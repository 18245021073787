.qtip {
	position: absolute;
	left: -28000px;
	top: -28000px;
	display: none;
	max-width: 280px;
	min-width: 50px;
	font-size: 10.5px;
	line-height: 12px;
	direction: ltr;
	box-shadow: none;
	padding: 0;
}
.qtip-content {
	padding: 5px 9px;
	text-align: left;
	word-wrap: break-word;
}
.qtip-content,
.qtip-titlebar {
	position: relative;
	overflow: hidden;
}
.qtip-titlebar {
	padding: 5px 35px 5px 10px;
	border-width: 0 0 1px;
	font-weight: 700;
}
.qtip-titlebar + .qtip-content {
	border-top-width: 0 !important;
}
.qtip-close {
	position: absolute;
	right: -9px;
	top: -9px;
	z-index: 11;
	cursor: pointer;
	outline: medium none;
	border: 1px solid transparent;
}
.qtip-titlebar .qtip-close {
	right: 4px;
	top: 50%;
	margin-top: -9px;
}
* html .qtip-titlebar .qtip-close {
	top: 16px;
}
.qtip-icon .ui-icon,
.qtip-titlebar .ui-icon {
	display: block;
	text-indent: -1000em;
	direction: ltr;
}
.qtip-icon,
.qtip-icon .ui-icon {
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	text-decoration: none;
}
.qtip-icon .ui-icon {
	width: 18px;
	height: 14px;
	line-height: 14px;
	text-align: center;
	text-indent: 0;
	font: normal 700 10px/13px Tahoma, sans-serif;
	color: inherit;
	background: transparent none no-repeat -100em -100em;
}
.qtip-default {
	border: 1px solid #f1d031;
	background-color: #ffffa3;
	color: #555;
}
.qtip-default .qtip-titlebar {
	background-color: #ffef93;
}
.qtip-default .qtip-icon {
	border-color: #ccc;
	background: #f1f1f1;
	color: #777;
}
.qtip-default .qtip-titlebar .qtip-close {
	border-color: #aaa;
	color: #111;
}

/*! Light tooltip style */
.qtip-light {
	background-color: #fff;
	border-color: #e2e2e2;
	color: #454545;
}
.qtip-light .qtip-titlebar {
	background-color: #f1f1f1;
}

/*! Dark tooltip style */
.qtip-dark {
	background-color: #505050;
	border-color: #303030;
	color: #f3f3f3;
}
.qtip-dark .qtip-titlebar {
	background-color: #404040;
}
.qtip-dark .qtip-icon {
	border-color: #444;
}
.qtip-dark .qtip-titlebar .ui-state-hover {
	border-color: #303030;
}

/*! Cream tooltip style */
.qtip-cream {
	background-color: #fbf7aa;
	border-color: #f9e98e;
	color: #a27d35;
}
.qtip-cream .qtip-titlebar {
	background-color: #f0de7d;
}
.qtip-cream .qtip-close .qtip-icon {
	background-position: -82px 0;
}

/*! Red tooltip style */
.qtip-red {
	background-color: #f78b83;
	border-color: #d95252;
	color: #912323;
}
.qtip-red .qtip-titlebar {
	background-color: #f06d65;
}
.qtip-red .qtip-close .qtip-icon {
	background-position: -102px 0;
}
.qtip-red .qtip-icon,
.qtip-red .qtip-titlebar .ui-state-hover {
	border-color: #d95252;
}

/*! Green tooltip style */
.qtip-green {
	background-color: #caed9e;
	border-color: #90d93f;
	color: #3f6219;
}
.qtip-green .qtip-titlebar {
	background-color: #b0de78;
}
.qtip-green .qtip-close .qtip-icon {
	background-position: -42px 0;
}

/*! Blue tooltip style */
.qtip-blue {
	background-color: #e5f6fe;
	border-color: #add9ed;
	color: #5e99bd;
}
.qtip-blue .qtip-titlebar {
	background-color: #d0e9f5;
}
.qtip-blue .qtip-close .qtip-icon {
	background-position: -2px 0;
}
.qtip-shadow {
	-webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.15);
	box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.15);
}
.qtip-bootstrap,
.qtip-rounded,
.qtip-tipsy {
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
}
.qtip-rounded .qtip-titlebar {
	-moz-border-radius: 4px 4px 0 0;
	-webkit-border-radius: 4px 4px 0 0;
	border-radius: 4px 4px 0 0;
}
.qtip-youtube {
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	-webkit-box-shadow: 0 0 3px #333;
	-moz-box-shadow: 0 0 3px #333;
	box-shadow: 0 0 3px #333;
	color: #fff;
	border: 0 solid transparent;
	background: #4a4a4a;
	background-image: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(0, #4a4a4a),
		color-stop(100%, #000)
	);
	background-image: -webkit-linear-gradient(top, #4a4a4a, #000);
	background-image: -moz-linear-gradient(top, #4a4a4a 0, #000 100%);
	background-image: -ms-linear-gradient(top, #4a4a4a 0, #000 100%);
	background-image: -o-linear-gradient(top, #4a4a4a 0, #000 100%);
}
.qtip-youtube .qtip-titlebar {
	background-color: #4a4a4a;
	background-color: transparent;
}
.qtip-youtube .qtip-content {
	padding: 0.75em;
	font: 12px arial, sans-serif;
	filter: progid:DXImageTransform.Microsoft.Gradient(GradientType=0,StartColorStr=#4a4a4a,EndColorStr=#000000);
	-ms-filter: 'progid:DXImageTransform.Microsoft.Gradient(GradientType=0,StartColorStr=#4a4a4a,EndColorStr=#000000);';
}
.qtip-youtube .qtip-icon {
	border-color: #222;
}
.qtip-youtube .qtip-titlebar .ui-state-hover {
	border-color: #303030;
}
.qtip-jtools {
	background: #232323;
	background: rgba(0, 0, 0, 0.7);
	background-image: -webkit-gradient(linear, left top, left bottom, from(#717171), to(#232323));
	background-image: -moz-linear-gradient(top, #717171, #232323);
	background-image: -webkit-linear-gradient(top, #717171, #232323);
	background-image: -ms-linear-gradient(top, #717171, #232323);
	background-image: -o-linear-gradient(top, #717171, #232323);
	border: 2px solid #f1f1f1;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	-webkit-box-shadow: 0 0 12px #333;
	-moz-box-shadow: 0 0 12px #333;
	box-shadow: 0 0 12px #333;
}
.qtip-jtools .qtip-titlebar {
	background-color: transparent;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#717171,endColorstr=#4A4A4A);
	-ms-filter: 'progid:DXImageTransform.Microsoft.gradient(startColorstr=#717171,endColorstr=#4A4A4A)';
}
.qtip-jtools .qtip-content {
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#4A4A4A,endColorstr=#232323);
	-ms-filter: 'progid:DXImageTransform.Microsoft.gradient(startColorstr=#4A4A4A,endColorstr=#232323)';
}
.qtip-jtools .qtip-content,
.qtip-jtools .qtip-titlebar {
	background: transparent;
	color: #fff;
	border: 0 dashed transparent;
}
.qtip-jtools .qtip-icon {
	border-color: #555;
}
.qtip-jtools .qtip-titlebar .ui-state-hover {
	border-color: #333;
}
.qtip-cluetip {
	-webkit-box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.4);
	-moz-box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.4);
	box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.4);
	background-color: #d9d9c2;
	color: #111;
	border: 0 dashed transparent;
}
.qtip-cluetip .qtip-titlebar {
	background-color: #87876a;
	color: #fff;
	border: 0 dashed transparent;
}
.qtip-cluetip .qtip-icon {
	border-color: #808064;
}
.qtip-cluetip .qtip-titlebar .ui-state-hover {
	border-color: #696952;
	color: #696952;
}
.qtip-tipsy {
	background: #000;
	background: rgba(0, 0, 0, 0.87);
	color: #fff;
	border: 0 solid transparent;
	font-size: 11px;
	font-family: Lucida Grande, sans-serif;
	font-weight: 700;
	line-height: 16px;
	text-shadow: 0 1px #000;
}
.qtip-tipsy .qtip-titlebar {
	padding: 6px 35px 0 10px;
	background-color: transparent;
}
.qtip-tipsy .qtip-content {
	padding: 6px 10px;
}
.qtip-tipsy .qtip-icon {
	border-color: #222;
	text-shadow: none;
}
.qtip-tipsy .qtip-titlebar .ui-state-hover {
	border-color: #303030;
}
.qtip-tipped {
	border: 3px solid #959fa9;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	background-color: #f9f9f9;
	color: #454545;
	font-weight: 400;
	font-family: serif;
}
.qtip-tipped .qtip-titlebar {
	border-bottom-width: 0;
	color: #fff;
	background: #3a79b8;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#3a79b8), to(#2e629d));
	background-image: -webkit-linear-gradient(top, #3a79b8, #2e629d);
	background-image: -moz-linear-gradient(top, #3a79b8, #2e629d);
	background-image: -ms-linear-gradient(top, #3a79b8, #2e629d);
	background-image: -o-linear-gradient(top, #3a79b8, #2e629d);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#3A79B8,endColorstr=#2E629D);
	-ms-filter: 'progid:DXImageTransform.Microsoft.gradient(startColorstr=#3A79B8,endColorstr=#2E629D)';
}
.qtip-tipped .qtip-icon {
	border: 2px solid #285589;
	background: #285589;
}
.qtip-tipped .qtip-icon .ui-icon {
	background-color: #fbfbfb;
	color: #555;
}
.qtip-bootstrap {
	font-size: 14px;
	line-height: 20px;
	color: #333;
	padding: 1px;
	background-color: #fff;
	border: 1px solid #ccc;
	border: 1px solid rgba(0, 0, 0, 0.2);
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
	-webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	-webkit-background-clip: padding-box;
	-moz-background-clip: padding;
	background-clip: padding-box;
}
.qtip-bootstrap .qtip-titlebar {
	padding: 8px 14px;
	margin: 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	background-color: #f7f7f7;
	border-bottom: 1px solid #ebebeb;
	-webkit-border-radius: 5px 5px 0 0;
	-moz-border-radius: 5px 5px 0 0;
	border-radius: 5px 5px 0 0;
}
.qtip-bootstrap .qtip-titlebar .qtip-close {
	right: 11px;
	top: 45%;
	border-style: none;
}
.qtip-bootstrap .qtip-content {
	padding: 9px 14px;
}
.qtip-bootstrap .qtip-icon {
	background: transparent;
}
.qtip-bootstrap .qtip-icon .ui-icon {
	width: auto;
	height: auto;
	float: right;
	font-size: 20px;
	font-weight: 700;
	line-height: 18px;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: 0.2;
	filter: alpha(opacity=20);
}
.qtip-bootstrap .qtip-icon .ui-icon:hover {
	color: #000;
	text-decoration: none;
	cursor: pointer;
	opacity: 0.4;
	filter: alpha(opacity=40);
}
.qtip:not(.ie9haxors) div.qtip-content,
.qtip:not(.ie9haxors) div.qtip-titlebar {
	-webkit-filter: none;
	filter: none;
	-ms-filter: none;
}
.qtip .qtip-tip {
	margin: 0 auto;
	overflow: hidden;
	z-index: 10;
}
.qtip .qtip-tip,
x:-o-prefocus {
	visibility: hidden;
}
.qtip .qtip-tip,
.qtip .qtip-tip .qtip-vml,
.qtip .qtip-tip canvas {
	position: absolute;
	color: #123456;
	background: transparent;
	border: 0 dashed transparent;
}
.qtip .qtip-tip canvas {
	top: 0;
	left: 0;
}
.qtip .qtip-tip .qtip-vml {
	behavior: url(#default#VML);
	display: inline-block;
	visibility: visible;
}
#qtip-overlay {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
#qtip-overlay.blurs {
	cursor: pointer;
}
#qtip-overlay div {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: #000;
	opacity: 0.7;
	filter: alpha(opacity=70);
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=70)';
}
.qtipmodal-ie6fix {
	position: absolute !important;
}
@font-face {
	font-family: montserrat;
	src: url(../font/montserrat-light-webfont.eot);
	src: url(../font/montserrat-light-webfont.eot?#iefix) format('embedded-opentype'),
		url(../font/montserrat-light-webfont.woff) format('woff'),
		url(../font/montserrat-light-webfont.ttf) format('truetype'),
		url(../font/montserrat-light-webfont.svg#Montserrat-Light) format('svg');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: montserrat;
	src: url(../font/montserrat-regular-webfont.eot);
	src: url(../font/montserrat-regular-webfont.eot?#iefix) format('embedded-opentype'),
		url(../font/montserrat-regular-webfont.woff) format('woff'),
		url(../font/montserrat-regular-webfont.ttf) format('truetype'),
		url(../font/montserrat-regular-webfont.svg#Montserrat-SemiBold) format('svg');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: okticon;
	src: url(../font/okticon.eot);
	src: url(../font/okticon.eot?#iefix) format('embedded-opentype'),
		url(../font/okticon.woff) format('woff'), url(../font/okticon.ttf) format('truetype'),
		url(../font/okticon.svg#okticon) format('svg');
	font-weight: 400;
	font-style: normal;
}
.wf-loading {
	visibility: hidden;
}
.wf-loading .ajax-include-feedback-content {
	visibility: hidden !important;
}
.wf-active {
	visibility: visible;
}
.float-r {
	float: right !important;
}
.clearfix {
	display: block;
}
.clearfix:after,
.clearfix:before {
	display: block;
	height: 0;
	clear: both;
	content: '.';
	visibility: hidden;
}
.hide {
	display: none;
}
#okta-sign-in {
	font-family: montserrat, Arial, Helvetica, sans-serif;
	font-size: 13px;
	font-weight: 400;
	line-height: 1.4;
	background-color: #f9f9f9;
	color: #777;
	position: relative;
	overflow: auto;
	border-radius: 3px;
	border-style: solid;
	border-width: 1px;
	height: auto;
	margin: 100px auto 8px;
	width: 400px;
	min-width: 300px;
}
@media only screen and (max-device-width: 480px) {
	#okta-sign-in {
		margin-top: 0;
		width: 100%;
	}
}
@media only screen and (max-width: 400px) {
	#okta-sign-in {
		width: 100%;
	}
}
@media only screen and (max-height: 750px) {
	#okta-sign-in {
		margin-top: 0;
	}
}
#okta-sign-in.no-beacon .auth-content {
	padding-top: 20px;
}
#okta-sign-in.no-beacon .auth-header {
	padding-bottom: 30px;
}
.auth .content {
	min-height: 100%;
	min-width: 300px;
	display: inline-block;
	width: 100%;
}
.auth .content:after {
	content: '';
	display: block;
	height: 30px;
}
.auth .footer {
	background-color: #fff;
	min-width: 300px;
	position: absolute;
	font-size: 11px;
	left: 0;
	right: 0;
	bottom: 0;
	height: 34px;
	overflow: hidden;
}
.auth .footer .footer-container {
	padding: 9px 80px;
	margin: 0 auto;
	box-sizing: border-box;
}
@media only screen and (max-width: 560px) {
	.auth .footer .footer-container {
		width: 400px;
		padding-left: 20px;
		padding-right: 20px;
	}
}
@media only screen and (max-width: 400px) {
	.auth .footer .footer-container {
		width: 100%;
	}
}
.auth .footer a {
	color: #777;
}
.auth .footer a:active,
.auth .footer a:link,
.auth .footer a:visited {
	text-decoration: none;
	color: #777;
}
.auth .footer a:focus,
.auth .footer a:hover {
	text-decoration: underline;
	color: #777;
}
.auth .footer .copyright {
	float: left;
}
.auth .footer .privacy-policy {
	float: right;
}
.lt-ie8 .beacon-container {
	left: 50%;
	margin-left: -42px;
}
.lt-ie9 .input-fix input[name='phoneNumber'] {
	border: 1px solid #bbb;
}
.lt-ie9 .auth {
	max-height: 500px;
	overflow: auto;
}
.lt-ie9 .footer {
	position: fixed;
	bottom: 0;
}
.lt-ie9 .auth-beacon-border {
	bottom: -2px;
	left: -2px;
	right: -2px;
	top: -2px;
}
.lt-ie9 #copyright a {
	text-decoration: none;
}
.lt-ie9 #copyright a:hover {
	cursor: pointer;
	color: red;
}
.lt-ie9 .beacon-blank {
	display: none;
}
.login-bg-image {
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: cover;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -5;
}
.okta-container .applogin-banner {
	position: relative;
	margin-bottom: 10px;
	min-width: 300px;
}
.okta-container .applogin-banner .applogin-background {
	background-color: #fff;
	opacity: 0.9;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	box-shadow: 0 0 2px 1px hsla(0, 0%, 68.6%, 0.3);
}
.okta-container .applogin-banner .applogin-container {
	position: relative;
	width: 400px;
	min-width: 300px;
	margin: 0 auto;
	padding: 20px 0;
	box-sizing: border-box;
	text-align: center;
}
@media only screen and (max-width: 400px) {
	.okta-container .applogin-banner .applogin-container {
		width: 100%;
	}
}
@media only screen and (max-height: 750px) {
	.okta-container .applogin-banner .applogin-container {
		padding: 10px 0;
	}
}
@media only screen and (max-height: 660px) {
	.okta-container .applogin-banner .applogin-container {
		padding: 5px 0;
	}
}
.okta-container .applogin-banner .applogin-container h1 {
	font-size: 24px;
	font-weight: lighter;
	line-height: 26px;
}
@media only screen and (max-height: 660px) {
	.okta-container .applogin-banner .applogin-container p {
		display: none;
	}
}
.okta-container .applogin-banner .applogin-app-logo {
	display: inline-block;
	vertical-align: middle;
	margin-left: 5px;
}
.okta-container .applogin-banner .applogin-app-logo img {
	max-height: 26px;
}
#okta-sign-in {
	/*! Custom qtip styles, based on dark theme */
}
#okta-sign-in [class*='-16'],
#okta-sign-in [class^='-16'] {
	vertical-align: top;
	position: relative;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
#okta-sign-in [class*='-16']:after,
#okta-sign-in [class*='-16']:before,
#okta-sign-in [class^='-16']:after,
#okta-sign-in [class^='-16']:before {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: block;
	font-family: okticon;
	font-size: 16px;
	font-smoothing: antialiased;
	font-style: normal !important;
	font-weight: 300 !important;
	position: absolute;
	speak: none;
	text-indent: 0;
	white-space: normal;
}
#okta-sign-in [class*='-13']:after,
#okta-sign-in [class*='-13']:before,
#okta-sign-in [class*='-14']:after,
#okta-sign-in [class*='-14']:before,
#okta-sign-in [class^='-13']:after,
#okta-sign-in [class^='-13']:before,
#okta-sign-in [class^='-14']:after,
#okta-sign-in [class^='-14']:before {
	display: block;
	position: absolute;
	white-space: normal;
	text-indent: 0;
	font-size: 14px;
	font-family: okticon;
	font-weight: 300 !important;
	font-style: normal !important;
	speak: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-smoothing: antialiased;
}
#okta-sign-in [class*='-24'],
#okta-sign-in [class^='-24'] {
	vertical-align: top;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
#okta-sign-in [class*='-24']:after,
#okta-sign-in [class*='-24']:before,
#okta-sign-in [class^='-24']:after,
#okta-sign-in [class^='-24']:before {
	display: block;
	position: absolute;
	white-space: normal;
	text-indent: 0;
	font-size: 24px;
	font-family: okticon;
	font-weight: 300 !important;
	font-style: normal !important;
	speak: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-smoothing: antialiased;
}
#okta-sign-in [class*='-48'],
#okta-sign-in [class^='-48'] {
	vertical-align: top;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
#okta-sign-in [class*='-48']:after,
#okta-sign-in [class*='-48']:before,
#okta-sign-in [class^='-48']:after,
#okta-sign-in [class^='-48']:before {
	display: block;
	position: absolute;
	white-space: normal;
	text-indent: 0;
	font-size: 48px;
	font-family: okticon;
	font-weight: 300 !important;
	font-style: normal !important;
	speak: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-smoothing: antialiased;
}
#okta-sign-in [class*='-32'],
#okta-sign-in [class^='-32'] {
	vertical-align: top;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
#okta-sign-in [class*='-32']:after,
#okta-sign-in [class*='-32']:before,
#okta-sign-in [class^='-32']:after,
#okta-sign-in [class^='-32']:before {
	position: absolute;
	font-size: 32px;
}
#okta-sign-in .dismiss-icon,
#okta-sign-in [class*='-32']:after,
#okta-sign-in [class*='-32']:before,
#okta-sign-in [class^='-32']:after,
#okta-sign-in [class^='-32']:before {
	display: block;
	white-space: normal;
	text-indent: 0;
	font-family: okticon;
	font-weight: 300 !important;
	font-style: normal !important;
	speak: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-smoothing: antialiased;
}
#okta-sign-in .dismiss-icon {
	position: relative;
	font-size: 16px;
}
#okta-sign-in .admin-banner .dismiss-icon:before {
	content: '\e04f';
	color: #fff;
}
#okta-sign-in .dismiss-icon:before {
	content: '\e04f';
	color: #a7a7a7;
}
#okta-sign-in .dismiss-icon:hover:before {
	content: '\e04f';
	color: #5e5e5e;
}
#okta-sign-in #hide-getting-started.dismiss-icon {
	float: none;
	margin: 0;
	position: absolute;
	right: 5px;
	top: 5px;
}
#okta-sign-in #hide-getting-started.dismiss-icon:hover {
	text-decoration: none;
}
#okta-sign-in #getting-started-shortcut .dismiss-icon {
	content: '\e04f';
	color: #b0d244;
}
#okta-sign-in #getting-started-shortcut .dismiss-icon:hover {
	content: '\e04f';
	color: #a7a7a7;
}
#okta-sign-in #hide-getting-started-shortcut {
	font-family: okticon;
	font-weight: 300 !important;
	font-style: normal !important;
	speak: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-smoothing: antialiased;
	text-decoration: none;
	position: absolute;
	right: 15px;
	text-align: center;
	font-size: 16px;
}
#okta-sign-in #hide-getting-started-shortcut:before {
	content: '\e04f';
	color: #b0d244;
}
#okta-sign-in #hide-getting-started-shortcut:hover:before {
	content: '\e04f';
	text-decoration: none;
	color: #cfeb76;
}
#okta-sign-in .oktamainlogo:before {
	display: block;
	position: absolute;
	white-space: normal;
	text-indent: 0;
	vertical-align: top;
	top: -7px;
	font-size: 66px;
	font-family: okticon;
	font-weight: 300 !important;
	font-style: normal !important;
	speak: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-smoothing: antialiased;
	content: '\e06e';
	color: #fff;
}
#okta-sign-in .oktaicon:before {
	content: '\e06c';
	color: #007dc1;
}
#okta-sign-in .oktaicon:after,
#okta-sign-in .oktaicon:before {
	display: block;
	position: absolute;
	white-space: normal;
	text-indent: 0;
	vertical-align: top;
	top: -10px;
	font-size: 80px;
	font-family: okticon;
	font-weight: 300 !important;
	font-style: normal !important;
	speak: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-smoothing: antialiased;
}
#okta-sign-in .oktaicon:after {
	content: '\e06d';
	color: #000;
}
#okta-sign-in .person-add-16:before {
	color: #5e5e5e;
	content: '\e00d';
}
#okta-sign-in .person-add-16:after {
	color: #007dc1;
	content: '\e001';
}
#okta-sign-in .app-add-16:before {
	color: #5e5e5e;
	content: '\e043';
}
#okta-sign-in .app-add-16:after {
	color: #007dc1;
	content: '\e001';
}
#okta-sign-in .app-add-16-gray:before {
	color: #5e5e5e;
	content: '\e043';
}
#okta-sign-in .app-add-16-gray:after {
	color: #5e5e5e;
	content: '\e001';
}
#okta-sign-in .app-refresh-16:before {
	color: #5e5e5e;
	content: '\e047';
}
#okta-sign-in .app-refresh-16:after {
	color: #007dc1;
	content: '\e008';
}
#okta-sign-in .app-remove-16:before {
	color: #5e5e5e;
	content: '\e044';
}
#okta-sign-in .app-remove-16:after {
	color: #e34843;
	content: '\e009';
}
#okta-sign-in .person-import-16:before {
	color: #5e5e5e;
	content: '\e010';
}
#okta-sign-in .person-import-16:after {
	color: #5e5e5e;
	content: '\e005';
}
#okta-sign-in .person-import-16-green:before {
	color: #5e5e5e;
	content: '\e010';
}
#okta-sign-in .person-import-16-green:after {
	color: #4cbf9c;
	content: '\e005';
}
#okta-sign-in .admin-add-16:before {
	color: #5e5e5e;
	content: '\e012';
}
#okta-sign-in .admin-add-16:after {
	color: #007dc1;
	content: '\e001';
}
#okta-sign-in .logo-upload-16:before {
	content: '\e016';
	color: #5e5e5e;
}
#okta-sign-in .logo-upload-16:after {
	content: '\e00b';
	color: #007dc1;
}
#okta-sign-in .person-activate-16:before {
	content: '\e00f';
	color: #5e5e5e;
}
#okta-sign-in .person-activate-16:after {
	content: '\e000';
	color: #007dc1;
}
#okta-sign-in .person-suspend-16:before {
	content: '\e093';
	color: #5e5e5e;
}
#okta-sign-in .person-suspend-16:after {
	content: '\e091';
	color: #007dc1;
}
#okta-sign-in .person-unsuspend-16:before {
	content: '\e095';
	color: #5e5e5e;
}
#okta-sign-in .person-unsuspend-16:after {
	content: '\e092';
	color: #007dc1;
}
#okta-sign-in .app-assign-16:before {
	content: '\e046';
	color: #5e5e5e;
}
#okta-sign-in .app-assign-16:after {
	content: '\e002';
	color: #007dc1;
}
#okta-sign-in .app-unassign-16:before {
	content: '\e045';
	color: #5e5e5e;
}
#okta-sign-in .app-unassign-16:after {
	content: '\e00a';
	color: #007dc1;
}
#okta-sign-in .person-deactivate-16:before {
	content: '\e00e';
	color: #5e5e5e;
}
#okta-sign-in .person-deactivate-16:after {
	content: '\e009';
	color: #007dc1;
}
#okta-sign-in .arrow-left-16:before {
	color: #007dc1;
}
#okta-sign-in .arrow-left-16-gray:before,
#okta-sign-in .arrow-left-16:before {
	content: '\e02c';
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	transform: rotate(180deg);
}
#okta-sign-in .arrow-left-16-gray:before {
	color: #5e5e5e;
}
#okta-sign-in .arrow-right-16:before {
	content: '\e02c';
	color: #007dc1;
}
#okta-sign-in .arrow-down-16:before {
	content: '\e02c';
	color: #007dc1;
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
}
#okta-sign-in .arrow-up-16:before {
	content: '\e02c';
	color: #007dc1;
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	transform: rotate(-90deg);
}
#okta-sign-in .form-help-16:before {
	content: '\e054';
	color: #a7a7a7;
}
#okta-sign-in .password-reset-16:before {
	content: '\e034';
	color: #5e5e5e;
}
#okta-sign-in .password-reset-16:after {
	content: '\e035';
	color: #007dc1;
}
#okta-sign-in .support-16:before {
	content: '\e015';
	color: #5e5e5e;
}
#okta-sign-in .status-16:before {
	content: '\e05b';
	color: #5e5e5e;
}
#okta-sign-in .help-16:before {
	content: '\e054';
	color: #666;
}
#okta-sign-in .help-14:before {
	content: '\e054';
	color: #f1d10d;
	font-size: 14px;
}
#okta-sign-in .help-13:before {
	content: '\e054';
	color: #a7a7a7;
	font-size: 14px;
}
#okta-sign-in .feedback-16:before {
	content: '\e02f';
	color: #5e5e5e;
}
#okta-sign-in .suggest-feature-16:before {
	content: '\e062';
	color: #5e5e5e;
}
#okta-sign-in .light-bulb-16:before {
	content: '\e062';
	color: #fff;
}
#okta-sign-in .report-bug-16:before {
	content: '\e05c';
	color: #5e5e5e;
}
#okta-sign-in .person-16-blue:before {
	content: '\e00c';
	color: #007dc1;
}
#okta-sign-in .confirm-16:before {
	content: '\e030';
	color: #5e5e5e;
}
#okta-sign-in .confirm-16-small:before {
	content: '\e031';
	color: #5e5e5e;
}
#okta-sign-in .confirm-16-small-disabled:before {
	content: '\e031';
	color: #a7a7a7;
}
#okta-sign-in .confirm-16-disabled:before {
	content: '\e030';
	color: #a7a7a7;
}
#okta-sign-in .confirm-16-blue:before {
	content: '\e030';
	color: #007dc1;
}
#okta-sign-in .confirm-16-green:before {
	content: '\e030';
	color: #4cbf9c;
}
#okta-sign-in .visibility-16:before {
	content: '\e022';
	color: #a7a7a7;
}
#okta-sign-in .visibility-off-16:before {
	content: '\e0c3';
	color: #a7a7a7;
}
#okta-sign-in .person-16-gray:before {
	content: '\e00c';
	color: #5e5e5e;
}
#okta-sign-in .cancel-16-small:before,
#okta-sign-in .cancel-16:before {
	content: '\e02b';
	color: #5e5e5e;
}
#okta-sign-in .cancel-16-disabled:before,
#okta-sign-in .cancel-16-small-disabled:before {
	content: '\e02b';
	color: #a7a7a7;
}
#okta-sign-in .cancel-16-blue:before {
	content: '\e02b';
	color: #007dc1;
}
#okta-sign-in .cancel-16-light-gray:before {
	content: '\e02b';
	color: #a7a7a7;
}
#okta-sign-in .cancel-16-small-red:before {
	content: '\e02b';
	color: #e34843;
}
#okta-sign-in .checkbox-16:before {
	content: '\e032';
	color: #5e5e5e;
	content: '\e033';
	color: #007dc1;
}
#okta-sign-in .message-16:before {
	content: '\e02f';
	color: #5e5e5e;
}
#okta-sign-in .activate-on-green-16:before {
	content: '\e02d';
	color: #4cbf9c;
}
#okta-sign-in .deactivate-16:before {
	content: '\e02d';
	color: #5e5e5e;
}
#okta-sign-in .deactivate-off-red-16:before {
	content: '\e02d';
	color: #e34843;
}
#okta-sign-in .delete-16:before {
	content: '\e04e';
	color: #e34843;
}
#okta-sign-in .delete-16-gray:before {
	content: '\e04e';
	color: #5e5e5e;
}
#okta-sign-in .app-16-blue:before {
	content: '\e042';
	color: #007dc1;
}
#okta-sign-in .edit-16:before {
	content: '\e040';
	color: #5e5e5e;
}
#okta-sign-in .edit-16-disabled:before {
	content: '\e040';
	color: #a7a7a7;
}
#okta-sign-in .edit-16-blue:before {
	content: '\e040';
	color: #007dc1;
}
#okta-sign-in .edit-16-white:before {
	content: '\e040';
	color: #fff;
}
#okta-sign-in .credentials-16:before {
	content: '\e03c';
	color: #5e5e5e;
}
#okta-sign-in .launch-16:before {
	content: '\e06f';
	color: #5e5e5e;
}
#okta-sign-in .app-16-gray:before {
	content: '\e042';
	color: #5e5e5e;
}
#okta-sign-in .prev-16:before {
	content: '\e020';
}
#okta-sign-in .prev-13:before,
#okta-sign-in .prev-16:before {
	color: #5e5e5e;
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	transform: rotate(180deg);
}
#okta-sign-in .prev-13:before {
	content: '\e01f';
}
#okta-sign-in .next-16:before {
	content: '\e020';
	color: #5e5e5e;
}
#okta-sign-in .next-13:before {
	content: '\e01f';
	color: #5e5e5e;
	font-size: 16px;
}
#okta-sign-in .org-settings-16:before {
	content: '\e025';
	color: #5e5e5e;
}
#okta-sign-in .org-settings-16:after {
	content: '\e026';
	color: #5e5e5e;
}
#okta-sign-in .settings-white-16:before {
	content: '\e025';
	color: #fff;
}
#okta-sign-in .settings-white-16:after {
	content: '\e026';
	color: #fff;
}
#okta-sign-in .remove-16:before {
	content: '\e052';
	color: #5e5e5e;
}
#okta-sign-in .remove-16-color:before {
	content: '\e052';
	color: #e34843;
}
#okta-sign-in .remove-16-disabled:before {
	content: '\e052';
	color: #a7a7a7;
}
#okta-sign-in .remove-16-red:before {
	content: '\e052';
	color: #e34843;
}
#okta-sign-in .clear-input-16:before {
	content: '\e02b';
	color: #5e5e5e;
}
#okta-sign-in .reports-16:before {
	content: '\e048';
	color: #5e5e5e;
}
#okta-sign-in .reports-16:after {
	content: '\e049';
	color: #007dc1;
}
#okta-sign-in .add-16:before {
	content: '\e050';
	color: #5e5e5e;
}
#okta-sign-in .add-16-color:before {
	content: '\e050';
	color: #4cbf9c;
}
#okta-sign-in .add-16-disabled:before {
	content: '\e050';
	color: #a7a7a7;
}
#okta-sign-in .add-16-white:before {
	content: '\e050';
	color: #fff;
}
#okta-sign-in .add-16-thin:before {
	content: '\e03a';
	color: #007dc1;
}
#okta-sign-in .expand-l-16-gray:before {
	content: '\e056';
	color: #5e5e5e;
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	transform: rotate(180deg);
}
#okta-sign-in .expand-r-16-gray:before {
	content: '\e056';
	color: #5e5e5e;
}
#okta-sign-in .collapse-16-gray:before {
	content: '\e056';
	color: #5e5e5e;
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
}
#okta-sign-in .icon-ios-16:before {
	content: '\e088';
	color: #5e5e5e;
}
#okta-sign-in .icon-android-16:before {
	content: '\e085';
	color: #92bf43;
}
#okta-sign-in .icon-windows-16:before {
	content: '\e08f';
	color: #00abef;
}
#okta-sign-in .icon-osx-16:before {
	content: '\e08e';
	color: #5e5e5e;
}
#okta-sign-in .expand-l-gray .icon:before {
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	transform: rotate(180deg);
}
#okta-sign-in .collapse-gray .icon:before,
#okta-sign-in .expand-l-gray .icon:before,
#okta-sign-in .expand-r-gray .icon:before {
	content: '\e056';
	color: #a7a7a7;
	display: block;
	position: absolute;
	white-space: normal;
	text-indent: 0;
	font-size: 16px;
	font-family: okticon;
	font-weight: 300 !important;
	font-style: normal !important;
	speak: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-smoothing: antialiased;
}
#okta-sign-in .collapse-gray .icon:before {
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
}
#okta-sign-in .collapse-16-up-gray:before {
	content: '\e056';
	color: #5e5e5e;
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	transform: rotate(-90deg);
}
#okta-sign-in .expand-l-16-blue:before {
	content: '\e056';
	color: #007dc1;
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	transform: rotate(180deg);
}
#okta-sign-in .expand-r-16-blue:before {
	content: '\e056';
	color: #007dc1;
}
#okta-sign-in .collapse-16-blue:before {
	content: '\e056';
	color: #007dc1;
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
}
#okta-sign-in .info-16-white:before,
#okta-sign-in .info-16:before {
	content: '\e053';
	color: #fff;
}
#okta-sign-in .info-16-gray:before {
	content: '\e053';
	color: #5e5e5e;
}
#okta-sign-in .warning-16:before {
	content: '\e058';
	color: #fff;
}
#okta-sign-in .warning-16-yellow:before {
	content: '\e058';
	color: #f1d10d;
}
#okta-sign-in .error-16:before {
	content: '\e055';
	color: #fff;
}
#okta-sign-in .error-16-red:before,
#okta-sign-in .error-16-small:before {
	content: '\e055';
	color: #e34843;
}
#okta-sign-in .success-16:before {
	content: '\e051';
	color: #fff;
}
#okta-sign-in .success-16-green:before {
	content: '\e051';
	color: #4cbf9c;
}
#okta-sign-in .success-16-gray:before {
	content: '\e051';
	color: #e0e0e0;
}
#okta-sign-in .directory-add-16:before {
	content: '\e027';
	color: #5e5e5e;
}
#okta-sign-in .directory-add-16:after {
	content: '\e001';
	color: #007dc1;
}
#okta-sign-in .directory-remove-16:before {
	content: '\e029';
	color: #5e5e5e;
}
#okta-sign-in .directory-remove-16:after {
	content: '\e009';
	color: #007dc1;
}
#okta-sign-in .directory-16:before {
	content: '\e028';
	color: #5e5e5e;
}
#okta-sign-in .directory-16-light-gray:before {
	content: '\e028';
	color: #a7a7a7;
}
#okta-sign-in .token-add-16:before {
	content: '\e03b';
	color: #5e5e5e;
}
#okta-sign-in .token-add-16:after {
	content: '\e001';
	color: #007dc1;
}
#okta-sign-in .user-provisioning-16:before {
	content: '\e011';
	color: #a7a7a7;
}
#okta-sign-in .user-provisioning-16:after {
	content: '\e007';
	color: #a7a7a7;
}
#okta-sign-in .user-provisioning-16-green:before,
#okta-sign-in .user-provisioning-16.active:before {
	content: '\e011';
	color: #5e5e5e;
}
#okta-sign-in .user-provisioning-16-green:after,
#okta-sign-in .user-provisioning-16.active:after {
	content: '\e007';
	color: #4cbf9c;
}
#okta-sign-in .user-deprovisioning-16:before {
	content: '\e013';
	color: #5e5e5e;
}
#okta-sign-in .user-deprovisioning-16:after {
	content: '\e003';
	color: #007dc1;
}
#okta-sign-in .import-users-16:before {
	content: '\e010';
	color: #a7a7a7;
}
#okta-sign-in .import-users-16:after {
	content: '\e005';
	color: #a7a7a7;
}
#okta-sign-in .import-users-16-green.active:before,
#okta-sign-in .import-users-16-green:before {
	content: '\e010';
	color: #5e5e5e;
}
#okta-sign-in .import-users-16-green.active:after,
#okta-sign-in .import-users-16-green:after {
	content: '\e005';
	color: #4cbf9c;
}
#okta-sign-in .password-sync-16:before {
	content: '\e034';
	color: #a7a7a7;
}
#okta-sign-in .password-sync-16:after {
	content: '\e035';
	color: #a7a7a7;
}
#okta-sign-in .password-sync-16-green.active:before,
#okta-sign-in .password-sync-16-green:before {
	content: '\e034';
	color: #5e5e5e;
}
#okta-sign-in .password-sync-16-green.active:after,
#okta-sign-in .password-sync-16-green:after {
	content: '\e035';
	color: #4cbf9c;
}
#okta-sign-in .user-deactivation-16:before {
	content: '\e00f';
	color: #a7a7a7;
}
#okta-sign-in .user-deactivation-16:after {
	content: '\e000';
	color: #a7a7a7;
}
#okta-sign-in .user-deactivation-16-green.active:before,
#okta-sign-in .user-deactivation-16-green:before {
	content: '\e00f';
	color: #5e5e5e;
}
#okta-sign-in .user-deactivation-16-green.active:after,
#okta-sign-in .user-deactivation-16-green:after {
	content: '\e000';
	color: #4cbf9c;
}
#okta-sign-in .swa-16:before {
	content: '\e03d';
	color: #a7a7a7;
}
#okta-sign-in .swa-16:after {
	content: '\e002';
	color: #a7a7a7;
}
#okta-sign-in .swa-16-green.active:before,
#okta-sign-in .swa-16-green:before {
	content: '\e03d';
	color: #5e5e5e;
}
#okta-sign-in .swa-16-green.active:after,
#okta-sign-in .swa-16-green:after {
	content: '\e002';
	color: #4cbf9c;
}
#okta-sign-in .advanced-sso-16:before {
	content: '\e017';
	color: #a7a7a7;
}
#okta-sign-in .advanced-sso-16:after {
	content: '\e018';
	color: #a7a7a7;
}
#okta-sign-in .advanced-sso-16-green.active:before,
#okta-sign-in .advanced-sso-16-green:before {
	content: '\e017';
	color: #4cbf9c;
}
#okta-sign-in .advanced-sso-16-green.active:after,
#okta-sign-in .advanced-sso-16-green:after {
	content: '\e018';
	color: #5e5e5e;
}
#okta-sign-in .advanced-sso-16-blue:before {
	content: '\e017';
	color: #007dc1;
}
#okta-sign-in .advanced-sso-16-blue:after {
	content: '\e018';
	color: #5e5e5e;
}
#okta-sign-in .bookmark-16:before {
	content: '\e02a';
	color: #a7a7a7;
}
#okta-sign-in .bookmark-16-green.active:before,
#okta-sign-in .bookmark-16-green:before {
	content: '\e02a';
	color: #4cbf9c;
}
#okta-sign-in .run-16:before {
	content: '\e04b';
	color: #007dc1;
}
#okta-sign-in .run-16-gray:before {
	content: '\e04b';
	color: #5e5e5e;
}
#okta-sign-in .run-16-white:before {
	content: '\e04b';
	color: #fff;
}
#okta-sign-in .download-16:before {
	content: '\e03f';
	color: #007dc1;
}
#okta-sign-in .group-16:before {
	content: '\e019';
	color: #5e5e5e;
}
#okta-sign-in .group-add-16:before {
	content: '\e01a';
	color: #5e5e5e;
}
#okta-sign-in .group-add-16:after {
	content: '\e001';
	color: #007dc1;
}
#okta-sign-in .group-remove-16:before {
	content: '\e01d';
	color: #5e5e5e;
}
#okta-sign-in .group-remove-16:after {
	content: '\e00a';
	color: #007dc1;
}
#okta-sign-in .group-member-add-16:before {
	content: '\e00d';
	color: #5e5e5e;
}
#okta-sign-in .group-member-add-16:after {
	content: '\e001';
	color: #007dc1;
}
#okta-sign-in .group-member-remove-16:before {
	content: '\e00e';
	color: #5e5e5e;
}
#okta-sign-in .group-member-remove-16:after {
	content: '\e009';
	color: #007dc1;
}
#okta-sign-in .config-16:before {
	content: '\e024';
	color: #5e5e5e;
}
#okta-sign-in .group-override-16:before {
	content: '\e01e';
	color: #5e5e5e;
}
#okta-sign-in .group-override-16:after {
	content: '\e006';
	color: #007dc1;
}
#okta-sign-in .search-add-16:before {
	content: '\e050';
	color: #a7a7a7;
}
#okta-sign-in .search-green-add-16:before {
	content: '\e050';
	color: #4cbf9c;
}
#okta-sign-in .magnifying-glass-16:before {
	content: '\e041';
	color: #a7a7a7;
}
#okta-sign-in .location-16:before {
	content: '\e039';
	color: #5e5e5e;
}
#okta-sign-in .time-16:before {
	content: '\e057';
	color: #5e5e5e;
}
#okta-sign-in .time-alt-16:before {
	content: '\e082';
	color: #a7a7a7;
}
#okta-sign-in .calendar-16:before {
	content: '\e083';
	color: #a7a7a7;
}
#okta-sign-in .people-16:before {
	content: '\e019';
	color: #5e5e5e;
}
#okta-sign-in .conversation-16:before {
	content: '\e023';
	color: #5e5e5e;
}
#okta-sign-in .conversation-16-white:before {
	content: '\e023';
	color: #fff;
}
#okta-sign-in .megaphone-16:before {
	content: '\e065';
	color: #5e5e5e;
}
#okta-sign-in .active-sync-16:before {
	content: '\e01c';
	color: #5e5e5e;
}
#okta-sign-in .active-sync-16:after {
	content: '\e007';
	color: #4cbf9c;
}
#okta-sign-in .active-sync-16-reverse:before {
	content: '\e01c';
	color: #fff;
}
#okta-sign-in .active-sync-16-reverse:after {
	content: '\e007';
	color: #4cbf9c;
}
#okta-sign-in .inactive-sync-16:before {
	content: '\e01c';
	color: #a7a7a7;
}
#okta-sign-in .inactive-sync-16:after {
	content: '\e007';
	color: #a7a7a7;
}
#okta-sign-in .error-sync-16:before {
	content: '\e01c';
	color: #5e5e5e;
}
#okta-sign-in .error-sync-16:after {
	content: '\e007';
	color: #e34843;
}
#okta-sign-in .tasks-16-pending:before {
	content: '\e032';
	color: #5e5e5e;
}
#okta-sign-in .tasks-16-pending:after {
	content: '\e033';
	color: #f1d10d;
}
#okta-sign-in .password-reset-16-red:before {
	content: '\e034';
	color: #5e5e5e;
}
#okta-sign-in .password-reset-16-red:after {
	content: '\e035';
	color: #e34843;
}
#okta-sign-in .person-activate-16-yellow:before {
	content: '\e00f';
	color: #5e5e5e;
}
#okta-sign-in .person-activate-16-yellow:after {
	content: '\e000';
	color: #f1d10d;
}
#okta-sign-in .person-import-16-yellow:before {
	content: '\e010';
	color: #5e5e5e;
}
#okta-sign-in .person-import-16-yellow:after {
	content: '\e005';
	color: #f1d10d;
}
#okta-sign-in .apps-16-red:before {
	content: '\e042';
	color: #e34843;
}
#okta-sign-in .code-brackets-16:before {
	content: '\e038';
	color: #5e5e5e;
}
#okta-sign-in .refresh-16:before {
	content: '\e03e';
	color: #5e5e5e;
}
#okta-sign-in .refresh-16-blue:before {
	content: '\e03e';
	color: #007dc1;
}
#okta-sign-in .refresh-16-white:before {
	content: '\e03e';
	color: #fff;
}
#okta-sign-in .back-16:before {
	content: '\e02c';
	color: #fff;
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	transform: rotate(180deg);
}
#okta-sign-in .filter-16:before {
	content: '\e04a';
	color: #5e5e5e;
}
#okta-sign-in .filter-16-reverse:before {
	content: '\e04a';
	color: #fff;
}
#okta-sign-in .clone-16:before {
	content: '\e036';
	color: #5e5e5e;
}
#okta-sign-in .clone-16:after {
	content: '\e037';
	color: #007dc1;
}
#okta-sign-in .duo-push-16:before,
#okta-sign-in .okta-push-16:before {
	content: '\e051';
	color: #5e5e5e;
}
#okta-sign-in .duo-sms-16:before {
	content: '\e02e';
	color: #5e5e5e;
}
#okta-sign-in .duo-call-16:before {
	content: '\e021';
	color: #5e5e5e;
}
#okta-sign-in .factory-reset-16:before {
	content: '\e04c';
	color: #5e5e5e;
}
#okta-sign-in .no-16:before {
	content: '\e04d';
	color: #5e5e5e;
}
#okta-sign-in .expression-16:before {
	content: '\e068';
	color: #007dc1;
}
#okta-sign-in .omm-enrolling-16:before {
	content: '\e05a';
	color: #4cbf9c;
}
#okta-sign-in .remote-lock-16:before {
	content: '\e05d';
	color: #5e5e5e;
}
#okta-sign-in .asterisk-16:before {
	content: '\e071';
	color: #007dc1;
	font-size: 12px;
}
#okta-sign-in .asterisk-16-white:before {
	content: '\e071';
	color: #fff;
}
#okta-sign-in .configure-16:before {
	content: '\e076';
	color: #5e5e5e;
}
#okta-sign-in .instructions-16:before {
	content: '\e064';
	color: #fff;
}
#okta-sign-in .tombstone-16:before {
	content: '\e084';
	color: #5e5e5e;
}
#okta-sign-in .send-16:before {
	content: '\e094';
	color: #5e5e5e;
}
#okta-sign-in .add:before {
	content: '\e050';
}
#okta-sign-in .add:before,
#okta-sign-in .success-icon:before {
	color: #4cbf9c;
	display: block;
	position: absolute;
	white-space: normal;
	text-indent: 0;
	font-size: 16px;
	font-family: okticon;
	font-weight: 300 !important;
	font-style: normal !important;
	speak: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-smoothing: antialiased;
}
#okta-sign-in .success-icon:before {
	content: '\e051';
}
#okta-sign-in .person-add-24:before {
	content: '\e00d';
	color: #5e5e5e;
}
#okta-sign-in .person-add-24:after {
	content: '\e001';
	color: #007dc1;
}
#okta-sign-in .app-add-24:before {
	content: '\e043';
	color: #5e5e5e;
}
#okta-sign-in .app-add-24:after {
	content: '\e001';
	color: #007dc1;
}
#okta-sign-in .app-add-24-gray:before {
	content: '\e043';
	color: #5e5e5e;
}
#okta-sign-in .app-add-24-gray:after {
	content: '\e001';
	color: #5e5e5e;
}
#okta-sign-in .app-refresh-24:before {
	content: '\e047';
	color: #5e5e5e;
}
#okta-sign-in .app-refresh-24:after {
	content: '\e008';
	color: #007dc1;
}
#okta-sign-in .person-import-24:before {
	content: '\e010';
	color: #5e5e5e;
}
#okta-sign-in .person-import-24:after {
	content: '\e005';
	color: #007dc1;
}
#okta-sign-in .person-import-24-yellow:before {
	content: '\e010';
	color: #5e5e5e;
}
#okta-sign-in .person-import-24-yellow:after {
	content: '\e005';
	color: #f1d10d;
}
#okta-sign-in .admin-add-24:before {
	content: '\e012';
	color: #5e5e5e;
}
#okta-sign-in .admin-add-24:after {
	content: '\e001';
	color: #007dc1;
}
#okta-sign-in .logo-upload-24:before {
	content: '\e016';
	color: #5e5e5e;
}
#okta-sign-in .logo-upload-24:after {
	content: '\e00b';
	color: #007dc1;
}
#okta-sign-in .person-activate-24:before {
	content: '\e00f';
	color: #5e5e5e;
}
#okta-sign-in .person-activate-24:after {
	content: '\e000';
	color: #007dc1;
}
#okta-sign-in .person-activate-24-yellow:before {
	content: '\e00f';
	color: #5e5e5e;
}
#okta-sign-in .person-activate-24-yellow:after {
	content: '\e000';
	color: #f1d10d;
}
#okta-sign-in .person-edit-24:before {
	content: '\e066';
	color: #5e5e5e;
}
#okta-sign-in .person-edit-24:after {
	content: '\e004';
	color: #007dc1;
}
#okta-sign-in .app-assign-24:before {
	content: '\e046';
	color: #5e5e5e;
}
#okta-sign-in .app-assign-24:after {
	content: '\e002';
	color: #007dc1;
}
#okta-sign-in .apps-24:before {
	content: '\e042';
	color: #5e5e5e;
}
#okta-sign-in .apps-24-green:before {
	content: '\e042';
	color: #4cbf9c;
}
#okta-sign-in .apps-24-red:before {
	content: '\e042';
	color: #e34843;
}
#okta-sign-in .app-unassign-24:before {
	content: '\e045';
	color: #5e5e5e;
}
#okta-sign-in .app-unassign-24:after {
	content: '\e00a';
	color: #007dc1;
}
#okta-sign-in .app-unassign-24-yellow:before {
	content: '\e045';
	color: #5e5e5e;
}
#okta-sign-in .app-unassign-24-yellow:after {
	content: '\e00a';
	color: #f1d10d;
}
#okta-sign-in .person-deactivate-24:before {
	content: '\e00e';
	color: #5e5e5e;
}
#okta-sign-in .person-deactivate-24:after {
	content: '\e009';
	color: #007dc1;
}
#okta-sign-in .password-reset-24:before {
	content: '\e034';
	color: #5e5e5e;
}
#okta-sign-in .password-reset-24:after {
	content: '\e035';
	color: #007dc1;
}
#okta-sign-in .password-reset-24-red:before {
	content: '\e034';
	color: #5e5e5e;
}
#okta-sign-in .password-reset-24-red:after {
	content: '\e035';
	color: #e34843;
}
#okta-sign-in .feedback-24:before {
	content: '\e02f';
	color: #5e5e5e;
}
#okta-sign-in .person-24:before {
	content: '\e00c';
	color: #5e5e5e;
}
#okta-sign-in .person-24-green:before {
	content: '\e00c';
	color: #4cbf9c;
}
#okta-sign-in .person-24-red:before {
	content: '\e00c';
	color: #e34843;
}
#okta-sign-in .people-24:before {
	content: '\e019';
	color: #5e5e5e;
}
#okta-sign-in .instructions-24:before {
	content: '\e064';
	color: #fff;
}
#okta-sign-in .plugin-required-24:before {
	content: '\e063';
	color: #5e5e5e;
}
#okta-sign-in .info-24:before {
	content: '\e053';
	color: #fff;
}
#okta-sign-in .warning-24:before {
	content: '\e058';
	color: #fff;
}
#okta-sign-in .warning-24-yellow:before {
	content: '\e058';
	color: #f1d10d;
}
#okta-sign-in .error-24:before {
	content: '\e055';
	color: #fff;
}
#okta-sign-in .success-24:before {
	content: '\e051';
	color: #fff;
}
#okta-sign-in .directory-add-24:before {
	content: '\e027';
	color: #5e5e5e;
}
#okta-sign-in .directory-add-24:after {
	content: '\e001';
	color: #007dc1;
}
#okta-sign-in .directory-remove-24:before {
	content: '\e029';
	color: #5e5e5e;
}
#okta-sign-in .directory-remove-24:after {
	content: '\e009';
	color: #007dc1;
}
#okta-sign-in .directory-24:before {
	content: '\e028';
	color: #5e5e5e;
}
#okta-sign-in .directory-24-green:before {
	content: '\e028';
	color: #4cbf9c;
}
#okta-sign-in .directory-24-red:before {
	content: '\e028';
	color: #e34843;
}
#okta-sign-in .preview-24:before {
	content: '\e022';
	color: #e34843;
}
#okta-sign-in .token-add-24:before {
	content: '\e03b';
	color: #5e5e5e;
}
#okta-sign-in .token-add-24:after {
	content: '\e002';
	color: #007dc1;
}
#okta-sign-in .group-24:before {
	content: '\e019';
	color: #5e5e5e;
}
#okta-sign-in .group-add-24:before {
	content: '\e01a';
	color: #5e5e5e;
}
#okta-sign-in .group-add-24:after {
	content: '\e001';
	color: #007dc1;
}
#okta-sign-in .settings-24:before {
	content: '\e025';
	color: #5e5e5e;
}
#okta-sign-in .settings-24:after {
	content: '\e026';
	color: #5e5e5e;
}
#okta-sign-in .reports-24:before {
	content: '\e048';
	color: #5e5e5e;
}
#okta-sign-in .reports-24:after {
	content: '\e049';
	color: #007dc1;
}
#okta-sign-in .security-24:before {
	content: '\e05d';
	color: #5e5e5e;
}
#okta-sign-in .dashboard-24:before {
	content: '\e05e';
	color: #5e5e5e;
}
#okta-sign-in .dashboard-24:after {
	content: '\e05f';
	color: #007dc1;
}
#okta-sign-in .tasks-notepad-24:before {
	content: '\e060';
	color: #5e5e5e;
}
#okta-sign-in .tasks-notepad-24:after {
	content: '\e061';
	color: #007dc1;
}
#okta-sign-in .profile-sync-24:before {
	content: '\e067';
	color: #5e5e5e;
}
#okta-sign-in .profile-sync-24:after {
	content: '\e004';
	color: #007dc1;
}
#okta-sign-in .light-bulb-24:before {
	content: '\e062';
	color: #fff;
}
#okta-sign-in .warning-important-24:before {
	content: '\e058';
	color: #fff;
}
#okta-sign-in .download-24:before {
	content: '\e03f';
	color: #5e5e5e;
}
#okta-sign-in .icon-devices-24:before {
	content: '\e069';
	color: #5e5e5e;
}
#okta-sign-in .icon-laptop-32:before {
	content: '\e08d';
	color: #5e5e5e;
}
#okta-sign-in .icon-iphone-32:before {
	content: '\e08c';
	color: #5e5e5e;
}
#okta-sign-in .icon-ipad-32:before {
	content: '\e08b';
	color: #5e5e5e;
}
#okta-sign-in .icon-ipod-touch-32:before,
#okta-sign-in .icon-unknown-32:before {
	content: '\e08c';
	color: #5e5e5e;
}
#okta-sign-in .icon-android-phone-32:before {
	content: '\e086';
	color: #5e5e5e;
}
#okta-sign-in .icon-android-tab-32:before {
	content: '\e087';
	color: #5e5e5e;
}
#okta-sign-in .teach-48:before {
	content: '\e074';
	color: #fff;
}
#okta-sign-in #universal-directory-profiles .data-list-empty-img {
	background-image: none;
	top: 0;
	left: 0;
	position: relative;
	margin-left: 0;
}
#okta-sign-in #universal-directory-profiles .data-list-empty-img:before {
	content: '\e067';
	top: -95px;
}
#okta-sign-in #universal-directory-profiles .data-list-empty-img:after,
#okta-sign-in #universal-directory-profiles .data-list-empty-img:before {
	color: #a7a7a7;
	font-size: 100px;
	display: block;
	position: absolute;
	white-space: normal;
	text-indent: 0;
	font-family: okticon;
	font-weight: 300 !important;
	font-style: normal !important;
	speak: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-smoothing: antialiased;
	margin-left: 345px;
}
#okta-sign-in #universal-directory-profiles .data-list-empty-img:after {
	content: '\e004';
	top: -98px;
}
#okta-sign-in [class*='-light']:after,
#okta-sign-in [class*='-light']:before,
#okta-sign-in [class^='-light']:after,
#okta-sign-in [class^='-light']:before {
	font-size: 14px;
	display: block;
	position: absolute;
	white-space: normal;
	text-indent: 0;
	font-family: okticon;
	font-weight: 300 !important;
	font-style: normal !important;
	speak: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-smoothing: antialiased;
}
#okta-sign-in .green-light:before {
	content: '\e072';
	color: #4cbf9c;
}
#okta-sign-in .yellow-light:before {
	content: '\e072';
	color: #f1d10d;
}
#okta-sign-in .red-light:before {
	content: '\e072';
	color: #e34843;
}
#okta-sign-in .gray-light:before {
	content: '\e072';
	color: #a7a7a7;
	font-size: 14px;
	display: block;
	position: absolute;
	white-space: normal;
	text-indent: 0;
	font-family: okticon;
	font-weight: 300 !important;
	font-style: normal !important;
	speak: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-smoothing: antialiased;
}
#okta-sign-in .legend-light:before {
	color: #f9f9f9;
	content: '\e072';
}
#okta-sign-in .legend-light:after {
	color: #5e5e5e;
	content: '\e073';
}
#okta-sign-in .link-button.button-error .icon.info-16-gray:before,
#okta-sign-in .link-button.button-primary .icon.active-sync-16:after,
#okta-sign-in .link-button.button-primary .icon.active-sync-16:before {
	color: #fff;
}
#okta-sign-in .single-click-edit-controls .icon.cancel-16-small:before {
	font-size: 12px;
	left: 2px;
}
#okta-sign-in .search-box .clear-input-16:before {
	font-size: 12px;
}
#okta-sign-in .facebook-square-16:before {
	color: #3b5998;
	content: '\e077';
}
#okta-sign-in .facebook-16:before {
	color: #3b5998;
	content: '\e078';
}
#okta-sign-in .google-square-16:before {
	color: #dd4b39;
	content: '\e079';
}
#okta-sign-in .google-16:before {
	color: #dd4b39;
	content: '\e07f';
}
#okta-sign-in .twitter-square-16:before {
	color: #5e5e5e;
	content: '\e07c';
}
#okta-sign-in .twitter-16:before {
	color: #5e5e5e;
	content: '\e07b';
}
#okta-sign-in .linkedin-square-16:before {
	color: #5e5e5e;
	content: '\e07e';
}
#okta-sign-in .linkedin-16:before {
	color: #5e5e5e;
	content: '\e07d';
}
#okta-sign-in .openid-16:before {
	color: #5e5e5e;
	content: '\e07a';
}
#okta-sign-in a,
#okta-sign-in abbr,
#okta-sign-in acronym,
#okta-sign-in address,
#okta-sign-in applet,
#okta-sign-in b,
#okta-sign-in big,
#okta-sign-in blockquote,
#okta-sign-in body,
#okta-sign-in caption,
#okta-sign-in center,
#okta-sign-in cite,
#okta-sign-in code,
#okta-sign-in dd,
#okta-sign-in del,
#okta-sign-in dfn,
#okta-sign-in div,
#okta-sign-in dl,
#okta-sign-in dt,
#okta-sign-in em,
#okta-sign-in fieldset,
#okta-sign-in form,
#okta-sign-in h1,
#okta-sign-in h2,
#okta-sign-in h3,
#okta-sign-in h4,
#okta-sign-in h5,
#okta-sign-in h6,
#okta-sign-in html,
#okta-sign-in i,
#okta-sign-in iframe,
#okta-sign-in img,
#okta-sign-in ins,
#okta-sign-in kbd,
#okta-sign-in label,
#okta-sign-in legend,
#okta-sign-in li,
#okta-sign-in object,
#okta-sign-in ol,
#okta-sign-in p,
#okta-sign-in pre,
#okta-sign-in q,
#okta-sign-in s,
#okta-sign-in samp,
#okta-sign-in small,
#okta-sign-in span,
#okta-sign-in strike,
#okta-sign-in strong,
#okta-sign-in sub,
#okta-sign-in sup,
#okta-sign-in table,
#okta-sign-in tbody,
#okta-sign-in td,
#okta-sign-in tfoot,
#okta-sign-in th,
#okta-sign-in thead,
#okta-sign-in tr,
#okta-sign-in tt,
#okta-sign-in u,
#okta-sign-in ul,
#okta-sign-in var {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	background: transparent;
}
#okta-sign-in body {
	line-height: 1;
}
#okta-sign-in ol,
#okta-sign-in ul {
	list-style: none;
}
#okta-sign-in blockquote,
#okta-sign-in q {
	quotes: none;
}
#okta-sign-in blockquote:after,
#okta-sign-in blockquote:before,
#okta-sign-in q:after,
#okta-sign-in q:before {
	content: '';
	content: none;
}
#okta-sign-in :focus {
	outline: 0;
}
#okta-sign-in ins {
	text-decoration: none;
}
#okta-sign-in del {
	text-decoration: line-through;
}
#okta-sign-in table {
	border-collapse: collapse;
	border-spacing: 0;
}
#okta-sign-in input[type='hidden'] {
	display: none !important;
}
#okta-sign-in input[type='checkbox'],
#okta-sign-in input[type='radio'] {
	border: 0 !important;
	margin: 0;
	padding: 0;
}
#okta-sign-in .dropdown {
	background: #fff;
	border-radius: 4px;
}
#okta-sign-in .dropdown .options {
	position: relative;
}
#okta-sign-in .dropdown .option-selected {
	display: block;
	cursor: pointer;
	padding: 5px;
	position: relative;
}
#okta-sign-in .dropdown .option-selected:hover {
	cursor: pointer;
}
#okta-sign-in .dropdown .option-selected span.more-options {
	display: block;
	position: absolute;
	right: 0;
	top: 4px;
	height: 30px;
	width: 30px;
}
#okta-sign-in .dropdown .option-selected:hover span.more-options {
	display: block;
}
#okta-sign-in .dropdown .options {
	display: none;
}
#okta-sign-in .dropdown .options-wrap {
	padding: 0;
	list-style: none;
	position: absolute;
	left: 0;
	top: 2px;
	z-index: 4;
	background: #fff;
	border: 1px solid #bbb;
	width: 417px;
	box-shadow: 0 2px 0 hsla(0, 0%, 62.7%, 0.2);
}
#okta-sign-in .dropdown .options li.option {
	padding: 5px;
	display: block;
	border-bottom: 1px solid #eaeaea;
	background: #fff;
	position: relative;
}
#okta-sign-in .dropdown .options li.option:last-child {
	border-bottom: none;
}
#okta-sign-in .dropdown .options li.error {
	padding: 10px 5px 10px 25px;
	background: url(../img/icons/16x16/error-01.png) 5px 8px no-repeat !important;
	background-color: #fff2f2 !important;
}
#okta-sign-in .dropdown .options li.error:hover {
	background-color: #fce5e6 !important;
	cursor: pointer;
}
#okta-sign-in .dropdown .options li.option:hover {
	background: #f9f9f9;
	cursor: pointer;
}
#okta-sign-in .dropdown.more-actions .option-selected {
	float: none !important;
	position: relative;
	padding: 0 22px 0 8px !important;
	overflow: hidden;
	color: #333;
	white-space: nowrap;
}
#okta-sign-in .page-links .dropdown.more-actions .option-selected {
	margin: 0 !important;
}
#okta-sign-in .dropdown.more-actions .option-selected span.icon-dm {
	position: absolute;
	height: 6px;
	width: 9px;
	margin: 0;
	top: 12px;
	right: 6px;
}
#okta-sign-in .dropdown.more-actions .button-primary.option-selected span.icon-dm {
	background: url(../img/ui/indicators/drop-menu-s-white.png) no-repeat scroll 0 0 transparent;
}
#okta-sign-in .dropdown.more-actions .options-wrap {
	border-style: solid;
	width: 200px;
	left: -100px;
	margin: 0;
}
#okta-sign-in #add-idp-dropdown.more-actions .options-wrap {
	left: 0;
}
#okta-sign-in #idp-container .btnCol .dropdown.more-actions .options-wrap {
	left: -95px;
}
#okta-sign-in .dropdown.more-actions .options {
	position: absolute;
	z-index: 5;
}
#okta-sign-in .dropdown.more-actions .options .option {
	padding: 0;
}
#okta-sign-in .dropdown.more-actions .option a {
	color: #5e5e5e;
	font-size: 13px;
	padding: 10px 5px 10px 30px;
	display: block;
	line-height: 16px;
}
#okta-sign-in .dropdown.more-actions .option a:hover {
	text-decoration: none;
}
#okta-sign-in .dropdown.more-actions .option a .icon {
	height: 16px;
	width: 16px;
	position: absolute;
	top: 9px;
	left: 8px;
}
#okta-sign-in .dropdown-edit {
	background: transparent;
}
#okta-sign-in .dropdown-edit .option-selected {
	width: 300px;
	margin: 0;
	border-radius: 4px 0 0 4px;
	border-right: none;
}
#okta-sign-in #customize-user-account-dropdown .option-selected.dropdown-disabled,
#okta-sign-in #customize-user-account-dropdown .option-selected.dropdown-disabled:hover {
	background: transparent !important;
	box-shadow: none;
	cursor: default;
	opacity: 0.5;
	border-color: #bbb #bbb #b1b1b1;
}
#okta-sign-in .dropdown-edit .option-selected.dropdown-disabled .dropdown-selected-text {
	color: #888;
}
#okta-sign-in .dropdown-edit .option-selected.dropdown-disabled .icon-dm {
	opacity: 0.4;
	filter: Alpha(Opacity=40);
}
#okta-sign-in .dropdown-edit .option-selected.dropdown-disabled span:hover {
	cursor: default;
}
#okta-sign-in .dropdown-edit .option-selected.dropdown-disabled:active,
#okta-sign-in .dropdown-edit .option-selected.dropdown-disabled:hover {
	background: url(../img/ui/button/okta-buttons-sprite-001.png) repeat-x scroll 0 0 #eaeaea !important;
}
#okta-sign-in .dropdown.dropdown-edit .options-wrap {
	left: 0;
	width: 330px;
}
#okta-sign-in .dropdown.dropdown-edit .option .option-link {
	padding: 6px 8px;
}
#okta-sign-in .dropdown-edit-button {
	width: 55px;
	margin: 0 !important;
	border-radius: 0 4px 4px 0;
	border-left-color: #bbb !important;
}
#okta-sign-in .option-subtitle {
	color: #a7a7a7;
	font-weight: 400;
}
#okta-sign-in p.option-subtitle {
	margin-top: 3px;
}
#okta-sign-in #more-actions-dropdown.more-actions .options-wrap {
	left: -142px;
}
#okta-sign-in #people-more-actions-dropdown.more-actions .options-wrap {
	left: 10px;
}
#okta-sign-in #import-people-dropdown.more-actions .options-wrap {
	left: -150px;
	width: 280px;
}
#okta-sign-in #add-directory-dropdown.more-actions .options-wrap {
	left: 0;
	width: 240px;
}
#okta-sign-in #download-agent-dropdown.more-actions .options-wrap {
	left: 0;
}
#okta-sign-in .chzn-container {
	position: relative;
	display: block;
	vertical-align: middle;
	float: left;
	zoom: 1;
	*display: inline;
}
#okta-sign-in .chzn-container .chzn-drop {
	background: #fff;
	border: 1px solid #888;
	border-top: 0;
	position: absolute;
	top: 100%;
	left: -9999px;
	-webkit-box-shadow: 0 1px 2px hsla(0, 0%, 68.6%, 0.15);
	-moz-box-shadow: 0 1px 2px hsla(0, 0%, 68.6%, 0.15);
	box-shadow: 0 1px 2px hsla(0, 0%, 68.6%, 0.15);
	z-index: 1010;
	width: 100%;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-khtml-box-sizing: border-box;
	box-sizing: border-box;
}
#okta-sign-in .chzn-container.chzn-with-drop .chzn-drop {
	left: 0;
}
#okta-sign-in .chzn-container-single .chzn-single {
	-moz-background-clip: padding;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	display: block;
	overflow: hidden;
	white-space: nowrap;
	position: relative;
	height: 28px;
	line-height: 28px;
	padding: 0 0 0 8px;
	text-decoration: none;
}
#okta-sign-in .chzn-container-single .chzn-single:hover {
	border: 1px solid #888;
}
#okta-sign-in .chzn-container-single .chzn-default {
	color: #999;
}
#okta-sign-in .chzn-container-single .chzn-single span {
	margin-right: 26px;
	display: block;
	overflow: hidden;
	white-space: nowrap;
	-o-text-overflow: ellipsis;
	-ms-text-overflow: ellipsis;
	text-overflow: ellipsis;
	color: #333;
	text-shadow: 0 1px 0 #fff;
}
#okta-sign-in .chzn-container-single .chzn-single abbr {
	display: block;
	position: absolute;
	right: 26px;
	top: 6px;
	width: 12px;
	height: 12px;
	font-size: 1px;
	background: url(../img/ui/forms/chosen-sprite.png) -42px 1px no-repeat;
}
#okta-sign-in .chzn-container-single.chzn-disabled .chzn-single abbr:hover,
#okta-sign-in .chzn-container-single .chzn-single abbr:hover {
	background-position: -42px -10px;
}
#okta-sign-in .chzn-container-single .chzn-single div {
	position: absolute;
	right: 0;
	top: 3px;
	display: block;
	height: 100%;
	width: 18px;
}
#okta-sign-in .chzn-container-single .chzn-single div b {
	background: url(../img/ui/forms/chosen-sprite.png) no-repeat 0 2px;
	display: block;
	width: 100%;
	height: 100%;
}
#okta-sign-in .chzn-container-single .chzn-search {
	padding: 3px 4px;
	position: relative;
	margin: 0;
	white-space: nowrap;
	z-index: 1010;
}
#okta-sign-in .chzn-container-single .chzn-search input {
	background: #fff url(../img/ui/forms/chosen-sprite.png) no-repeat 100% -20px;
	background: url(../img/ui/forms/chosen-sprite.png) no-repeat 100% -20px,
		-webkit-gradient(linear, 0 0, 0 100%, color-stop(1%, #eee), color-stop(15%, #fff));
	background: url(../img/ui/forms/chosen-sprite.png) no-repeat 100% -20px,
		-webkit-linear-gradient(top, #eee 1%, #fff 15%);
	background: url(../img/ui/forms/chosen-sprite.png) no-repeat 100% -20px,
		-moz-linear-gradient(top, #eee 1%, #fff 15%);
	background: url(../img/ui/forms/chosen-sprite.png) no-repeat 100% -20px,
		-o-linear-gradient(top, #eee 1%, #fff 15%);
	background: url(../img/ui/forms/chosen-sprite.png) no-repeat 100% -20px,
		-webkit-linear-gradient(#eee 1%, #fff 15%);
	background: url(../img/ui/forms/chosen-sprite.png) no-repeat 100% -20px,
		linear-gradient(#eee 1%, #fff 15%);
	margin: 1px 0;
	padding: 4px 20px 4px 5px;
	outline: 0;
	border: 1px solid #aaa;
	font-size: 1em;
	width: 100%;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-khtml-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 0;
}
#okta-sign-in .chzn-container-single .chzn-drop {
	margin-top: -1px;
	-webkit-border-radius: 0 0 4px 4px;
	-moz-border-radius: 0 0 4px 4px;
	border-radius: 0 0 4px 4px;
	-moz-background-clip: padding;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
}
#okta-sign-in .chzn-container-single-nosearch .chzn-search {
	position: absolute;
	left: -9999px;
}
#okta-sign-in .chzn-container-multi .chzn-choices {
	background-color: #fff;
	background-image: -webkit-gradient(
		linear,
		0 0,
		0 100%,
		color-stop(1%, #eee),
		color-stop(15%, #fff)
	);
	background-image: -webkit-linear-gradient(top, #eee 1%, #fff 15%);
	background-image: -moz-linear-gradient(top, #eee 1%, #fff 15%);
	background-image: -o-linear-gradient(top, #eee 1%, #fff 15%);
	background-image: -webkit-linear-gradient(#eee 1%, #fff 15%);
	background-image: linear-gradient(#eee 1%, #fff 15%);
	border: 1px solid #aaa;
	margin: 0;
	padding: 0;
	cursor: text;
	overflow: hidden;
	height: auto !important;
	height: 1%;
	position: relative;
	width: 100%;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-khtml-box-sizing: border-box;
	box-sizing: border-box;
}
#okta-sign-in .chzn-container-multi .chzn-choices li {
	float: left;
	list-style: none;
}
#okta-sign-in .chzn-container-multi .chzn-choices .search-field {
	white-space: nowrap;
	margin: 0;
	padding: 0;
}
#okta-sign-in .chzn-container-multi .chzn-choices .search-field input {
	color: #666;
	background: transparent !important;
	border: 0 !important;
	font-size: 100%;
	height: 15px;
	padding: 5px;
	margin: 1px 0;
	outline: 0;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}
#okta-sign-in .chzn-container-multi .chzn-choices .search-field .default {
	color: #999;
}
#okta-sign-in .chzn-container-multi .chzn-choices .search-choice {
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	-moz-background-clip: padding;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	background-color: #e4e4e4;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr = "#f4f4f4",endColorstr = "#eeeeee",GradientType = 0);
	background-image: -webkit-gradient(
		linear,
		0 0,
		0 100%,
		color-stop(20%, #f4f4f4),
		color-stop(50%, #f0f0f0),
		color-stop(52%, #e8e8e8),
		color-stop(100%, #eee)
	);
	background-image: -webkit-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee);
	background-image: -moz-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
	background-image: -o-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
	background-image: -webkit-linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee);
	background-image: linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee);
	-webkit-box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
	-moz-box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
	box-shadow: inset 0 0 2px #fff, 0 1px 0 rgba(0, 0, 0, 0.05);
	color: #333;
	border: 1px solid #aaa;
	line-height: 13px;
	padding: 3px 20px 3px 5px;
	margin: 3px 0 3px 5px;
	position: relative;
	cursor: default;
}
#okta-sign-in .chzn-container-multi .chzn-choices .search-choice.search-choice-disabled {
	background-color: #e4e4e4;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr = "#f4f4f4",endColorstr = "#eeeeee",GradientType = 0);
	background-image: -webkit-gradient(
		linear,
		0 0,
		0 100%,
		color-stop(20%, #f4f4f4),
		color-stop(50%, #f0f0f0),
		color-stop(52%, #e8e8e8),
		color-stop(100%, #eee)
	);
	background-image: -webkit-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee);
	background-image: -moz-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
	background-image: -o-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
	background-image: -ms-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
	background-image: linear-gradient(180deg, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee);
	color: #666;
	border: 1px solid #ccc;
	padding-right: 5px;
}
#okta-sign-in .chzn-container-multi .chzn-choices .search-choice-focus {
	background: #d4d4d4;
}
#okta-sign-in .chzn-container-multi .chzn-choices .search-choice .search-choice-close {
	display: block;
	position: absolute;
	right: 3px;
	top: 4px;
	width: 12px;
	height: 12px;
	font-size: 1px;
	background: url(../img/ui/forms/chosen-sprite.png) -42px 1px no-repeat;
}
#okta-sign-in .chzn-container-multi .chzn-choices .search-choice-focus .search-choice-close,
#okta-sign-in .chzn-container-multi .chzn-choices .search-choice .search-choice-close:hover {
	background-position: -42px -10px;
}
#okta-sign-in .chzn-container .chzn-results {
	margin: 3px 0 4px;
	max-height: 240px;
	padding: 0;
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}
#okta-sign-in .chzn-container-multi .chzn-results {
	margin: 0;
	padding: 0;
}
#okta-sign-in .chzn-container .chzn-results li {
	display: none;
	line-height: 16px;
	padding: 7px 8px;
	margin: 0;
	list-style: none;
	border: solid #fff;
	border-width: 0 0 1px;
}
#okta-sign-in .chzn-container .chzn-results .active-result {
	cursor: pointer;
	display: list-item;
}
#okta-sign-in .chzn-container .chzn-results .highlighted {
	background: #f9f9f9;
}
#okta-sign-in .chzn-container .chzn-results li em {
	background: #feffde;
	font-style: normal;
}
#okta-sign-in .chzn-container .chzn-results .highlighted em {
	background: transparent;
}
#okta-sign-in .chzn-container .chzn-results .no-results {
	background: #fff;
	display: list-item;
}
#okta-sign-in .chzn-container .chzn-results .group-result {
	cursor: default;
	color: #999;
	font-weight: 700;
}
#okta-sign-in .chzn-container .chzn-results .group-option {
	padding-left: 15px;
}
#okta-sign-in .chzn-container-multi .chzn-drop .result-selected {
	display: none;
}
#okta-sign-in .chzn-container .chzn-results-scroll {
	background: #fff;
	margin: 0 4px;
	position: absolute;
	text-align: center;
	width: 321px;
	z-index: 1;
}
#okta-sign-in .chzn-container .chzn-results-scroll span {
	display: inline-block;
	height: 17px;
	text-indent: -5000px;
	width: 9px;
}
#okta-sign-in .chzn-container .chzn-results-scroll-down {
	bottom: 0;
}
#okta-sign-in .chzn-container .chzn-results-scroll-down span {
	background: url(../img/ui/forms/chosen-sprite.png) no-repeat -4px -3px;
}
#okta-sign-in .chzn-container .chzn-results-scroll-up span {
	background: url(../img/ui/forms/chosen-sprite.png) no-repeat -22px -3px;
}
#okta-sign-in .chzn-container-active.chzn-with-drop .chzn-single {
	border: 1px solid #888;
	-webkit-box-shadow: 0 1px 0 #fff inset;
	-moz-box-shadow: 0 1px 0 #fff inset;
	box-shadow: inset 0 1px 0 #fff;
	background: #fff;
	-webkit-border-bottom-left-radius: 0;
	-webkit-border-bottom-right-radius: 0;
	-moz-border-radius-bottomleft: 0;
	-moz-border-radius-bottomright: 0;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
#okta-sign-in .chzn-container-active .chzn-single {
	-webkit-box-shadow: 0 1px 2px hsla(0, 0%, 68.6%, 0.3);
	-moz-box-shadow: 0 1px 2px hsla(0, 0%, 68.6%, 0.3);
	box-shadow: 0 1px 2px hsla(0, 0%, 68.6%, 0.3);
	border: 1px solid #888;
}
#okta-sign-in .chzn-container-active.chzn-with-drop .chzn-single div {
	background: transparent;
	border-left: none;
}
#okta-sign-in .chzn-container-active.chzn-with-drop .chzn-single div b {
	background-position: -18px 2px;
}
#okta-sign-in .chzn-container-active .chzn-choices {
	-webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
	border: 1px solid #5897fb;
}
#okta-sign-in .chzn-container-active .chzn-choices .search-field input {
	color: #111 !important;
}
#okta-sign-in .chzn-disabled {
	cursor: default;
	opacity: 0.5 !important;
}
#okta-sign-in .chzn-disabled .chzn-choices .search-choice .search-choice-close,
#okta-sign-in .chzn-disabled .chzn-single {
	cursor: default;
}
#okta-sign-in .chzn-rtl {
	text-align: right;
}
#okta-sign-in .chzn-rtl .chzn-single {
	padding: 0 8px 0 0;
	overflow: visible;
}
#okta-sign-in .chzn-rtl .chzn-single span {
	margin-left: 26px;
	margin-right: 0;
	direction: rtl;
}
#okta-sign-in .chzn-rtl .chzn-single div {
	left: 3px;
	right: auto;
}
#okta-sign-in .chzn-rtl .chzn-single abbr {
	left: 26px;
	right: auto;
}
#okta-sign-in .chzn-rtl .chzn-choices .search-field input {
	direction: rtl;
}
#okta-sign-in .chzn-rtl .chzn-choices li {
	float: right;
}
#okta-sign-in .chzn-rtl .chzn-choices .search-choice {
	padding: 3px 5px 3px 19px;
	margin: 3px 5px 3px 0;
}
#okta-sign-in .chzn-rtl .chzn-choices .search-choice .search-choice-close {
	left: 4px;
	right: auto;
}
#okta-sign-in .chzn-rtl .chzn-search {
	left: 9999px;
}
#okta-sign-in .chzn-rtl.chzn-with-drop .chzn-search {
	left: 0;
}
#okta-sign-in .chzn-rtl .chzn-drop {
	left: 9999px;
}
#okta-sign-in .chzn-rtl.chzn-container-single .chzn-results {
	margin: 0 0 4px 4px;
	padding: 0 4px 0 0;
}
#okta-sign-in .chzn-rtl .chzn-results .group-option {
	padding-left: 0;
	padding-right: 15px;
}
#okta-sign-in .chzn-rtl.chzn-container-active.chzn-with-drop .chzn-single div {
	border-right: none;
}
#okta-sign-in .chzn-rtl .chzn-search input {
	background: #fff url(../img/ui/forms/chosen-sprite.png) no-repeat -30px -20px;
	background: url(../img/ui/forms/chosen-sprite.png) no-repeat -30px -20px,
		-webkit-gradient(linear, 0 0, 0 100%, color-stop(1%, #eee), color-stop(15%, #fff));
	background: url(../img/ui/forms/chosen-sprite.png) no-repeat -30px -20px,
		-webkit-linear-gradient(top, #eee 1%, #fff 15%);
	background: url(../img/ui/forms/chosen-sprite.png) no-repeat -30px -20px,
		-moz-linear-gradient(top, #eee 1%, #fff 15%);
	background: url(../img/ui/forms/chosen-sprite.png) no-repeat -30px -20px,
		-o-linear-gradient(top, #eee 1%, #fff 15%);
	background: url(../img/ui/forms/chosen-sprite.png) no-repeat -30px -20px,
		-webkit-linear-gradient(#eee 1%, #fff 15%);
	background: url(../img/ui/forms/chosen-sprite.png) no-repeat -30px -20px,
		linear-gradient(#eee 1%, #fff 15%);
	padding: 4px 5px 4px 20px;
	direction: rtl;
}
#okta-sign-in .chzn-container-single.chzn-rtl .chzn-single div b {
	background-position: 6px 2px;
}
#okta-sign-in .chzn-container-single.chzn-rtl.chzn-with-drop .chzn-single div b {
	background-position: -12px 2px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in .chzn-container-multi .chzn-choices .search-choice .search-choice-close,
	#okta-sign-in .chzn-container-single .chzn-search input,
	#okta-sign-in .chzn-container-single .chzn-single abbr,
	#okta-sign-in .chzn-container-single .chzn-single div b,
	#okta-sign-in .chzn-container .chzn-results-scroll-down span,
	#okta-sign-in .chzn-container .chzn-results-scroll-up span,
	#okta-sign-in .chzn-rtl .chzn-search input {
		background-image: url(../img/ui/forms/chosen-sprite@2x.png) !important;
		background-repeat: no-repeat !important;
		background-size: 52px 37px !important;
	}
}
#okta-sign-in .o-form-fieldset:after {
	content: '.';
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}
#okta-sign-in .o-form-fieldset {
	height: auto;
	word-wrap: break-word;
	display: inline-block;
	margin-bottom: 15px;
}
#okta-sign-in .o-form-fieldset:last-child {
	margin-bottom: 0;
}
#okta-sign-in * html .o-form-fieldset {
	height: 1%;
}
#okta-sign-in .o-form-fieldset {
	display: block;
}
#okta-sign-in .o-form-input,
#okta-sign-in .o-form-label {
	float: left;
}
#okta-sign-in .o-form-label {
	width: 38%;
	font-weight: 700;
	padding: 7px 10px 7px 0;
	line-height: 16px;
	color: #5e5e5e;
	box-sizing: border-box;
}
#okta-sign-in .o-form-label a.icon-16 {
	display: inline;
	margin-left: 6px;
	top: -1px;
}
#okta-sign-in .o-form-input {
	width: 62%;
}
#okta-sign-in .o-form-input .o-form-input-group + .link-button,
#okta-sign-in .o-form-input .o-form-input-group + .o-form-input-group {
	margin-top: 15px;
}
#okta-sign-in .o-form-input .array-inputs-button {
	clear: both;
}
#okta-sign-in .o-form-input .o-form-input-group-controls {
	width: 100%;
}
#okta-sign-in .o-form .input-fix {
	height: 30px;
}
#okta-sign-in .o-form .input-fix input[type='number'],
#okta-sign-in .o-form .input-fix input[type='password'],
#okta-sign-in .o-form .input-fix input[type='text'],
#okta-sign-in .o-form .input-fix input[type='textbox'] {
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 6px 8px;
	line-height: 16px;
	border: none;
	background: transparent;
	box-sizing: border-box;
}
#okta-sign-in .o-form .input-fix input.password-with-toggle {
	padding-right: 35px;
}
#okta-sign-in .o-form .input-fix .password-toggle .eyeicon {
	right: 5px;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 32px;
	height: 32px;
	padding: 6px 8px;
	opacity: 0.5;
	cursor: pointer;
}
#okta-sign-in .o-form .input-fix .password-toggle .button-hide {
	display: none;
}
#okta-sign-in .o-form .input-fix input[type='number'] {
	border: none;
}
#okta-sign-in .o-form .input-fix,
#okta-sign-in .o-form .textarea-fix {
	border-radius: 3px;
	width: 100%;
	box-sizing: border-box;
}
#okta-sign-in .o-form .textarea-fix textarea {
	height: auto;
	width: 100%;
	margin: 0;
	padding: 6px 8px;
	line-height: 16px;
	box-sizing: border-box;
	min-height: 30px;
	max-width: 100% !important;
}
#okta-sign-in .o-form select {
	width: 62%;
	margin-top: 6px;
}
#okta-sign-in .o-form-wide select {
	width: 100%;
}
#okta-sign-in .o-form-label-attached .o-form-label {
	background-color: #e2e2e2;
	border-bottom-left-radius: 3px;
	border-top-left-radius: 3px;
	border: 1px solid #c8c8c8;
	border-right-width: 0;
	font-weight: 400;
	padding: 6px 10px;
	width: auto;
}
#okta-sign-in .o-form-label-attached .chzn-single,
#okta-sign-in .o-form-label-attached .input-fix,
#okta-sign-in .o-form-label-attached .input[type='text'],
#okta-sign-in .o-form-label-attached .textarea-fix {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}
#okta-sign-in .o-form-label-attached .link-button.option-selected {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	box-shadow: none;
}
#okta-sign-in .data-list-toolbar .o-form-label-attached .o-form-label {
	background: transparent;
}
#okta-sign-in .o-form-input-group {
	position: relative;
	display: table;
	border-collapse: separate;
}
#okta-sign-in .o-form-input-group .link-button,
#okta-sign-in .o-form-input-group .o-form-control,
#okta-sign-in .o-form-input-group .o-form-label-inline {
	display: table-cell;
	float: none;
	border-radius: 0;
	white-space: nowrap;
}
#okta-sign-in .o-form-input-group .link-button input[type='number'],
#okta-sign-in .o-form-input-group .o-form-control input[type='number'],
#okta-sign-in .o-form-input-group .o-form-label-inline input[type='number'] {
	padding-bottom: 0;
}
#okta-sign-in .o-form-input-group :first-child {
	border-bottom-left-radius: 3px;
	border-top-left-radius: 3px;
}
#okta-sign-in .o-form-input-group :last-child {
	border-bottom-right-radius: 3px;
	border-top-right-radius: 3px;
	border-left-width: 0;
	border-right-width: 1px;
}
#okta-sign-in .o-form-input-group .link-button:first-child,
#okta-sign-in .o-form-input-group .o-form-label-inline:first-child {
	border-right-width: 0;
}
#okta-sign-in .o-form-input-group .o-form-control:last-child {
	border-left-width: 1px;
}
#okta-sign-in .o-form-input-group .o-form-control + .o-form-control {
	margin: 0;
}
#okta-sign-in .o-form-input-group .link-button {
	box-shadow: none;
	padding: 0 16px;
}
#okta-sign-in .o-form-input-group .link-button-icon.icon-only {
	padding: 0 8px;
}
#okta-sign-in .o-form-input-group .link-button-icon span.icon {
	top: 5px;
}
#okta-sign-in .o-form-input-group .o-form-control .chzn-single {
	box-shadow: none;
}
#okta-sign-in .o-form-input-group select:first-child + .o-form-control.chzn-container .chzn-single {
	border-bottom-right-radius: 0;
	border-right-width: 0;
	border-top-right-radius: 0;
}
#okta-sign-in .o-form-input-group .o-form-control:last-child .chzn-single {
	border-bottom-left-radius: 0;
	border-left-width: 0;
	border-top-left-radius: 0;
}
#okta-sign-in
	.o-form-input-group
	.o-form-control.chzn-container-active.chzn-with-drop:last-child
	.chzn-single {
	border-left-width: 1px;
	margin-left: -1px;
}
#okta-sign-in
	.o-form-input-group
	.o-form-control.chzn-container-active.chzn-with-drop:last-child
	.chzn-drop {
	border-left-width: 1px;
	border-top-right-radius: 0;
	left: -1px;
	width: 101%;
}
#okta-sign-in
	.o-form-input-group
	select:first-child
	+ .o-form-control.chzn-container-active.chzn-with-drop
	.chzn-single {
	border-right-width: 1px;
	margin-right: -1px;
}
#okta-sign-in
	.o-form-input-group
	select:first-child
	+ .o-form-control.chzn-container-active.chzn-with-drop
	.chzn-drop {
	border-left-width: 1px;
	right: -1px;
	width: 101%;
}
#okta-sign-in .o-form-label-inline {
	padding: 6px 10px;
	color: #999;
	background-color: #f2f2f2;
	border-bottom-left-radius: 3px;
	border-top-left-radius: 3px;
	border: 1px solid #c8c8c8;
	border-right-width: 0;
	font-weight: 400;
}
#okta-sign-in .o-form-input-group.o-form-input-group-2 .o-form-control {
	width: 50%;
}
#okta-sign-in .o-form-input-group.o-form-input-group-2 .o-form-label-inline {
	border-left-width: 0;
}
#okta-sign-in .o-form-control + .o-form-control {
	margin-top: 20px;
}
#okta-sign-in .o-form-multi-input-2 .o-form-control,
#okta-sign-in .o-form-multi-input-3 .o-form-control,
#okta-sign-in .o-form-multi-input-4 .o-form-control,
#okta-sign-in .o-form-multi-input .o-form-control {
	box-sizing: border-box;
}
#okta-sign-in .o-form-multi-input-2 .o-form-control,
#okta-sign-in .o-form-multi-input-3 .o-form-control,
#okta-sign-in .o-form-multi-input-4 .o-form-control {
	margin-top: 0;
}
#okta-sign-in .o-form-multi-input-2 .o-form-control,
#okta-sign-in .o-form-multi-input-2 .o-form-input-group {
	width: 49%;
}
#okta-sign-in .o-form-multi-input-2 .o-form-input-group,
#okta-sign-in .o-form-multi-input-3 .o-form-input-group,
#okta-sign-in .o-form-multi-input-4 .o-form-input-group {
	float: left;
}
#okta-sign-in .o-form-multi-input-3 .o-form-control,
#okta-sign-in .o-form-multi-input-3 .o-form-input-group {
	width: 32%;
}
#okta-sign-in .o-form-multi-input-2 .o-form-control + .o-form-control,
#okta-sign-in .o-form-multi-input-3 .o-form-control + .o-form-control {
	margin-left: 2%;
}
#okta-sign-in .o-form-multi-input-2 .o-form-input-group,
#okta-sign-in .o-form-multi-input-3 .o-form-input-group {
	margin-top: 0 !important;
}
#okta-sign-in .o-form-multi-input-2 .chzn-done + .o-form-control,
#okta-sign-in .o-form-multi-input-2 .o-form-control + .o-form-control,
#okta-sign-in .o-form-multi-input-3 .chzn-done + .o-form-control,
#okta-sign-in .o-form-multi-input-3 .o-form-control + .o-form-control,
#okta-sign-in .o-form-multi-input-group-2 .o-form-control:not(:first-child),
#okta-sign-in .o-form-multi-input-group-2 .o-form-input-group:not(:first-child),
#okta-sign-in .o-form-multi-input-group-3 .o-form-control:not(:first-child),
#okta-sign-in .o-form-multi-input-group-3 .o-form-input-group:not(:first-child) {
	margin-left: 2%;
}
#okta-sign-in .o-form-select {
	width: 100%;
	display: inline-block;
	float: left;
}
#okta-sign-in .o-form-multi-input-2 .o-form-select .chzn-container,
#okta-sign-in .o-form-multi-input-3 .o-form-select .chzn-container {
	width: 100% !important;
}
#okta-sign-in .o-form-multi-input-2 .chzn-container {
	width: 49% !important;
}
#okta-sign-in .o-form-multi-input-3 .chzn-container {
	width: 32% !important;
}
#okta-sign-in .o-form-wide .chzn-container {
	width: 100% !important;
}
#okta-sign-in .text-select .input-fix {
	width: 84%;
	border-right: none;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
#okta-sign-in .chzn-container-single.text-select .chzn-single {
	width: 16%;
	height: 30px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-color: #bbb #bbb #b1b1b1;
	cursor: pointer;
	box-sizing: border-box;
}
#okta-sign-in .text-select.chzn-with-drop input[type='text'] {
	border-color: #888;
	border-bottom-left-radius: 0;
	box-shadow: inset 0 -1px 0 0 #aaa;
}
#okta-sign-in .custom-checkbox,
#okta-sign-in .custom-radio {
	position: relative;
	clear: both;
}
#okta-sign-in .custom-checkbox input,
#okta-sign-in .custom-radio input {
	opacity: 0;
	filter: Alpha(Opacity=0);
	position: absolute;
	left: 0;
	top: 7px;
	margin: 0;
}
#okta-sign-in .custom-checkbox label,
#okta-sign-in .custom-radio label {
	display: block;
	position: relative;
	line-height: 16px;
	margin: 0;
	padding: 7px 10px 7px 24px;
	cursor: pointer;
}
#okta-sign-in .custom-checkbox label {
	background: url(../img/ui/forms/checkbox-01.png) no-repeat;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2),
	only screen and (min-device-pixel-ratio: 2),
	only screen and (min-resolution: 2dppx) {
	#okta-sign-in .custom-checkbox label {
		background: url(../img/ui/forms/checkbox-01@2x.png) no-repeat;
		background-size: 50px 1155px;
	}
}
#okta-sign-in .custom-radio label {
	background: url(../img/ui/forms/radiobutton-01.png) no-repeat;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2),
	only screen and (min-device-pixel-ratio: 2),
	only screen and (min-resolution: 2dppx) {
	#okta-sign-in .custom-radio label {
		background: url(../img/ui/forms/radiobutton-01@2x.png) no-repeat;
		background-size: 50px 820px;
	}
}
#okta-sign-in .custom-checkbox + .o-form-explain,
#okta-sign-in .custom-checkbox + input + .o-form-explain,
#okta-sign-in .custom-radio + .o-form-explain,
#okta-sign-in .custom-radio + input + .o-form-explain {
	padding-top: 0;
}
#okta-sign-in .o-form-input kbd {
	box-shadow: inset 0 1px 1px hsla(0, 0%, 58.8%, 0.16);
	background: #fff;
	padding: 10px;
	display: block;
	border-radius: 3px;
	border: 1px solid #cacaca;
}
#okta-sign-in .o-form-button-bar,
#okta-sign-in .o-form-toolbar,
#okta-sign-in .o-form-toolbar.ui-tabs {
	background: #f2f2f2;
	padding: 16px;
	box-shadow: 0 1px 1px hsla(0, 0%, 68.6%, 0.16);
	border-color: #dadada #ddd;
	border-style: solid;
	border-width: 1px;
	margin-top: -1px;
	border-radius: 0 0 3px 3px;
	text-align: right;
}
#okta-sign-in .o-form-button-bar .button + .button,
#okta-sign-in .o-form-toolbar .button + .button,
#okta-sign-in .o-form-toolbar.ui-tabs .button + .button {
	margin-left: 10px;
}
#okta-sign-in .o-form-button-bar-no-border {
	border-width: 1px 0 0;
}
#okta-sign-in .o-form-toolbar.ui-tabs {
	margin: 0;
}
#okta-sign-in .o-form-toolbar.ui-tabs .o-form-pillbar.ui-tabs-nav {
	border-bottom: 0;
	border-top: 0;
	padding: 0;
}
#okta-sign-in .o-form-toolbar.ui-tabs .o-form-pillbar.ui-tabs-nav li a {
	margin: 0;
	padding: 0 14px;
	border: 1px solid #d1d1d1;
	border-right-width: 0;
	color: #5e5e5e;
	background: #fbfbfb;
	display: block;
	float: none;
	font-size: 12px;
	font-weight: 400;
	line-height: 28px;
	overflow: hidden;
	text-align: center;
	text-overflow: ellipsis;
	white-space: nowrap;
}
#okta-sign-in .o-form-toolbar.ui-tabs .o-form-pillbar.ui-tabs-nav li + li {
	margin-left: 0;
}
#okta-sign-in .o-form-toolbar.ui-tabs .o-form-pillbar.ui-tabs-nav li:first-child a {
	border-radius: 3px 0 0 3px;
}
#okta-sign-in .o-form-toolbar.ui-tabs .o-form-pillbar.ui-tabs-nav li:last-child > a {
	border-right-width: 1px;
	border-radius: 0 3px 3px 0;
}
#okta-sign-in .o-form-toolbar.ui-tabs .o-form-pillbar.ui-tabs-nav li.ui-state-active a {
	border-right-width: 1px;
	padding-right: 19px;
	color: #fff;
	border-color: #1b90c5;
	background-color: #46b3e9;
}
#okta-sign-in
	.o-form-toolbar.ui-tabs
	.o-form-pillbar.ui-tabs-nav
	li.ui-state-active:last-child
	> a {
	border-right-color: #1b90c5;
}
#okta-sign-in .o-form-toolbar.ui-tabs .o-form-pillbar.ui-tabs-nav li.ui-state-active + li > a {
	border-left-width: 0;
	padding-left: 21px;
}
#okta-sign-in .o-form-pillbar.ui-tabs-nav > .ui-corner-top {
	background: transparent;
	border-top: none;
}
#okta-sign-in .o-form-pillbar.ui-tabs-nav li + li {
	margin-left: 0;
}
#okta-sign-in .o-form-title-bar {
	padding: 0 20px;
	line-height: 44px;
	font-size: 17px;
	font-weight: 400;
	background: #f2f2f2;
	color: #5e5e5e;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	border: solid #ddd;
	border-width: 1px 1px 0;
	margin: 0;
	position: relative;
}
#okta-sign-in .o-form-toggle {
	position: absolute;
	right: 8px;
	top: 8px;
}
#okta-sign-in .o-form-toggle a {
	display: block;
	font-size: 13px;
	height: 26px;
	line-height: 26px;
	padding: 0;
	width: 60px;
}
#okta-sign-in .data-list .o-form-title-bar {
	padding: 0;
	margin: 0 0 15px;
	color: #5e5e5e;
	border: none;
	border-radius: 0;
	box-shadow: none;
	background: transparent;
}
#okta-sign-in .data-list .o-form-toggle {
	right: 0;
}
#okta-sign-in .o-form-head {
	color: #46b090;
	text-transform: uppercase;
}
#okta-sign-in .o-form-head,
#okta-sign-in .o-form-subhead {
	line-height: 30px;
	margin: 0 0 15px;
	padding: 0;
	font-weight: 200;
}
#okta-sign-in .o-form-subhead {
	color: #a7a7a7;
	font-size: 15px;
}
#okta-sign-in .o-form-explain + .o-form-head,
#okta-sign-in .o-form-fieldset + .o-form-head,
#okta-sign-in .o-form-separator {
	border-top: 1px solid #e3e3e3;
	margin-top: 30px;
	padding-top: 30px;
}
#okta-sign-in .o-form-explain + .o-form-fieldset {
	margin-top: 20px;
}
#okta-sign-in .o-form-explain {
	color: #a7a7a7;
	font-weight: 400;
}
#okta-sign-in .o-form-explain + .o-form-explain {
	margin-top: 0;
}
#okta-sign-in .o-form-input .o-form-explain {
	clear: both;
	line-height: 18px;
	padding: 8px 0 6px;
}
#okta-sign-in .o-form-explain.o-form-input-error {
	color: #d93934;
	padding-left: 18px;
	position: relative;
	display: none;
}
#okta-sign-in .o-form-has-errors .o-form-explain.o-form-input-error {
	display: block;
}
#okta-sign-in .o-form-input-error .icon-16 {
	left: -2px;
	position: absolute !important;
	top: 7px;
}
#okta-sign-in .o-form-tooltip.icon-only {
	margin-left: 4px;
	vertical-align: top;
}
#okta-sign-in .o-form-theme {
	padding: 30px;
	background: #f9f9f9;
	box-shadow: 0 1px 1px hsla(0, 0%, 68.6%, 0.16);
	border: 1px solid;
	border-color: #ddd #ddd #d2d2d2;
	border-radius: 4px;
}
#okta-sign-in .o-form-theme-no-border {
	border: none;
	border-radius: 0;
}
#okta-sign-in .o-form-has-title-bar,
#okta-sign-in .o-form-title-bar + .o-form-theme {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
#okta-sign-in .o-form-has-button-bar {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
#okta-sign-in .simplemodal-wrap .o-form-wrap {
	padding: 30px;
}
#okta-sign-in .simplemodal-container .o-form-button-bar,
#okta-sign-in .simplemodal-wrap .o-form-button-bar {
	border-radius: 0;
	margin-top: 0;
	border-left-width: 0;
	border-right-width: 0;
}
#okta-sign-in .simplemodal-container .o-form-toolbar,
#okta-sign-in .simplemodal-wrap .o-form-toolbar {
	border-radius: 0;
	border-width: 0 1px 1px;
}
#okta-sign-in .simplemodal-container .o-form-content,
#okta-sign-in .simplemodal-wrap .o-form-content {
	overflow: auto;
}
#okta-sign-in .simplemodal-wrap .o-form-content {
	box-sizing: border-box;
}
#okta-sign-in .simplemodal-container .o-form-theme,
#okta-sign-in .simplemodal-wrap .o-form-content.o-form-theme {
	border-width: 0 1px;
	border-color: #ddd;
	border-radius: 0;
	padding: 30px;
}
#okta-sign-in .o-form-3-4 .o-form-label {
	width: 25%;
}
#okta-sign-in .o-form-3-4 .o-form-input {
	width: 75%;
}
#okta-sign-in .o-form .o-form-focus {
	border: 1px solid #888;
	box-shadow: 0 1px 1px #fff;
}
#okta-sign-in .o-form-read-mode.o-form-input,
#okta-sign-in .o-form-read-mode .o-form-input {
	padding: 7px 10px 7px 0;
	line-height: 16px;
	box-sizing: border-box;
	word-wrap: break-word;
}
#okta-sign-in .o-form-read-mode.custom-checkbox:first-child,
#okta-sign-in .o-form-read-mode .custom-checkbox:first-child,
#okta-sign-in .o-form-read-mode.custom-radio:first-child,
#okta-sign-in .o-form-read-mode .custom-radio:first-child {
	margin-top: -8px;
}
#okta-sign-in .o-form-edit-mode .o-form-content {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
#okta-sign-in .custom-checkbox label,
#okta-sign-in .custom-radio label {
	background-position: -16px -13px;
}
#okta-sign-in .custom-checkbox label.focus,
#okta-sign-in .custom-checkbox label.hover,
#okta-sign-in .custom-radio label.focus,
#okta-sign-in .custom-radio label.hover {
	background-position: -16px -113px;
}
#okta-sign-in .custom-checkbox label.checked,
#okta-sign-in .custom-radio label.checked {
	background-position: -16px -213px;
}
#okta-sign-in .custom-checkbox label.checked.focus,
#okta-sign-in .custom-checkbox label.checked.hover {
	background-position: -16px -313px;
}
#okta-sign-in .o-form-label-top .o-form-input,
#okta-sign-in .o-form-label-top .o-form-label {
	width: 100%;
}
#okta-sign-in .o-form-label-top .o-form-read-mode.o-form-input,
#okta-sign-in .o-form-read-mode .o-form-label-top .o-form-input {
	padding-left: 0;
	padding-right: 0;
}
#okta-sign-in .o-form-read-mode.custom-checkbox label,
#okta-sign-in .o-form-read-mode .custom-checkbox label {
	background-position: -16px -413px !important;
}
#okta-sign-in .o-form-read-mode.custom-radio label,
#okta-sign-in .o-form-read-mode .custom-radio label {
	background-position: -16px -313px !important;
}
#okta-sign-in .o-form-read-mode.custom-checkbox label.focus,
#okta-sign-in .o-form-read-mode .custom-checkbox label.focus,
#okta-sign-in .o-form-read-mode.custom-checkbox label.hover,
#okta-sign-in .o-form-read-mode .custom-checkbox label.hover {
	background-position: -16px -513px !important;
	cursor: text !important;
}
#okta-sign-in .o-form-read-mode.custom-radio label.focus,
#okta-sign-in .o-form-read-mode .custom-radio label.focus,
#okta-sign-in .o-form-read-mode.custom-radio label.hover,
#okta-sign-in .o-form-read-mode .custom-radio label.hover {
	background-position: -16px -413px !important;
	cursor: text;
}
#okta-sign-in .o-form-read-mode.custom-checkbox label.checked,
#okta-sign-in .o-form-read-mode .custom-checkbox label.checked {
	background-position: -16px -613px !important;
}
#okta-sign-in .o-form-read-mode.custom-radio label.checked,
#okta-sign-in .o-form-read-mode .custom-radio label.checked {
	background-position: -16px -513px !important;
}
#okta-sign-in .o-form-read-mode.custom-checkbox label.checked.focus,
#okta-sign-in .o-form-read-mode .custom-checkbox label.checked.focus,
#okta-sign-in .o-form-read-mode.custom-checkbox label.checked.hover,
#okta-sign-in .o-form-read-mode .custom-checkbox label.checked.hover {
	background-position: -16px -713px;
	cursor: text;
}
#okta-sign-in .o-form-read-mode .group-picker-wrap .as-selections {
	border-width: 0;
	box-shadow: none;
	background: transparent;
}
#okta-sign-in .two_col_left .o-form-title-bar,
#okta-sign-in .two_col_right .o-form-title-bar {
	font-size: 17px;
	padding-left: 20px;
	padding-right: 12px;
}
#okta-sign-in .two_col_left .o-form-read-mode .o-form-content,
#okta-sign-in .two_col_right .o-form-read-mode .o-form-content {
	padding: 30px 31px;
}
#okta-sign-in .two_col_left .o-form-read-mode .o-form-theme,
#okta-sign-in .two_col_right .o-form-read-mode .o-form-theme {
	background: none;
	border-width: 0;
	box-shadow: none;
}
#okta-sign-in .two_col_left .o-form-read-mode .o-form-explain,
#okta-sign-in .two_col_right .o-form-read-mode .o-form-explain {
	background: #f9f9f9;
	padding: 15px;
	margin: -30px -31px 20px;
	border: 1px solid #ddd;
}
#okta-sign-in .two_col_left .o-form-edit-mode .o-form-explain,
#okta-sign-in .two_col_right .o-form-edit-mode .o-form-explain {
	padding: 15px;
	margin: -30px -30px 20px;
	border: 0;
	border-bottom: 1px solid #ddd;
}
#okta-sign-in .o-form-disabled,
#okta-sign-in .o-form-saving .o-form-input > :not(.o-form-disabled) {
	opacity: 0.4;
	pointer-events: none;
	display: block;
}
#okta-sign-in .o-form-saving .o-form-content {
	overflow: hidden;
}
#okta-sign-in .o-form-saving .o-form-content * {
	cursor: default !important;
}
#okta-sign-in .o-form-disabled.custom-checkbox label,
#okta-sign-in .o-form-disabled .custom-checkbox label,
#okta-sign-in .o-form-disabled.custom-radio label,
#okta-sign-in .o-form-disabled .custom-radio label {
	background-position: -16px -13px;
}
#okta-sign-in .o-form-disabled.custom-checkbox label.checked,
#okta-sign-in .o-form-disabled .custom-checkbox label.checked,
#okta-sign-in .o-form-disabled.custom-checkbox label.checked.hover,
#okta-sign-in .o-form-disabled.custom-radio label.checked,
#okta-sign-in .o-form-disabled.custom-radio label.checked.hover {
	background-position: -16px -213px;
}
#okta-sign-in .o-form-disabled.custom-radio label.checked,
#okta-sign-in .o-form-disabled .custom-radio label.checked {
	background-position: -16px -713px;
}
#okta-sign-in .o-form-has-errors.chzn-container-single .chzn-single,
#okta-sign-in .o-form-has-errors.input-fix,
#okta-sign-in .o-form-has-errors.link-button,
#okta-sign-in .o-form-has-errors.textarea-fix {
	border-color: #d93934;
}
#okta-sign-in .o-form-has-errors .link-button:hover,
#okta-sign-in .o-form-has-errors .o-form-control.o-form-focus {
	border-color: #888;
}
#okta-sign-in .o-form-has-errors .custom-radio label {
	background-position: -16px -613px;
}
#okta-sign-in .o-form-has-errors .custom-checkbox label {
	background-position: -16px -813px;
}
#okta-sign-in .o-form-has-errors .custom-checkbox label.focus,
#okta-sign-in .o-form-has-errors .custom-checkbox label.hover,
#okta-sign-in .o-form-has-errors .custom-radio label.focus,
#okta-sign-in .o-form-has-errors .custom-radio label.hover {
	background-position: -16px -113px;
}
#okta-sign-in .o-form-has-errors .custom-checkbox label.checked {
	background-position: -16px -913px;
}
#okta-sign-in .o-form-has-errors .custom-radio label.checked {
	background-position: -16px -713px;
}
#okta-sign-in .o-form-has-errors .o-form-input-group .o-form-control .chzn-single {
	border-color: #bbb #bbb #b1b1b1;
}
#okta-sign-in
	.o-form-has-errors
	.o-form-input-group
	.chzn-container-active.chzn-with-drop
	.chzn-single {
	border-color: #888;
}
#okta-sign-in .o-form-has-errors .chzn-container-active .chzn-single {
	-webkit-box-shadow: 0 1px 2px hsla(0, 0%, 68.6%, 0.3);
	-moz-box-shadow: 0 1px 2px hsla(0, 0%, 68.6%, 0.3);
	box-shadow: 0 1px 2px hsla(0, 0%, 68.6%, 0.3);
	border: 1px solid #888;
}
#okta-sign-in .o-form-has-errors .infobox-error {
	display: block !important;
	margin-bottom: 15px;
}
#okta-sign-in .o-form .infobox-error + form .o-form-focus {
	border-color: #888 !important;
}
#okta-sign-in .data-list-inline-form .o-form-head {
	color: #444;
	font-size: 16px;
	margin: 0;
}
#okta-sign-in .data-list-inline-form .o-form-button-bar {
	border-width: 1px 0 0;
	margin: 5px -30px -20px;
}
#okta-sign-in .o-form-content > .o-form-explain {
	margin-bottom: 20px;
}
#okta-sign-in .infobox {
	word-break: break-word;
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
	position: relative;
	min-height: 18px;
	padding: 12px 18px 12px 48px !important;
}
#okta-sign-in .infobox:before {
	width: 36px;
}
#okta-sign-in .infobox.infobox-no-icon {
	padding-left: 24px !important;
}
#okta-sign-in .infobox.infobox-no-icon :before {
	display: none;
}
#okta-sign-in .infobox > .icon {
	position: absolute;
	top: 13px;
	left: 9px;
}
#okta-sign-in .infobox ul {
	margin-right: 0;
	margin-left: 27px;
}
#okta-sign-in .infobox + .infobox,
#okta-sign-in .infobox + h2 {
	margin-top: 18px;
}
#okta-sign-in .infobox .two_col_left {
	width: 80%;
}
#okta-sign-in .infobox .two_col_right {
	width: 19%;
	margin-left: 1%;
	padding-left: 0;
}
#okta-sign-in .infobox .two_col_right .button-primary {
	width: 100% !important;
	float: right;
	box-sizing: border-box;
}
#okta-sign-in .infobox[style='display:none;'] + .infobox,
#okta-sign-in .infobox[style='display: none;'] + .infobox {
	margin-top: 0;
}
#okta-sign-in .infobox h2,
#okta-sign-in .infobox h2 *,
#okta-sign-in .infobox h3,
#okta-sign-in .infobox h3 * {
	margin: 0;
	padding: 0;
	font-size: 13px;
	font-weight: 700;
	line-height: 18px;
}
#okta-sign-in .infobox h2 span,
#okta-sign-in .infobox h3 span {
	color: #5e5e5e;
}
#okta-sign-in .infobox h2 + *,
#okta-sign-in .infobox h3 + *,
#okta-sign-in .infobox li + *,
#okta-sign-in .infobox ol + *,
#okta-sign-in .infobox p + *,
#okta-sign-in .infobox ul + * {
	margin-top: 9px;
}
#okta-sign-in .infobox,
#okta-sign-in .infobox-md {
	border: 1px solid #ddd;
	border-radius: 3px;
	background-color: #fbfbfb;
}
#okta-sign-in .infobox-compact:before,
#okta-sign-in .infobox-md:before,
#okta-sign-in .infobox:before {
	position: absolute;
	top: -1px;
	bottom: -1px;
	left: -1px;
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
	content: '';
}
#okta-sign-in .infobox-md {
	min-height: 18px;
	padding: 24px 24px 24px 70px !important;
}
#okta-sign-in .infobox-md h2,
#okta-sign-in .infobox-md h3 {
	font-size: 15px;
}
#okta-sign-in .infobox-md h2 *,
#okta-sign-in .infobox-md h3 * {
	margin: 0;
	line-height: 18px;
}
#okta-sign-in .infobox-md:before {
	width: 48px;
}
#okta-sign-in .infobox-md > .icon {
	top: 20px;
	left: 11px;
}
#okta-sign-in .infobox-md + .button-bar.button-bar-attached {
	width: auto;
	padding-left: 60px;
	float: none;
}
#okta-sign-in .infobox-md + .button-bar.button-bar-attached.button-bar-l-height {
	line-height: 32px;
}
#okta-sign-in .infobox-compact {
	min-height: 16px;
	padding: 9px 9px 9px 40px !important;
	border-radius: 3px;
	border: 1px solid #ddd;
	background-color: #fbfbfb;
}
#okta-sign-in .infobox-compact li,
#okta-sign-in .infobox-compact p {
	line-height: 16px;
}
#okta-sign-in .infobox-compact h2 + *,
#okta-sign-in .infobox-compact h3 + *,
#okta-sign-in .infobox-compact li + *,
#okta-sign-in .infobox-compact ol + *,
#okta-sign-in .infobox-compact p + *,
#okta-sign-in .infobox-compact ul + * {
	margin-top: 5px;
}
#okta-sign-in .infobox-compact:before {
	width: 30px;
}
#okta-sign-in .infobox-compact + p {
	margin-top: 9px;
}
#okta-sign-in .infobox-compact > .icon {
	top: 9px;
	left: 6px;
}
#okta-sign-in .infobox-xl {
	min-height: 18px;
	padding: 26px 36px 26px 108px !important;
}
#okta-sign-in .infobox-xl:before {
	width: 84px;
}
#okta-sign-in .infobox-xl > .icon {
	top: 22px;
	left: 18px;
}
#okta-sign-in .infobox-xl p,
#okta-sign-in .infobox-xl ul {
	width: 75%;
	margin-top: 20px;
}
#okta-sign-in .infobox-xl li,
#okta-sign-in .infobox-xl p {
	font-size: 14px;
	line-height: 20px;
}
#okta-sign-in .infobox-xl h2,
#okta-sign-in .infobox-xl h3 {
	font-size: 18px;
}
#okta-sign-in .infobox-xl h2 *,
#okta-sign-in .infobox-xl h3 * {
	margin: 0;
	line-height: 18px;
}
#okta-sign-in .infobox-teach h2,
#okta-sign-in .infobox-teach h3 {
	color: #835ab1;
	font-weight: 200;
}
#okta-sign-in .infobox-teach.infobox-dismiss .button-teach {
	margin-top: 20px;
	float: right;
}
#okta-sign-in .infobox-dismiss {
	padding-right: 30px;
}
#okta-sign-in .infobox-dismiss-link {
	display: block;
	position: absolute;
	top: 10px;
	right: 8px;
	color: #5e5e5e;
	line-height: 14px;
	cursor: pointer;
}
#okta-sign-in .infobox-dismiss-link:hover {
	text-decoration: none;
}
#okta-sign-in .help-wrap .infobox.infobox-dismiss .infobox-dismiss-link {
	top: 0;
	right: 0;
}
#okta-sign-in .infobox-dismiss-hide {
	display: none !important;
}
#okta-sign-in .infobox-confirm {
	position: absolute !important;
	top: 20px;
	left: 50%;
	width: 240px;
	margin-left: -154px;
	box-shadow: 0 2px 0 hsla(0, 0%, 62.7%, 0.1);
	z-index: 10;
}
#okta-sign-in .infobox-confirm-fixed {
	position: fixed !important;
	top: 106px;
}
#okta-sign-in .infobox-confirm-fixed .infobox-dismiss-link {
	top: 14px;
	right: 14px;
}
#okta-sign-in .infobox:before {
	background-color: #007dc1;
}
#okta-sign-in .help-wrap .infobox.infobox-success:before,
#okta-sign-in .infobox-success:before,
#okta-sign-in .infobox.infobox-subtle.infobox-success:before {
	background-color: #4cbf9c;
}
#okta-sign-in .help-wrap .infobox.infobox-warning:before,
#okta-sign-in .infobox-warning:before,
#okta-sign-in .infobox.infobox-subtle.infobox-warning:before {
	background-color: #f1d10d;
}
#okta-sign-in .help-wrap .infobox.infobox-error:before,
#okta-sign-in .infobox-error:before,
#okta-sign-in .infobox.infobox-subtle.infobox-error:before {
	background-color: #e34843;
}
#okta-sign-in .help-wrap .infobox.infobox-teach:before,
#okta-sign-in .help-wrap .infobox.infobox-tip:before,
#okta-sign-in .infobox-teach:before,
#okta-sign-in .infobox-tip:before,
#okta-sign-in .infobox.infobox-subtle.infobox-teach:before,
#okta-sign-in .infobox.infobox-subtle.infobox-tip:before {
	background-color: #835ab1;
}
#okta-sign-in .infobox-warning .icon.processing-16 {
	width: 16px;
	height: 16px;
	background: url(../img/ui/indicators/ajax-loader-processing-16.gif) no-repeat 0 0 transparent;
}
#okta-sign-in .infobox-warning .icon.processing-24 {
	width: 24px;
	height: 24px;
	background: url(../img/ui/indicators/ajax-loader-processing-24.gif) no-repeat 0 0 transparent;
}
#okta-sign-in .infobox-error .icon.processing-16 {
	width: 16px;
	height: 16px;
	background: url(../img/icons/16x16/error-01.png) no-repeat 0 0 transparent;
}
#okta-sign-in .infobox + .button-bar,
#okta-sign-in .infobox + .button-bar-small {
	border-top: 0;
}
#okta-sign-in .infobox-no-border + .button-bar,
#okta-sign-in .infobox-no-border + .button-bar-small {
	border-top: 1px solid #dadada;
}
#okta-sign-in .infobox-no-border {
	border: none !important;
}
#okta-sign-in .infobox-text {
	padding: 0;
}
#okta-sign-in .infobox-header {
	font-weight: 700;
}
#okta-sign-in .infobox-divider {
	border-bottom: 1px dotted #ddd;
}
#okta-sign-in .ajax-form-editor .infobox,
#okta-sign-in .edit-form .infobox,
#okta-sign-in .form-content-wrap .infobox,
#okta-sign-in .m-form .infobox,
#okta-sign-in .o-form .infobox,
#okta-sign-in .read-only-form .infobox,
#okta-sign-in .v-form .infobox {
	background-color: #fff;
}
#okta-sign-in .help-wrap .infobox,
#okta-sign-in .infobox.infobox-subtle {
	padding: 0 0 0 18px !important;
	border: 0;
	background: transparent;
}
#okta-sign-in .help-wrap .infobox:before,
#okta-sign-in .infobox.infobox-subtle:before {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 6px;
	border-radius: 0;
	background-color: #007dc1;
	content: '';
}
#okta-sign-in .help-wrap .infobox .icon,
#okta-sign-in .infobox.infobox-subtle .icon {
	display: none;
}
#okta-sign-in a {
	color: #777;
}
#okta-sign-in a:active,
#okta-sign-in a:link,
#okta-sign-in a:visited {
	text-decoration: none;
	color: #0071bc;
}
#okta-sign-in a:focus,
#okta-sign-in a:hover {
	text-decoration: underline;
	color: #0071bc;
}
#okta-sign-in h1 {
	color: #5e5e5e;
}
#okta-sign-in h2,
#okta-sign-in h3 {
	font-size: 13px;
	line-height: 1.5;
	font-weight: 700;
}
#okta-sign-in h3 {
	margin-bottom: 2px;
}
#okta-sign-in .text-align-c {
	text-align: center;
}
#okta-sign-in .auth-account {
	line-height: 18px;
	padding: 6px 0;
}
#okta-sign-in .auth-content {
	padding: 60px 42px 20px;
	-moz-transition: padding-top 0.4s;
	-webkit-transition: padding-top 0.4s;
	transition: padding-top 0.4s;
}
@media only screen and (max-device-width: 480px) {
	#okta-sign-in .auth-content {
		padding-left: 20px;
		padding-right: 20px;
	}
}
@media only screen and (max-width: 350px) {
	#okta-sign-in .auth-content {
		padding-left: 20px;
		padding-right: 20px;
	}
}
#okta-sign-in .auth-content .auth-content-inner {
	position: relative;
}
#okta-sign-in .auth-content .help-links li {
	margin-top: 15px;
}
#okta-sign-in .enroll-choices,
#okta-sign-in .no-beacon .auth-content {
	padding-top: 20px;
}
#okta-sign-in .auth-idp {
	background-color: #f9f9f9;
	border-radius: 0 0 3px 3px;
	padding: 20px 30px;
	border-top: 1px solid #ddd;
}
#okta-sign-in .auth-idp-label {
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 200;
	line-height: 30px;
	color: #888;
	float: left;
	margin-right: 155px;
}
#okta-sign-in .auth-divider {
	background-color: #a7a7a7;
	height: 1px;
	margin-bottom: 25px;
	text-align: center;
}
#okta-sign-in .auth-divider .auth-divider-text {
	background-color: #fff;
	position: relative;
	top: -0.5em;
	padding: 0 15px;
}
#okta-sign-in .animation-container-overflow {
	overflow: hidden;
}
#okta-sign-in .transition-from-right {
	position: absolute;
	width: 316px;
	top: 0;
	left: 200px;
	opacity: 0;
}
#okta-sign-in .transition-from-left {
	position: absolute;
	width: 316px;
	top: 0;
	left: -200px;
	opacity: 0;
}
#okta-sign-in .o-form-head {
	font-size: 14px;
	line-height: 1.5;
	margin-top: 10px;
	text-align: center;
	text-transform: none;
}
#okta-sign-in .o-form-head + .o-form-explain {
	margin-bottom: 20px;
	text-align: center;
}
#okta-sign-in .o-form-button-bar {
	background: transparent;
	border: none;
	padding: 0 0 25px;
	border-radius: 0;
	box-shadow: none;
}
#okta-sign-in .o-form-button-bar .button {
	display: block;
	width: 100%;
	height: 50px;
}
#okta-sign-in .o-form-button-bar .button-success {
	position: absolute;
	text-align: center;
}
#okta-sign-in .o-form-theme {
	background: transparent;
	box-shadow: none;
	padding: 0 0 14px;
	border-radius: 0;
	border: none;
}
#okta-sign-in .o-form .o-form-fieldset:last-child {
	margin-bottom: 15px;
}
#okta-sign-in .o-form .o-form-fieldset.margin-btm-0 {
	margin-bottom: 0;
}
#okta-sign-in .o-form .o-form-label,
#okta-sign-in .o-form input,
#okta-sign-in .o-form label,
#okta-sign-in .o-form textarea {
	font-size: 13px;
}
#okta-sign-in .o-form .o-form-label {
	font-weight: 400;
}
#okta-sign-in .o-form .o-form-label-inline + .input-fix input[type='text'] {
	line-height: 27px;
}
#okta-sign-in .o-form .input-fix,
#okta-sign-in .o-form .textarea-fix {
	height: 40px;
	border: 1px solid #bbb;
	display: inline-block;
	float: left;
}
#okta-sign-in .o-form .input-fix input[disabled],
#okta-sign-in .o-form .input-fix input[readonly],
#okta-sign-in .o-form .textarea-fix input[disabled],
#okta-sign-in .o-form .textarea-fix input[readonly] {
	background: #f4f4f4;
	color: #777;
	border-radius: 3px;
	box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.1);
}
#okta-sign-in .o-form .input-fix input,
#okta-sign-in .o-form .textarea-fix input {
	padding: 8px;
	line-height: 22px;
	font-size: 13px;
	border: none;
	background: none;
	box-shadow: none;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
	#okta-sign-in .o-form .input-fix input,
	#okta-sign-in .o-form .textarea-fix input {
		font-size: 14px;
	}
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
	#okta-sign-in .o-form .input-fix input,
	#okta-sign-in .o-form .textarea-fix input {
		font-size: 14px;
	}
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
	#okta-sign-in .o-form .input-fix input,
	#okta-sign-in .o-form .textarea-fix input {
		font-size: 14px;
	}
}
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
	#okta-sign-in .o-form .input-fix input,
	#okta-sign-in .o-form .textarea-fix input {
		font-size: 14px;
	}
}
@media screen and (device-width: 320px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 2) {
	#okta-sign-in .o-form .input-fix input,
	#okta-sign-in .o-form .textarea-fix input {
		font-size: 14px;
	}
}
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) {
	#okta-sign-in .o-form .input-fix input,
	#okta-sign-in .o-form .textarea-fix input {
		font-size: 14px;
	}
}
#okta-sign-in .o-form .input-fix input::-webkit-input-placeholder,
#okta-sign-in .o-form .textarea-fix input::-webkit-input-placeholder {
	font-size: 13px;
	letter-spacing: 0;
}
#okta-sign-in .o-form .input-fix input::-moz-placeholder,
#okta-sign-in .o-form .textarea-fix input::-moz-placeholder {
	font-size: 13px;
}
#okta-sign-in .o-form .input-fix input:-ms-input-placeholder,
#okta-sign-in .o-form .textarea-fix input:-ms-input-placeholder {
	font-size: 13px;
	line-height: 27px;
}
#okta-sign-in .o-form .input-fix input:-webkit-autofill,
#okta-sign-in .o-form .textarea-fix input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 1000px #fff inset;
	border-radius: 3px;
}
#okta-sign-in .o-form .input-fix input[type='password'],
#okta-sign-in .o-form .textarea-fix input[type='password'] {
	font-size: 13px;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
	#okta-sign-in .o-form .input-fix input[type='password'],
	#okta-sign-in .o-form .textarea-fix input[type='password'] {
		font-size: 14px;
	}
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
	#okta-sign-in .o-form .input-fix input[type='password'],
	#okta-sign-in .o-form .textarea-fix input[type='password'] {
		font-size: 14px;
	}
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
	#okta-sign-in .o-form .input-fix input[type='password'],
	#okta-sign-in .o-form .textarea-fix input[type='password'] {
		font-size: 14px;
	}
}
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
	#okta-sign-in .o-form .input-fix input[type='password'],
	#okta-sign-in .o-form .textarea-fix input[type='password'] {
		font-size: 14px;
	}
}
@media screen and (device-width: 320px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 2) {
	#okta-sign-in .o-form .input-fix input[type='password'],
	#okta-sign-in .o-form .textarea-fix input[type='password'] {
		font-size: 14px;
	}
}
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) {
	#okta-sign-in .o-form .input-fix input[type='password'],
	#okta-sign-in .o-form .textarea-fix input[type='password'] {
		font-size: 14px;
	}
}
#okta-sign-in .o-form .input-fix .placeholder,
#okta-sign-in .o-form .textarea-fix .placeholder {
	color: #aaa;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
	#okta-sign-in .o-form .chzn-container-single .chzn-search input,
	#okta-sign-in .o-form select,
	#okta-sign-in .o-form select:focus {
		font-size: 14px;
	}
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
	#okta-sign-in .o-form .chzn-container-single .chzn-search input,
	#okta-sign-in .o-form select,
	#okta-sign-in .o-form select:focus {
		font-size: 14px;
	}
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
	#okta-sign-in .o-form .chzn-container-single .chzn-search input,
	#okta-sign-in .o-form select,
	#okta-sign-in .o-form select:focus {
		font-size: 14px;
	}
}
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
	#okta-sign-in .o-form .chzn-container-single .chzn-search input,
	#okta-sign-in .o-form select,
	#okta-sign-in .o-form select:focus {
		font-size: 14px;
	}
}
@media screen and (device-width: 320px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 2) {
	#okta-sign-in .o-form .chzn-container-single .chzn-search input,
	#okta-sign-in .o-form select,
	#okta-sign-in .o-form select:focus {
		font-size: 14px;
	}
}
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) {
	#okta-sign-in .o-form .chzn-container-single .chzn-search input,
	#okta-sign-in .o-form select,
	#okta-sign-in .o-form select:focus {
		font-size: 14px;
	}
}
#okta-sign-in .o-form .custom-checkbox label {
	font-size: 12px;
}
#okta-sign-in .o-form .o-form-input input[name='nextPassCode'],
#okta-sign-in .o-form .o-form-input input[name='passCode'] {
	font-family: Anonymous Pro, Andale Mono, Courier New, Courier, serif;
	font-size: 17px;
	letter-spacing: 1px;
}
#okta-sign-in .o-form .o-form-input .o-form-control {
	position: relative;
}
#okta-sign-in .o-form .o-form-input .input-icon,
#okta-sign-in .o-form .o-form-input .input-tooltip {
	width: 16px;
	height: 16px;
	position: absolute;
	top: 50%;
	margin-top: -11px;
}
#okta-sign-in .o-form .o-form-input .input-icon + input,
#okta-sign-in .o-form .o-form-input .input-icon + input + input,
#okta-sign-in .o-form .o-form-input .input-tooltip + input,
#okta-sign-in .o-form .o-form-input .input-tooltip + input + input {
	padding-right: 30px;
}
#okta-sign-in .o-form .o-form-input .input-icon {
	left: 10px;
	opacity: 0.25;
}
#okta-sign-in .o-form .o-form-input .input-icon + input,
#okta-sign-in .o-form .o-form-input .input-icon + input + input {
	padding-left: 30px;
}
#okta-sign-in .o-form .o-form-input .input-tooltip {
	right: 10px;
	opacity: 0.5;
}
#okta-sign-in .o-form-input-group .o-form-control {
	display: table-cell;
	float: none;
	white-space: nowrap;
}
#okta-sign-in .form-divider {
	border-bottom: 1px solid #e8e8e8;
	margin-bottom: 25px;
	padding-top: 5px;
	clear: both;
}
#okta-sign-in .chzn-container-single .chzn-single {
	border: 1px solid #bbb;
	border-radius: 3px;
	height: 38px;
	line-height: 38px;
}
#okta-sign-in .chzn-container-single .chzn-single div b {
	background-position: 0 6px;
}
#okta-sign-in .chzn-container-active.chzn-with-drop .chzn-single div b {
	background-position: -17px 7px;
}
#okta-sign-in .custom-checkbox label {
	background-image: url(../img/ui/forms/checkbox-sign-in-widget.png);
}
#okta-sign-in .custom-checkbox label.focus {
	background-position: -16px -1013px;
}
#okta-sign-in .custom-checkbox label.focus:before {
	background-position: -16px -1020px;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2),
	only screen and (min-device-pixel-ratio: 2),
	only screen and (min-resolution: 2dppx) {
	#okta-sign-in .custom-checkbox label {
		background-image: url(../img/ui/forms/checkbox-sign-in-widget@2x.png);
		background-size: 50px 1155px;
	}
}
#okta-sign-in .infobox.icon-16 > .icon {
	position: absolute;
}
#okta-sign-in .contact-support .infobox,
#okta-sign-in .infobox.icon-16 {
	margin-bottom: 15px;
}
#okta-sign-in .infobox > .icon {
	line-height: 16px;
}
#okta-sign-in .qtip {
	font-size: 13px;
}
#okta-sign-in .qtip-custom .qtip-titlebar {
	background-color: transparent;
	border: 0;
	font-weight: 400;
}
#okta-sign-in .qtip-title {
	word-break: break-all;
}
#okta-sign-in .security-image-qtip.qtip-custom {
	font-size: 12px;
	line-height: 1.4;
	padding: 8px 8px 5px;
}
#okta-sign-in .security-image-qtip.qtip-custom canvas {
	margin-top: 2px;
}
#okta-sign-in .security-image-qtip.qtip-custom .qtip-icon {
	background: transparent url(../img/icons/login/tooltip_close.png) no-repeat 50%;
	border: none;
	color: transparent;
	right: 2px;
	top: 4px;
	text-decoration: none;
}
#okta-sign-in .security-image-qtip.qtip-custom .qtip-icon:hover {
	opacity: 0.5;
}
#okta-sign-in .qtip-custom {
	background-color: #4d4d4d;
	border-color: #303030;
	color: #f3f3f3;
	padding: 5px 8px 8px;
}
#okta-sign-in .qtip-custom.qtip-pos-bl {
	border-radius: 4px 4px 4px 0;
}
#okta-sign-in .qtip-custom.qtip-pos-br {
	border-radius: 4px 4px 0;
}
#okta-sign-in .qtip-custom .qtip-content {
	color: #f3f3f3;
}
#okta-sign-in .qtip-custom .qtip-titlebar {
	background-color: #404040;
}
#okta-sign-in .qtip-custom .qtip-icon {
	border-color: #444;
}
#okta-sign-in .qtip-custom .qtip-titlebar .ui-state-hover {
	border-color: #303030;
}
#okta-sign-in .qtip-custom .qtip-content ul {
	list-style: disc;
}
#okta-sign-in .qtip-custom .qtip-content ul > li {
	color: #69882a;
	font-size: 20px;
}
#okta-sign-in .qtip-custom .qtip-content ul > li span {
	color: #f3f3f3;
	font-size: 12px;
	vertical-align: middle;
}
#okta-sign-in .mfa-verify-totp-inline .inline-input {
	width: 212px;
	float: left;
}
@media only screen and (max-device-width: 480px) {
	#okta-sign-in .mfa-verify-totp-inline .inline-input {
		width: 180px;
	}
}
@media only screen and (max-width: 400px) {
	#okta-sign-in .mfa-verify-totp-inline .inline-input {
		width: 100%;
	}
}
@media only screen and (max-width: 400px) {
	#okta-sign-in .mfa-verify-totp-inline .inline-totp-verify {
		width: 100%;
		margin-bottom: 15px;
	}
}
#okta-sign-in .mfa-verify-totp-inline a.button {
	float: right;
	padding-left: 25px;
	padding-right: 25px;
}
#okta-sign-in .mfa-verify-totp-inline a.link-button {
	display: block;
	text-align: center;
}
#okta-sign-in .mfa-verify-passcode .link-button {
	float: left;
}
#okta-sign-in .mfa-verify-passcode .auth-passcode .o-form-input {
	width: 180px;
	float: right;
}
@media only screen and (max-device-width: 480px) {
	#okta-sign-in .mfa-verify-passcode .auth-passcode .o-form-input {
		width: 170px;
	}
}
@media only screen and (max-width: 400px) {
	#okta-sign-in .mfa-verify-passcode .auth-passcode .o-form-input {
		width: 100%;
	}
}
#okta-sign-in .duo-form iframe {
	height: 400px;
	width: 100%;
}
#okta-sign-in .forgot-password-call-enabled .mobile-recovery-hint,
#okta-sign-in .forgot-password-call-enabled .sms-hint,
#okta-sign-in .forgot-password-sms-enabled .mobile-recovery-hint,
#okta-sign-in .forgot-password-sms-enabled .sms-hint {
	font-size: 12px;
	padding-bottom: 10px;
	position: relative;
	top: -5px;
}
#okta-sign-in .forgot-password-call-enabled .o-form-fieldset-container,
#okta-sign-in .forgot-password-sms-enabled .o-form-fieldset-container {
	overflow: hidden;
}
#okta-sign-in .forgot-password-call-enabled .o-form-fieldset-container .button,
#okta-sign-in .forgot-password-sms-enabled .o-form-fieldset-container .button {
	width: 100%;
	float: none;
	margin-left: 0;
	margin-bottom: 15px;
}
#okta-sign-in .forgot-password-call-enabled .o-form-fieldset-container .button + .button-primary,
#okta-sign-in .forgot-password-sms-enabled .o-form-fieldset-container .button + .button-primary {
	margin-left: 0;
}
@media only screen and (max-width: 400px) {
	#okta-sign-in .recovery-challenge .enroll-sms-phone-send {
		width: 100%;
		margin-bottom: 15px;
	}
}
#okta-sign-in .recovery-challenge .o-form-input {
	width: 178px;
}
@media only screen and (max-device-width: 480px) {
	#okta-sign-in .recovery-challenge .o-form-input {
		width: 150px;
	}
}
@media only screen and (max-width: 400px) {
	#okta-sign-in .recovery-challenge .o-form-input {
		width: 100%;
	}
}
#okta-sign-in .recovery-challenge .o-form-button-bar {
	padding-bottom: 15px;
}
#okta-sign-in .recovery-challenge a.button {
	float: right;
}
#okta-sign-in .recovery-challenge .send-email-link {
	display: inline-block;
	font-size: 12px;
	padding-bottom: 25px;
	text-align: center;
	width: 100%;
}
#okta-sign-in .button,
#okta-sign-in .button.link-button {
	display: inline-block;
	height: 40px;
	line-height: 36px;
	padding: 0 15px;
	border: 1px solid;
	border-radius: 3px;
	font-size: 13px;
	box-sizing: border-box;
	-webkit-appearance: none;
}
#okta-sign-in .button-primary.default-custom-button.link-button,
#okta-sign-in .button-primary.social-auth-button.link-button,
#okta-sign-in .button-wide,
#okta-sign-in .button-wide.button-primary,
#okta-sign-in .default-custom-button.link-button,
#okta-sign-in .social-auth-button.link-button {
	width: 100%;
	height: 50px;
	line-height: 48px;
	text-align: center;
}
#okta-sign-in .icon-button {
	display: block;
	width: 22px;
	height: 22px;
	text-align: center;
	border: 1px solid;
	border-radius: 3px;
}
#okta-sign-in .icon-button .icon {
	display: inline-block;
	position: relative;
	top: 4px;
	opacity: 0.25;
}
#okta-sign-in .icon-button:hover .icon {
	opacity: 0.5;
}
#okta-sign-in .call-request-button,
#okta-sign-in .email-request-button,
#okta-sign-in .sms-request-button {
	text-align: center;
	width: 130px;
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
@media only screen and (max-width: 400px) {
	#okta-sign-in .call-request-button,
	#okta-sign-in .email-request-button,
	#okta-sign-in .sms-request-button {
		width: 100%;
		margin-bottom: 15px;
	}
}
#okta-sign-in .social-auth-button.link-button {
	border-radius: 3px;
	box-sizing: border-box;
	color: #fff;
	display: block;
	font-size: 13px;
	line-height: 50px;
	margin-top: 15px;
	padding-left: 90px;
	text-align: left;
}
#okta-sign-in .social-auth-button.link-button:last-of-type {
	margin-bottom: 25px;
}
#okta-sign-in .social-auth-button.link-button:active,
#okta-sign-in .social-auth-button.link-button:focus,
#okta-sign-in .social-auth-button.link-button:hover,
#okta-sign-in .social-auth-button.link-button:visited {
	text-decoration: none;
}
#okta-sign-in .default-custom-button.link-button {
	border-radius: 3px;
	box-sizing: border-box;
	color: #fff;
	display: block;
	font-size: 13px;
	line-height: 50px;
	margin-top: 15px;
	text-align: center;
}
#okta-sign-in .default-custom-button.link-button:last-of-type {
	margin-bottom: 25px;
}
#okta-sign-in .default-custom-button.link-button:active,
#okta-sign-in .default-custom-button.link-button:focus,
#okta-sign-in .default-custom-button.link-button:hover,
#okta-sign-in .default-custom-button.link-button:visited {
	text-decoration: none;
}
#okta-sign-in .social-auth-facebook-button {
	background-color: #406094;
	background: url(../img/icons/login/facebook_logo.png) no-repeat 10px,
		-webkit-linear-gradient(left, #304879 50px, #406094 0);
	background: url(../img/icons/login/facebook_logo.png) no-repeat 10px,
		linear-gradient(90deg, #304879 50px, #406094 0);
	border: 1px solid #375685;
}
#okta-sign-in .social-auth-facebook-button:active,
#okta-sign-in .social-auth-facebook-button:focus,
#okta-sign-in .social-auth-facebook-button:hover {
	background: url(../img/icons/login/facebook_logo.png) no-repeat 10px,
		-webkit-linear-gradient(left, #344f84 50px, #45679f 0);
	background: url(../img/icons/login/facebook_logo.png) no-repeat 10px,
		linear-gradient(90deg, #344f84 50px, #45679f 0);
}
#okta-sign-in .social-auth-google-button {
	background-color: #de4937;
	background: url(../img/icons/login/google_logo.png) no-repeat 10px,
		-webkit-linear-gradient(left, #d43722 50px, #de4937 0);
	background: url(../img/icons/login/google_logo.png) no-repeat 10px,
		linear-gradient(90deg, #d43722 50px, #de4937 0);
	border: 1px solid #b63c33;
}
#okta-sign-in .social-auth-google-button:active,
#okta-sign-in .social-auth-google-button:focus,
#okta-sign-in .social-auth-google-button:hover {
	background: url(../img/icons/login/google_logo.png) no-repeat 10px,
		-webkit-linear-gradient(left, #dd3e29 50px, #e05544 0);
	background: url(../img/icons/login/google_logo.png) no-repeat 10px,
		linear-gradient(90deg, #dd3e29 50px, #e05544 0);
}
#okta-sign-in .social-auth-linkedin-button {
	background-color: #0077b5;
	background: url(../img/icons/login/linkedin_logo.png) no-repeat 10px,
		-webkit-linear-gradient(left, #04659a 50px, #0077b5 0);
	background: url(../img/icons/login/linkedin_logo.png) no-repeat 10px,
		linear-gradient(90deg, #04659a 50px, #0077b5 0);
	border: 1px solid #0f608f;
}
#okta-sign-in .social-auth-linkedin-button:active,
#okta-sign-in .social-auth-linkedin-button:focus,
#okta-sign-in .social-auth-linkedin-button:hover {
	background: url(../img/icons/login/linkedin_logo.png) no-repeat 10px,
		-webkit-linear-gradient(left, #046fa9 50px, #0081c4 0);
	background: url(../img/icons/login/linkedin_logo.png) no-repeat 10px,
		linear-gradient(90deg, #046fa9 50px, #0081c4 0);
}
#okta-sign-in .social-auth-microsoft-button {
	background-color: #00a1f1;
	background: url(../img/icons/login/microsoft_logo.png) no-repeat 10px,
		-webkit-linear-gradient(left, #4294de 50px, #00a1f1 0);
	background: url(../img/icons/login/microsoft_logo.png) no-repeat 10px,
		linear-gradient(90deg, #4294de 50px, #00a1f1 0);
	border: 1px solid #447199;
}
#okta-sign-in .social-auth-microsoft-button:active,
#okta-sign-in .social-auth-microsoft-button:focus,
#okta-sign-in .social-auth-microsoft-button:hover {
	background: url(../img/icons/login/microsoft_logo.png) no-repeat 10px,
		-webkit-linear-gradient(left, #4f9be0 50px, #01abff 0);
	background: url(../img/icons/login/microsoft_logo.png) no-repeat 10px,
		linear-gradient(90deg, #4f9be0 50px, #01abff 0);
}
#okta-sign-in .default-custom-button {
	background-color: #ff5f73;
	border: 1px solid #d6001a;
}
#okta-sign-in .default-custom-button:active,
#okta-sign-in .default-custom-button:focus,
#okta-sign-in .default-custom-button:hover {
	background-color: #ff6e80;
}
#okta-sign-in .consent-beacon .logo-wrapper {
	width: 71px;
	height: 71px;
	position: absolute;
	border-radius: 50%;
	top: 7px;
	background-color: #fff;
}
#okta-sign-in .consent-beacon .consent-beacon-border {
	position: absolute;
	border: 1px solid #ddd;
	border-radius: 3px;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
}
#okta-sign-in .consent-beacon .consent-beacon-user {
	left: -45px;
}
#okta-sign-in .consent-beacon .consent-beacon-user .user-logo:before {
	color: #737373;
	font-size: 69px;
	margin: -10px 0 0;
}
#okta-sign-in .consent-beacon .consent-beacon-client {
	left: 59px;
}
#okta-sign-in .consent-beacon .consent-beacon-client .client-logo {
	border-radius: 3px;
}
#okta-sign-in .consent-beacon .consent-beacon-client .custom-logo {
	width: 70px;
	height: 70px;
}
#okta-sign-in .consent-beacon .consent-beacon-client .default-logo {
	width: 50px;
	height: 50px;
	padding: 10px;
}
#okta-sign-in .consent-beacon .arrows-wrapper {
	background: #fff;
	position: absolute;
	top: 10px;
	left: 26px;
	height: 65px;
	width: 34px;
}
#okta-sign-in .consent-beacon .arrow-left {
	position: absolute;
	top: 24px;
	left: 5px;
}
#okta-sign-in .consent-beacon .arrow-right {
	position: absolute;
	top: 10px;
	left: 5px;
}
#okta-sign-in .consent-beacon .arrow:before {
	color: #a7a7a7;
	font-size: 24px;
}
#okta-sign-in .consent-required .consent-title {
	padding-top: 20px;
	padding-bottom: 25px;
	text-align: center;
	word-wrap: break-word;
}
#okta-sign-in .consent-required .consent-title p {
	font-size: 16px;
}
#okta-sign-in .consent-required .consent-title b {
	font-weight: 700;
}
#okta-sign-in .consent-required .scope-item {
	display: inline-block;
	width: 100%;
	padding-bottom: 5px;
}
#okta-sign-in .consent-required .scope-item .scope-item-text {
	float: left;
	font-size: 14px;
	width: 85%;
	word-wrap: break-word;
}
#okta-sign-in .consent-required .scope-item .scope-item-tooltip {
	float: right;
	margin-right: 16px;
}
#okta-sign-in .consent-required .consent-description {
	padding-top: 15px;
	padding-bottom: 25px;
	font-size: 13px;
}
#okta-sign-in .consent-required .consent-description p {
	color: #a7a7a7;
	padding-bottom: 25px;
}
#okta-sign-in .consent-required .consent-description a {
	color: #007dc1;
}
#okta-sign-in .consent-required .o-form-button-bar {
	display: inline-block;
	width: 100%;
	padding: 0 0 14px;
}
#okta-sign-in .consent-required .o-form-button-bar input {
	width: 140px;
}
#okta-sign-in .consent-required .o-form-button-bar input[data-type='cancel'] {
	float: left;
	margin-left: 0;
}
#okta-sign-in .consent-required .o-form-button-bar input[data-type='save'] {
	float: right;
}
#okta-sign-in .auth-header {
	padding: 30px 90px 75px;
	position: relative;
	border-bottom: 1px solid #ddd;
	z-index: 10;
	-moz-transition: padding-bottom 0.4s;
	-webkit-transition: padding-bottom 0.4s;
	transition: padding-bottom 0.4s;
}
#okta-sign-in .auth-org-logo {
	display: block;
	margin: 0 auto;
	max-width: 200px;
	max-height: 40px;
}
#okta-sign-in .beacon-container {
	position: absolute;
	bottom: -42px;
	right: 0;
	left: 0;
	margin: auto;
	width: 85px;
	height: 85px;
	-webkit-transform: scale(0);
	transform: scale(0);
}
#okta-sign-in .beacon-container.beacon-small {
	height: 20px;
	width: 20px;
	bottom: -10px;
}
#okta-sign-in .beacon-container.beacon-small .beacon-blank {
	width: 50px;
	left: -20px;
}
#okta-sign-in .beacon-blank {
	background-image: none;
	background-color: transparent;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}
#okta-sign-in .radial-progress-bar {
	position: absolute;
	clip: rect(0, 96px, 96px, 48px);
	bottom: -5px;
	left: -5px;
	right: -5px;
	top: -5px;
}
#okta-sign-in .radial-progress-bar .circle {
	z-index: 10;
	width: 91px;
	height: 91px;
	border: 2px solid #a7a7a7;
	border-radius: 50%;
	position: absolute;
	clip: rect(0, 48px, 96px, 0);
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transform-origin: center;
	transform-origin: center;
}
#okta-sign-in .auth-beacon {
	position: absolute;
	border-radius: 50%;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #fff;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: cover;
	background-clip: content-box;
	color: #fff;
	box-shadow: 0 0 0 15px;
}
#okta-sign-in .undefined-user {
	background-image: url(../img/security/default.png);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in .undefined-user {
		background-image: url(../img/security/default@2x.png);
	}
}
#okta-sign-in .new-user {
	background-image: url(../img/security/unknown-device.png);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in .new-user {
		background-image: url(../img/security/unknown-device@2x.png);
	}
}
#okta-sign-in .auth-beacon-border {
	position: absolute;
	border: 2px solid #a7a7a7;
	border-radius: 50%;
	bottom: -5px;
	left: -5px;
	right: -5px;
	top: -5px;
}
#okta-sign-in .beacon-loading:after {
	content: '';
	display: block;
	position: absolute;
	top: -5px;
	bottom: -5px;
	left: -5px;
	right: -5px;
	border-radius: 50%;
	border: 2px solid transparent;
	border-top-color: #007dc1;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load8 1.1s linear infinite;
	animation: load8 1.1s linear infinite;
}
@-webkit-keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(1turn);
		transform: rotate(1turn);
	}
}
@keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(1turn);
		transform: rotate(1turn);
	}
}
#okta-sign-in .auth-beacon-factor {
	background-size: 100% auto;
	cursor: pointer;
}
#okta-sign-in .factors-dropdown-wrap {
	position: absolute;
	top: 50%;
	right: -32px;
	margin-top: -11px;
}
#okta-sign-in .factors-dropdown-wrap .dropdown.more-actions .bg-helper {
	color: #fff;
	box-shadow: 10px 0 0 0;
}
#okta-sign-in .factors-dropdown-wrap .dropdown.more-actions .link-button {
	height: 22px;
	padding: 0 !important;
}
#okta-sign-in .factors-dropdown-wrap .dropdown.more-actions span.icon-dm {
	top: 9px;
	background: url(../img/ui/indicators/drop-menu-s.png) no-repeat scroll 0 0 transparent;
}
#okta-sign-in .factors-dropdown-wrap .dropdown.more-actions .options-wrap {
	border: 1px solid #ccc;
	border: 1px solid rgba(0, 0, 0, 0.2);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
	background-color: #fff;
	background-clip: padding-box;
	overflow: auto;
	max-height: 306px;
	width: 320px;
	left: -212px;
	top: 38px;
	border-radius: 3px;
}
@media only screen and (max-width: 325px) {
	#okta-sign-in .factors-dropdown-wrap .dropdown.more-actions .options-wrap {
		width: 280px;
		left: -192px;
	}
}
#okta-sign-in .factors-dropdown-wrap .dropdown.more-actions .options-wrap:after {
	display: none;
}
#okta-sign-in .factors-dropdown-wrap .dropdown.more-actions .option a {
	line-height: 30px;
	padding-left: 50px;
}
#okta-sign-in .factors-dropdown-wrap .dropdown.more-actions .option a .icon {
	width: 30px;
	height: 30px;
}
#okta-sign-in .factors-dropdown-wrap .dropdown.more-actions .dropdown-list-title {
	text-align: center;
}
#okta-sign-in .factors-dropdown-wrap .dropdown.more-actions .dropdown-list-title:hover {
	background: transparent;
}
#okta-sign-in .factors-dropdown-wrap .dropdown.more-actions .dropdown-list-title a {
	padding-left: 5px;
	cursor: default;
}
#okta-sign-in .factors-dropdown-wrap .dropdown.more-actions .suboption.option {
	border-bottom: none;
	padding-left: 50px;
}
#okta-sign-in .factors-dropdown-wrap .dropdown.more-actions .suboption.option a {
	padding-left: 36px;
}
#okta-sign-in
	.factors-dropdown-wrap
	.dropdown.more-actions
	.suboption.option
	+ .option:not(.suboption),
#okta-sign-in
	.factors-dropdown-wrap
	.dropdown.more-actions
	.suboption.option
	+ .suboption.option
	a {
	border-top: 1px solid #eaeaea;
}
#okta-sign-in .mfa-call-30,
#okta-sign-in .mfa-custom-factor-30,
#okta-sign-in .mfa-duo-30,
#okta-sign-in .mfa-email-30,
#okta-sign-in .mfa-google-auth-30,
#okta-sign-in .mfa-okta-verify-30,
#okta-sign-in .mfa-onprem-30,
#okta-sign-in .mfa-password-30,
#okta-sign-in .mfa-question-30,
#okta-sign-in .mfa-rsa-30,
#okta-sign-in .mfa-sms-30,
#okta-sign-in .mfa-symantec-30,
#okta-sign-in .mfa-u2f-30,
#okta-sign-in .mfa-windows-hello-30,
#okta-sign-in .mfa-yubikey-30 {
	height: 30px;
	width: 30px;
	display: inline-block;
	border: 1px solid #ccc;
	border-radius: 50%;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
}
#okta-sign-in .auth-footer {
	/*font-size:12px*/
}
#okta-sign-in .auth-footer .goto {
	float: right;
}
#okta-sign-in .auth-footer .dot-separator {
	font-weight: 700;
}
#okta-sign-in .enroll-factor-list {
	margin-bottom: 30px;
}
#okta-sign-in .enroll-factor-list .list-subtitle {
	float: right;
}
#okta-sign-in .enroll-factor-list .list-title {
	padding-bottom: 20px;
}
#okta-sign-in .enroll-required-factor-list .enroll-factor-row-min {
	margin-bottom: 30px;
}
#okta-sign-in .enroll-required-factor-list .enroll-factor-row-min .enroll-factor-icon {
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	opacity: 0.5;
}
#okta-sign-in .enroll-factor-row {
	margin-bottom: 30px;
}
#okta-sign-in .enroll-factor-row .enroll-factor-description {
	overflow: hidden;
}
#okta-sign-in .enroll-factor-row .enroll-factor-button {
	padding-top: 10px;
}
@media only screen and (max-device-width: 480px) {
	#okta-sign-in .enroll-factor-row .enroll-factor-button {
		width: 100%;
		margin-top: 10px;
	}
}
#okta-sign-in .enroll-factor-row .enroll-factor-button .button {
	margin-top: 0;
	height: 30px;
	line-height: 28px;
	padding: 0 10px;
}
@media only screen and (max-device-width: 480px) {
	#okta-sign-in .enroll-factor-row .enroll-factor-button .button {
		width: 100%;
		text-align: center;
	}
}
#okta-sign-in .enroll-factor-row .enroll-factor-icon-container {
	float: left;
	margin-right: 20px;
}
#okta-sign-in .enroll-factor-row .enroll-factor-icon {
	width: 38px;
	height: 38px;
	border: 1px solid #ccc;
	border-radius: 50%;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: 38px 38px;
}
#okta-sign-in .enroll-factor-row .success-16-gray,
#okta-sign-in .enroll-factor-row .success-16-green {
	float: right;
	width: 20px;
	height: 20px;
	line-height: 24px;
}
#okta-sign-in .enroll-factor-row .success-16-gray:before,
#okta-sign-in .enroll-factor-row .success-16-green:before {
	font-size: 20px;
}
#okta-sign-in .enroll-factor-row-min {
	margin-bottom: 10px;
}
#okta-sign-in .enroll-factor-row-min h3 {
	padding-top: 10px;
}
#okta-sign-in .enroll-factor-row-min p {
	display: none;
}
#okta-sign-in .enroll-yubikey .yubikey-demo {
	background: url(../img/icons/mfa/yubikeyDemo.png) no-repeat 50%;
	background-size: contain;
	width: 100%;
	min-height: 102px;
	margin-bottom: 20px;
}
#okta-sign-in .enroll-sms .enroll-sms-phone {
	width: 196px;
	float: left;
}
@media only screen and (max-device-width: 480px) {
	#okta-sign-in .enroll-sms .enroll-sms-phone {
		width: 165px;
	}
}
@media only screen and (max-width: 400px) {
	#okta-sign-in .enroll-sms .enroll-sms-phone {
		width: 100%;
		margin-bottom: 15px;
	}
}
#okta-sign-in .enroll-sms .sms-request-button {
	float: right;
	width: 111px;
	margin-top: 0;
	padding: 0;
}
@media only screen and (max-width: 400px) {
	#okta-sign-in .enroll-sms .sms-request-button {
		width: 100%;
		margin-bottom: 15px;
	}
}
#okta-sign-in .enroll-call .enroll-call-phone {
	width: 196px;
	float: left;
}
@media only screen and (max-device-width: 480px) {
	#okta-sign-in .enroll-call .enroll-call-phone {
		width: 165px;
	}
}
@media only screen and (max-width: 400px) {
	#okta-sign-in .enroll-call .enroll-call-phone {
		width: 100%;
		margin-bottom: 15px;
	}
}
#okta-sign-in .enroll-call .enroll-call-extension {
	float: right;
	width: 111px;
	margin-top: 0;
	padding: 0;
}
@media only screen and (max-width: 400px) {
	#okta-sign-in .enroll-call .enroll-call-extension {
		width: 100%;
		margin-bottom: 15px;
	}
}
#okta-sign-in .enroll-call .call-request-button {
	width: 100%;
	text-align: center;
}
#okta-sign-in .enroll-u2f .u2f-instructions ol,
#okta-sign-in .enroll-webauthn .u2f-instructions ol {
	list-style: decimal;
	margin-left: 15px;
}
#okta-sign-in .enroll-u2f .u2f-instructions ol li,
#okta-sign-in .enroll-webauthn .u2f-instructions ol li {
	margin-bottom: 15px;
}
#okta-sign-in .enroll-windows-hello .o-form-fieldset-container > .okta-form-subtitle,
#okta-sign-in .verify-windows-hello .o-form-fieldset-container > .okta-form-subtitle {
	margin-top: 15px;
	margin-bottom: 20px;
}
#okta-sign-in .enroll-windows-hello .okta-infobox-error b,
#okta-sign-in .verify-windows-hello .okta-infobox-error b {
	font-weight: 700;
}
#okta-sign-in .enroll-windows-hello .okta-waiting-spinner,
#okta-sign-in .verify-windows-hello .okta-waiting-spinner {
	margin-top: 20px;
	margin-bottom: 20px;
}
#okta-sign-in .device-type-input .o-form-input {
	display: table;
	width: 100%;
}
#okta-sign-in .device-type-input .o-form-input > span {
	display: table-row;
}
#okta-sign-in .device-type-input .o-form-input > span > div {
	display: table-cell;
}
#okta-sign-in .device-type-input label,
#okta-sign-in .device-type-input label.checked,
#okta-sign-in .device-type-input label.focus,
#okta-sign-in .device-type-input label.hover {
	height: 60px;
	width: 60px;
	margin: 0 auto;
	padding: 0;
	background-position: 50% 50%;
}
#okta-sign-in .device-type-input [value='APPLE'] + label {
	background-image: url(../img/icons/mfa/iOS_60x60.png);
}
#okta-sign-in .device-type-input [value='ANDROID'] + label {
	background-image: url(../img/icons/mfa/android_60x60.png);
}
#okta-sign-in .device-type-input [value='WINDOWS'] + label {
	background-image: url(../img/icons/mfa/windows_60x60.png);
}
#okta-sign-in .device-type-input [value='BLACKBERRY'] + label {
	background-image: url(../img/icons/mfa/blackberry_60x60.png);
}
#okta-sign-in .device-type-input [value='APPLE'] + label.checked,
#okta-sign-in .device-type-input [value='APPLE'] + label.hover {
	background-image: url(../img/icons/mfa/iOSActive_60x60.png);
}
#okta-sign-in .device-type-input [value='ANDROID'] + label.checked,
#okta-sign-in .device-type-input [value='ANDROID'] + label.hover {
	background-image: url(../img/icons/mfa/androidActive_60x60.png);
}
#okta-sign-in .device-type-input [value='WINDOWS'] + label.checked,
#okta-sign-in .device-type-input [value='WINDOWS'] + label.hover {
	background-image: url(../img/icons/mfa/windowsActive_60x60.png);
}
#okta-sign-in .device-type-input [value='BLACKBERRY'] + label.checked,
#okta-sign-in .device-type-input [value='BLACKBERRY'] + label.hover {
	background-image: url(../img/icons/mfa/blackberryActive_60x60.png);
}
#okta-sign-in .app-logo {
	display: inline-block;
	width: 38px;
	height: 38px;
	background-repeat: no-repeat;
	border-radius: 10px;
	margin-right: 15px;
	float: left;
}
#okta-sign-in .okta-verify-38 {
	background-image: url(../img/icons/mfa/oktaVerify_38x38.png);
}
#okta-sign-in .google-auth-38 {
	background-image: url(../img/icons/mfa/googleAuth_38x38.png);
}
#okta-sign-in .app-download-instructions {
	margin-bottom: 15px;
}
#okta-sign-in .app-download-instructions .instructions-title {
	text-align: center;
	margin-bottom: 15px;
}
#okta-sign-in .barcode-scan .o-form-content {
	margin-bottom: 15px;
}
#okta-sign-in .scan-instructions .scan-instructions-details-wrapper {
	width: 100%;
	margin-right: -150px;
	float: left;
}
#okta-sign-in .scan-instructions .scan-instructions-details {
	padding-right: 150px;
}
#okta-sign-in .scan-instructions .scan-instructions-qrcode-wrapper {
	float: right;
	text-align: center;
}
#okta-sign-in .scan-instructions .qrcode-image {
	width: 140px;
	height: 140px;
	border: 1px solid #ccc;
	border-radius: 3px;
}
#okta-sign-in .scan-instructions .qrcode-wrap {
	position: relative;
}
#okta-sign-in .scan-instructions .qrcode-success {
	background: url(../img/ui/indicators/checkmark.png) no-repeat 50%;
}
#okta-sign-in .scan-instructions .qrcode-error {
	background: url(../img/ui/indicators/scanerror.png) no-repeat 50%;
}
#okta-sign-in .scan-instructions .qrcode-error,
#okta-sign-in .scan-instructions .qrcode-success {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
	filter: alpha(opacity=0);
	opacity: 0;
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	background-color: transparent;
	background-color: hsla(0, 0%, 100%, 0.6);
}
#okta-sign-in .scan-instructions .refresh-qrcode {
	display: none;
}
#okta-sign-in .scan-instructions.qrcode-expired .qrcode-error {
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=1)';
	filter: alpha(opacity=1);
	opacity: 1;
}
#okta-sign-in .scan-instructions.qrcode-expired .manual-setup {
	display: none;
}
#okta-sign-in .scan-instructions.qrcode-expired .refresh-qrcode {
	display: inline-block;
}
#okta-sign-in .scan-instructions.qrcode-success .qrcode-success {
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=1)';
	filter: alpha(opacity=1);
	opacity: 1;
}
#okta-sign-in .scan-instructions.qrcode-success .manual-setup,
#okta-sign-in .scan-instructions.qrcode-success .refresh-qrcode {
	display: none;
}
#okta-sign-in .enroll-manual-push .button-next {
	margin: 15px 0 11px;
}
#okta-sign-in [name='sharedSecret'] {
	text-align: center;
	cursor: text;
	opacity: 1;
}
#okta-sign-in .enroll-activation-link-sent p {
	text-align: center;
}
#okta-sign-in .enroll-activation-link-sent .auth-footer {
	margin-top: 15px;
}
#okta-sign-in .enroll-u2f-form .o-form-fieldset-container p,
#okta-sign-in .enroll-webauthn-form .o-form-fieldset-container p,
#okta-sign-in .verify-u2f-form .o-form-fieldset-container p,
#okta-sign-in .verify-webauthn-form .o-form-fieldset-container p {
	margin-bottom: 15px;
	text-align: center;
}
#okta-sign-in .enroll-u2f-form .o-form-content > .o-form-error-container,
#okta-sign-in .enroll-webauthn-form .o-form-content > .o-form-error-container,
#okta-sign-in .verify-u2f-form .o-form-content > .o-form-error-container,
#okta-sign-in .verify-webauthn-form .o-form-content > .o-form-error-container {
	margin-bottom: 20px;
}
#okta-sign-in .enroll-u2f-form .okta-waiting-spinner,
#okta-sign-in .enroll-webauthn-form .okta-waiting-spinner,
#okta-sign-in .verify-u2f-form .okta-waiting-spinner,
#okta-sign-in .verify-webauthn-form .okta-waiting-spinner {
	margin-top: 20px;
	margin-bottom: 20px;
}
#okta-sign-in .enroll-u2f-form .u2f-devices-images,
#okta-sign-in .enroll-webauthn-form .u2f-devices-images,
#okta-sign-in .verify-u2f-form .u2f-devices-images,
#okta-sign-in .verify-webauthn-form .u2f-devices-images {
	display: inline-block;
}
#okta-sign-in .enroll-u2f-form .u2f-devices-images .u2f-usb,
#okta-sign-in .enroll-webauthn-form .u2f-devices-images .u2f-usb,
#okta-sign-in .verify-u2f-form .u2f-devices-images .u2f-usb,
#okta-sign-in .verify-webauthn-form .u2f-devices-images .u2f-usb {
	background: url(../img/icons/mfa/u2f_usb.png) no-repeat 50%;
	height: 109px;
	width: 148px;
	margin-right: 15px;
	float: left;
}
#okta-sign-in .enroll-u2f-form .u2f-devices-images .u2f-bluetooth,
#okta-sign-in .enroll-webauthn-form .u2f-devices-images .u2f-bluetooth,
#okta-sign-in .verify-u2f-form .u2f-devices-images .u2f-bluetooth,
#okta-sign-in .verify-webauthn-form .u2f-devices-images .u2f-bluetooth {
	background: url(../img/icons/mfa/u2f_bluetooth.png) no-repeat 50%;
	height: 109px;
	width: 148px;
	float: left;
}
#okta-sign-in .okta-waiting-spinner {
	background: url(../img/ui/indicators/sign-on-widget-spinner.gif) no-repeat 50%;
	height: 18px;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2),
	only screen and (min-device-pixel-ratio: 2),
	only screen and (min-resolution: 2dppx) {
	#okta-sign-in .device-type-input .custom-radio label {
		background-size: auto;
	}
}
#okta-sign-in .accessibility-text {
	display: block;
	height: 0;
	overflow: hidden;
}
#okta-sign-in .registration-container {
	margin-top: 30px;
}
#okta-sign-in .registration-container .content-container {
	padding: 30px 42px;
	-moz-transition: padding-top 0.4s;
	-webkit-transition: padding-top 0.4s;
	transition: padding-top 0.4s;
	border-top: 1px solid #ddd;
	margin-top: 5px;
}
#okta-sign-in .registration-container .registration-link {
	margin-left: 10px;
	color: #007dc1;
}
#okta-sign-in .registration .o-form-fieldset-container .required-fields-label {
	color: #a7a7a7;
}
#okta-sign-in .registration .subschema {
	margin-bottom: 15px;
}
#okta-sign-in .registration .subschema p {
	padding: 5px 20px;
	position: relative;
}
#okta-sign-in .registration .subschema p.default-schema {
	display: none;
}
#okta-sign-in .registration .subschema p span {
	position: absolute;
	left: -2px;
}
#okta-sign-in .registration .subschema .subschema-unsatisfied {
	color: #777;
}
#okta-sign-in .registration .subschema .subschema-satisfied {
	color: #a7a7a7;
}
#okta-sign-in .registration .subschema .subschema-error {
	color: #e34843;
}
#okta-sign-in .registration .subschema .subschema-error span.error-16-small {
	display: block;
}
#okta-sign-in .registration-complete {
	margin-top: 20px;
}
#okta-sign-in .registration-complete h2 {
	color: #46b090;
	text-align: center;
	margin: 15px 0;
	font-size: 15px;
}
#okta-sign-in .registration-complete .back-btn {
	margin-top: 15px;
}
#okta-sign-in .registration-complete .title-icon {
	position: absolute;
	display: block;
	top: -10px;
	left: 45%;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	background: #46b090;
}
#okta-sign-in .registration-complete .title-icon:before {
	color: #fff;
	left: 4px;
}
@media only screen and (max-height: 550px) {
	#okta-sign-in.can-remove-beacon .beacon-container {
		display: none;
	}
	#okta-sign-in.can-remove-beacon .auth-content {
		padding-top: 20px;
	}
	#okta-sign-in.can-remove-beacon .enroll-choices {
		padding-top: 15px;
	}
	#okta-sign-in.can-remove-beacon .auth-header {
		padding: 30px 90px 25px;
	}
}
.qtip {
	font-size: 13px;
}
.qtip-custom .qtip-titlebar {
	background-color: transparent;
	border: 0;
	font-weight: 400;
}
.qtip-title {
	word-break: break-all;
}
.security-image-qtip.qtip-custom {
	font-size: 12px;
	line-height: 1.4;
	padding: 8px 8px 5px;
}
.security-image-qtip.qtip-custom canvas {
	margin-top: 2px;
}
.security-image-qtip.qtip-custom .qtip-icon {
	background: transparent url(../img/icons/login/tooltip_close.png) no-repeat 50%;
	border: none;
	color: transparent;
	right: 2px;
	top: 4px;
	text-decoration: none;
}
.security-image-qtip.qtip-custom .qtip-icon:hover {
	opacity: 0.5;
}
.o-form-input-name-rememberDevice {
	display: none;
}
/*! Custom qtip styles, based on dark theme */
.qtip-custom {
	background-color: #4d4d4d;
	border-color: #303030;
	color: #f3f3f3;
	padding: 5px 8px 8px;
}
.qtip-custom.qtip-pos-bl {
	border-radius: 4px 4px 4px 0;
}
.qtip-custom.qtip-pos-br {
	border-radius: 4px 4px 0;
}
.qtip-custom .qtip-content {
	color: #f3f3f3;
}
.qtip-custom .qtip-titlebar {
	background-color: #404040;
}
.qtip-custom .qtip-icon {
	border-color: #444;
}
.qtip-custom .qtip-titlebar .ui-state-hover {
	border-color: #303030;
}
.qtip-custom .qtip-content ul {
	list-style: disc;
}
.qtip-custom .qtip-content ul > li {
	color: #69882a;
	font-size: 20px;
}
.qtip-custom .qtip-content ul > li span {
	color: #f3f3f3;
	font-size: 12px;
	vertical-align: middle;
}
.chzn-container {
	position: relative;
	display: block;
	vertical-align: middle;
	float: left;
	zoom: 1;
	*display: inline;
}
.chzn-container .chzn-drop {
	background: #fff;
	border: 1px solid #888;
	border-top: 0;
	position: absolute;
	top: 100%;
	left: -9999px;
	-webkit-box-shadow: 0 1px 2px hsla(0, 0%, 68.6%, 0.15);
	-moz-box-shadow: 0 1px 2px hsla(0, 0%, 68.6%, 0.15);
	box-shadow: 0 1px 2px hsla(0, 0%, 68.6%, 0.15);
	z-index: 1010;
	width: 100%;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-khtml-box-sizing: border-box;
	box-sizing: border-box;
}
.chzn-container.chzn-with-drop .chzn-drop {
	left: 0;
}
.chzn-container-single .chzn-single {
	-moz-background-clip: padding;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	display: block;
	overflow: hidden;
	white-space: nowrap;
	position: relative;
	height: 28px;
	line-height: 28px;
	padding: 0 0 0 8px;
	text-decoration: none;
}
.chzn-container-single .chzn-single:hover {
	border: 1px solid #888;
}
.chzn-container-single .chzn-default {
	color: #999;
}
.chzn-container-single .chzn-single span {
	margin-right: 26px;
	display: block;
	overflow: hidden;
	white-space: nowrap;
	-o-text-overflow: ellipsis;
	-ms-text-overflow: ellipsis;
	text-overflow: ellipsis;
	color: #333;
	text-shadow: 0 1px 0 #fff;
}
.chzn-container-single .chzn-single abbr {
	display: block;
	position: absolute;
	right: 26px;
	top: 6px;
	width: 12px;
	height: 12px;
	font-size: 1px;
	background: url(../img/ui/forms/chosen-sprite.png) -42px 1px no-repeat;
}
.chzn-container-single.chzn-disabled .chzn-single abbr:hover,
.chzn-container-single .chzn-single abbr:hover {
	background-position: -42px -10px;
}
.chzn-container-single .chzn-single div {
	position: absolute;
	right: 0;
	top: 3px;
	display: block;
	height: 100%;
	width: 18px;
}
.chzn-container-single .chzn-single div b {
	background: url(../img/ui/forms/chosen-sprite.png) no-repeat 0 2px;
	display: block;
	width: 100%;
	height: 100%;
}
.chzn-container-single .chzn-search {
	padding: 3px 4px;
	position: relative;
	margin: 0;
	white-space: nowrap;
	z-index: 1010;
}
.chzn-container-single .chzn-search input {
	background: #fff url(../img/ui/forms/chosen-sprite.png) no-repeat 100% -20px;
	background: url(../img/ui/forms/chosen-sprite.png) no-repeat 100% -20px,
		-webkit-gradient(linear, 0 0, 0 100%, color-stop(1%, #eee), color-stop(15%, #fff));
	background: url(../img/ui/forms/chosen-sprite.png) no-repeat 100% -20px,
		-webkit-linear-gradient(top, #eee 1%, #fff 15%);
	background: url(../img/ui/forms/chosen-sprite.png) no-repeat 100% -20px,
		-moz-linear-gradient(top, #eee 1%, #fff 15%);
	background: url(../img/ui/forms/chosen-sprite.png) no-repeat 100% -20px,
		-o-linear-gradient(top, #eee 1%, #fff 15%);
	background: url(../img/ui/forms/chosen-sprite.png) no-repeat 100% -20px,
		-webkit-linear-gradient(#eee 1%, #fff 15%);
	background: url(../img/ui/forms/chosen-sprite.png) no-repeat 100% -20px,
		linear-gradient(#eee 1%, #fff 15%);
	margin: 1px 0;
	padding: 4px 20px 4px 5px;
	outline: 0;
	border: 1px solid #aaa;
	font-size: 1em;
	width: 100%;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-khtml-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 0;
}
.chzn-container-single .chzn-drop {
	margin-top: -1px;
	-webkit-border-radius: 0 0 4px 4px;
	-moz-border-radius: 0 0 4px 4px;
	border-radius: 0 0 4px 4px;
	-moz-background-clip: padding;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
}
.chzn-container-single-nosearch .chzn-search {
	position: absolute;
	left: -9999px;
}
.chzn-container-multi .chzn-choices {
	background-color: #fff;
	background-image: -webkit-gradient(
		linear,
		0 0,
		0 100%,
		color-stop(1%, #eee),
		color-stop(15%, #fff)
	);
	background-image: -webkit-linear-gradient(top, #eee 1%, #fff 15%);
	background-image: -moz-linear-gradient(top, #eee 1%, #fff 15%);
	background-image: -o-linear-gradient(top, #eee 1%, #fff 15%);
	background-image: -webkit-linear-gradient(#eee 1%, #fff 15%);
	background-image: linear-gradient(#eee 1%, #fff 15%);
	border: 1px solid #aaa;
	margin: 0;
	padding: 0;
	cursor: text;
	overflow: hidden;
	height: auto !important;
	height: 1%;
	position: relative;
	width: 100%;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-khtml-box-sizing: border-box;
	box-sizing: border-box;
}
.chzn-container-multi .chzn-choices li {
	float: left;
	list-style: none;
}
.chzn-container-multi .chzn-choices .search-field {
	white-space: nowrap;
	margin: 0;
	padding: 0;
}
.chzn-container-multi .chzn-choices .search-field input {
	color: #666;
	background: transparent !important;
	border: 0 !important;
	font-size: 100%;
	height: 15px;
	padding: 5px;
	margin: 1px 0;
	outline: 0;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}
.chzn-container-multi .chzn-choices .search-field .default {
	color: #999;
}
.chzn-container-multi .chzn-choices .search-choice {
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	-moz-background-clip: padding;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	background-image: -webkit-gradient(
		linear,
		0 0,
		0 100%,
		color-stop(20%, #f4f4f4),
		color-stop(50%, #f0f0f0),
		color-stop(52%, #e8e8e8),
		color-stop(100%, #eee)
	);
	background-image: -webkit-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee);
	background-image: -moz-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
	background-image: -o-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
	background-image: -webkit-linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee);
	background-image: linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee);
	-webkit-box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
	-moz-box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
	box-shadow: inset 0 0 2px #fff, 0 1px 0 rgba(0, 0, 0, 0.05);
	color: #333;
	border: 1px solid #aaa;
	line-height: 13px;
	padding: 3px 20px 3px 5px;
	margin: 3px 0 3px 5px;
	position: relative;
	cursor: default;
}
.chzn-container-multi .chzn-choices .search-choice,
.chzn-container-multi .chzn-choices .search-choice.search-choice-disabled {
	background-color: #e4e4e4;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr = "#f4f4f4",endColorstr = "#eeeeee",GradientType = 0);
}
.chzn-container-multi .chzn-choices .search-choice.search-choice-disabled {
	background-image: -webkit-gradient(
		linear,
		0 0,
		0 100%,
		color-stop(20%, #f4f4f4),
		color-stop(50%, #f0f0f0),
		color-stop(52%, #e8e8e8),
		color-stop(100%, #eee)
	);
	background-image: -webkit-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee);
	background-image: -moz-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
	background-image: -o-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
	background-image: -ms-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
	background-image: linear-gradient(180deg, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee);
	color: #666;
	border: 1px solid #ccc;
	padding-right: 5px;
}
.chzn-container-multi .chzn-choices .search-choice-focus {
	background: #d4d4d4;
}
.chzn-container-multi .chzn-choices .search-choice .search-choice-close {
	display: block;
	position: absolute;
	right: 3px;
	top: 4px;
	width: 12px;
	height: 12px;
	font-size: 1px;
	background: url(../img/ui/forms/chosen-sprite.png) -42px 1px no-repeat;
}
.chzn-container-multi .chzn-choices .search-choice-focus .search-choice-close,
.chzn-container-multi .chzn-choices .search-choice .search-choice-close:hover {
	background-position: -42px -10px;
}
.chzn-container .chzn-results {
	margin: 3px 0 4px;
	max-height: 240px;
	padding: 0;
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}
.chzn-container-multi .chzn-results {
	margin: 0;
	padding: 0;
}
.chzn-container .chzn-results li {
	display: none;
	line-height: 16px;
	padding: 7px 8px;
	margin: 0;
	list-style: none;
	border: solid #fff;
	border-width: 0 0 1px;
}
.chzn-container .chzn-results .active-result {
	cursor: pointer;
	display: list-item;
}
.chzn-container .chzn-results .highlighted {
	background: #f9f9f9;
}
.chzn-container .chzn-results li em {
	background: #feffde;
	font-style: normal;
}
.chzn-container .chzn-results .highlighted em {
	background: transparent;
}
.chzn-container .chzn-results .no-results {
	background: #fff;
	display: list-item;
}
.chzn-container .chzn-results .group-result {
	cursor: default;
	color: #999;
	font-weight: 700;
}
.chzn-container .chzn-results .group-option {
	padding-left: 15px;
}
.chzn-container-multi .chzn-drop .result-selected {
	display: none;
}
.chzn-container .chzn-results-scroll {
	background: #fff;
	margin: 0 4px;
	position: absolute;
	text-align: center;
	width: 321px;
	z-index: 1;
}
.chzn-container .chzn-results-scroll span {
	display: inline-block;
	height: 17px;
	text-indent: -5000px;
	width: 9px;
}
.chzn-container .chzn-results-scroll-down {
	bottom: 0;
}
.chzn-container .chzn-results-scroll-down span {
	background: url(../img/ui/forms/chosen-sprite.png) no-repeat -4px -3px;
}
.chzn-container .chzn-results-scroll-up span {
	background: url(../img/ui/forms/chosen-sprite.png) no-repeat -22px -3px;
}
.chzn-container-active.chzn-with-drop .chzn-single {
	border: 1px solid #888;
	-webkit-box-shadow: 0 1px 0 #fff inset;
	-moz-box-shadow: 0 1px 0 #fff inset;
	box-shadow: inset 0 1px 0 #fff;
	background: #fff;
	-webkit-border-bottom-left-radius: 0;
	-webkit-border-bottom-right-radius: 0;
	-moz-border-radius-bottomleft: 0;
	-moz-border-radius-bottomright: 0;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.chzn-container-active .chzn-single {
	-webkit-box-shadow: 0 1px 2px hsla(0, 0%, 68.6%, 0.3);
	-moz-box-shadow: 0 1px 2px hsla(0, 0%, 68.6%, 0.3);
	box-shadow: 0 1px 2px hsla(0, 0%, 68.6%, 0.3);
	border: 1px solid #888;
}
.chzn-container-active.chzn-with-drop .chzn-single div {
	background: transparent;
	border-left: none;
}
.chzn-container-active.chzn-with-drop .chzn-single div b {
	background-position: -18px 2px;
}
.chzn-container-active .chzn-choices {
	-webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
	border: 1px solid #5897fb;
}
.chzn-container-active .chzn-choices .search-field input {
	color: #111 !important;
}
.chzn-disabled {
	cursor: default;
	opacity: 0.5 !important;
}
.chzn-disabled .chzn-choices .search-choice .search-choice-close,
.chzn-disabled .chzn-single {
	cursor: default;
}
.chzn-rtl {
	text-align: right;
}
.chzn-rtl .chzn-single {
	padding: 0 8px 0 0;
	overflow: visible;
}
.chzn-rtl .chzn-single span {
	margin-left: 26px;
	margin-right: 0;
	direction: rtl;
}
.chzn-rtl .chzn-single div {
	left: 3px;
	right: auto;
}
.chzn-rtl .chzn-single abbr {
	left: 26px;
	right: auto;
}
.chzn-rtl .chzn-choices .search-field input {
	direction: rtl;
}
.chzn-rtl .chzn-choices li {
	float: right;
}
.chzn-rtl .chzn-choices .search-choice {
	padding: 3px 5px 3px 19px;
	margin: 3px 5px 3px 0;
}
.chzn-rtl .chzn-choices .search-choice .search-choice-close {
	left: 4px;
	right: auto;
}
.chzn-rtl .chzn-search {
	left: 9999px;
}
.chzn-rtl.chzn-with-drop .chzn-search {
	left: 0;
}
.chzn-rtl .chzn-drop {
	left: 9999px;
}
.chzn-rtl.chzn-container-single .chzn-results {
	margin: 0 0 4px 4px;
	padding: 0 4px 0 0;
}
.chzn-rtl .chzn-results .group-option {
	padding-left: 0;
	padding-right: 15px;
}
.chzn-rtl.chzn-container-active.chzn-with-drop .chzn-single div {
	border-right: none;
}
.chzn-rtl .chzn-search input {
	background: #fff url(../img/ui/forms/chosen-sprite.png) no-repeat -30px -20px;
	background: url(../img/ui/forms/chosen-sprite.png) no-repeat -30px -20px,
		-webkit-gradient(linear, 0 0, 0 100%, color-stop(1%, #eee), color-stop(15%, #fff));
	background: url(../img/ui/forms/chosen-sprite.png) no-repeat -30px -20px,
		-webkit-linear-gradient(top, #eee 1%, #fff 15%);
	background: url(../img/ui/forms/chosen-sprite.png) no-repeat -30px -20px,
		-moz-linear-gradient(top, #eee 1%, #fff 15%);
	background: url(../img/ui/forms/chosen-sprite.png) no-repeat -30px -20px,
		-o-linear-gradient(top, #eee 1%, #fff 15%);
	background: url(../img/ui/forms/chosen-sprite.png) no-repeat -30px -20px,
		-webkit-linear-gradient(#eee 1%, #fff 15%);
	background: url(../img/ui/forms/chosen-sprite.png) no-repeat -30px -20px,
		linear-gradient(#eee 1%, #fff 15%);
	padding: 4px 5px 4px 20px;
	direction: rtl;
}
.chzn-container-single.chzn-rtl .chzn-single div b {
	background-position: 6px 2px;
}
.chzn-container-single.chzn-rtl.chzn-with-drop .chzn-single div b {
	background-position: -12px 2px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	.chzn-container-multi .chzn-choices .search-choice .search-choice-close,
	.chzn-container-single .chzn-search input,
	.chzn-container-single .chzn-single abbr,
	.chzn-container-single .chzn-single div b,
	.chzn-container .chzn-results-scroll-down span,
	.chzn-container .chzn-results-scroll-up span,
	.chzn-rtl .chzn-search input {
		background-image: url(../img/ui/forms/chosen-sprite@2x.png) !important;
		background-repeat: no-repeat !important;
		background-size: 52px 37px !important;
	}
}
.o-form-head {
	font-size: 14px;
	line-height: 1.5;
	margin-top: 10px;
	text-align: center;
	text-transform: none;
}
.o-form-head + .o-form-explain {
	margin-bottom: 20px;
	text-align: center;
}
.o-form-button-bar {
	background: transparent;
	border: none;
	padding: 0 0 25px;
	border-radius: 0;
	box-shadow: none;
}
.o-form-button-bar .button {
	display: block;
	width: 100%;
	height: 50px;
}
.o-form-button-bar .button-success {
	position: absolute;
	text-align: center;
}
.o-form-theme {
	background: transparent;
	box-shadow: none;
	padding: 0 0 14px;
	border-radius: 0;
	border: none;
}
.o-form .o-form-fieldset:last-child {
	margin-bottom: 15px;
}
.o-form .o-form-fieldset.margin-btm-0 {
	margin-bottom: 0;
}
.o-form .o-form-label,
.o-form input,
.o-form label,
.o-form textarea {
	font-size: 13px;
}
.o-form .o-form-label {
	font-weight: 400;
}
.o-form .o-form-label-inline + .input-fix input[type='text'] {
	line-height: 27px;
}
.o-form .input-fix,
.o-form .textarea-fix {
	height: 40px;
	border: 1px solid #bbb;
	display: inline-block;
	float: left;
}
.o-form .input-fix input[disabled],
.o-form .input-fix input[readonly],
.o-form .textarea-fix input[disabled],
.o-form .textarea-fix input[readonly] {
	background: #f4f4f4;
	color: #777;
	border-radius: 3px;
	box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.1);
}
.o-form .input-fix input,
.o-form .textarea-fix input {
	padding: 8px;
	line-height: 22px;
	font-size: 13px;
	border: none;
	background: none;
	box-shadow: none;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
	.o-form .input-fix input,
	.o-form .textarea-fix input {
		font-size: 14px;
	}
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
	.o-form .input-fix input,
	.o-form .textarea-fix input {
		font-size: 14px;
	}
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
	.o-form .input-fix input,
	.o-form .textarea-fix input {
		font-size: 14px;
	}
}
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
	.o-form .input-fix input,
	.o-form .textarea-fix input {
		font-size: 14px;
	}
}
@media screen and (device-width: 320px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 2) {
	.o-form .input-fix input,
	.o-form .textarea-fix input {
		font-size: 14px;
	}
}
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) {
	.o-form .input-fix input,
	.o-form .textarea-fix input {
		font-size: 14px;
	}
}
.o-form .input-fix input::-webkit-input-placeholder,
.o-form .textarea-fix input::-webkit-input-placeholder {
	font-size: 13px;
	letter-spacing: 0;
}
.o-form .input-fix input::-moz-placeholder,
.o-form .textarea-fix input::-moz-placeholder {
	font-size: 13px;
}
.o-form .input-fix input:-ms-input-placeholder,
.o-form .textarea-fix input:-ms-input-placeholder {
	font-size: 13px;
	line-height: 27px;
}
.o-form .input-fix input:-webkit-autofill,
.o-form .textarea-fix input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 1000px #fff inset;
	border-radius: 3px;
}
.o-form .input-fix input[type='password'],
.o-form .textarea-fix input[type='password'] {
	font-size: 13px;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
	.o-form .input-fix input[type='password'],
	.o-form .textarea-fix input[type='password'] {
		font-size: 14px;
	}
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
	.o-form .input-fix input[type='password'],
	.o-form .textarea-fix input[type='password'] {
		font-size: 14px;
	}
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
	.o-form .input-fix input[type='password'],
	.o-form .textarea-fix input[type='password'] {
		font-size: 14px;
	}
}
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
	.o-form .input-fix input[type='password'],
	.o-form .textarea-fix input[type='password'] {
		font-size: 14px;
	}
}
@media screen and (device-width: 320px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 2) {
	.o-form .input-fix input[type='password'],
	.o-form .textarea-fix input[type='password'] {
		font-size: 14px;
	}
}
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) {
	.o-form .input-fix input[type='password'],
	.o-form .textarea-fix input[type='password'] {
		font-size: 14px;
	}
}
.o-form .input-fix .placeholder,
.o-form .textarea-fix .placeholder {
	color: #aaa;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
	.o-form .chzn-container-single .chzn-search input,
	.o-form select,
	.o-form select:focus {
		font-size: 14px;
	}
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
	.o-form .chzn-container-single .chzn-search input,
	.o-form select,
	.o-form select:focus {
		font-size: 14px;
	}
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
	.o-form .chzn-container-single .chzn-search input,
	.o-form select,
	.o-form select:focus {
		font-size: 14px;
	}
}
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
	.o-form .chzn-container-single .chzn-search input,
	.o-form select,
	.o-form select:focus {
		font-size: 14px;
	}
}
@media screen and (device-width: 320px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 2) {
	.o-form .chzn-container-single .chzn-search input,
	.o-form select,
	.o-form select:focus {
		font-size: 14px;
	}
}
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) {
	.o-form .chzn-container-single .chzn-search input,
	.o-form select,
	.o-form select:focus {
		font-size: 14px;
	}
}
.o-form .custom-checkbox label {
	/*font-size:12px*/
}
.o-form .o-form-input input[name='nextPassCode'],
.o-form .o-form-input input[name='passCode'] {
	font-family: Anonymous Pro, Andale Mono, Courier New, Courier, serif;
	font-size: 17px;
	letter-spacing: 1px;
}
.o-form .o-form-input .o-form-control {
	position: relative;
}
.o-form .o-form-input .input-icon,
.o-form .o-form-input .input-tooltip {
	width: 16px;
	height: 16px;
	position: absolute;
	top: 50%;
	margin-top: -11px;
}
.o-form .o-form-input .input-icon + input,
.o-form .o-form-input .input-icon + input + input,
.o-form .o-form-input .input-tooltip + input,
.o-form .o-form-input .input-tooltip + input + input {
	padding-right: 30px;
}
.o-form .o-form-input .input-icon {
	left: 10px;
	opacity: 0.25;
}
.o-form .o-form-input .input-icon + input,
.o-form .o-form-input .input-icon + input + input {
	padding-left: 30px;
}
.o-form .o-form-input .input-tooltip {
	right: 10px;
	opacity: 0.5;
}
.o-form-input-group .o-form-control {
	display: table-cell;
	float: none;
	white-space: nowrap;
}
.form-divider {
	border-bottom: 1px solid #e8e8e8;
	margin-bottom: 25px;
	padding-top: 5px;
	clear: both;
}
.chzn-container-single .chzn-single {
	border: 1px solid #bbb;
	border-radius: 3px;
	height: 38px;
	line-height: 38px;
}
.chzn-container-single .chzn-single div b {
	background-position: 0 6px;
}
.chzn-container-active.chzn-with-drop .chzn-single div b {
	background-position: -17px 7px;
}
.custom-checkbox label {
	background-image: url(../img/ui/forms/checkbox-sign-in-widget.png);
}
.custom-checkbox label.focus {
	background-position: -16px -1013px;
}
.custom-checkbox label.focus:before {
	background-position: -16px -1020px;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2),
	only screen and (min-device-pixel-ratio: 2),
	only screen and (min-resolution: 2dppx) {
	.custom-checkbox label {
		background-image: url(../img/ui/forms/checkbox-sign-in-widget@2x.png);
		background-size: 50px 1155px;
	}
}
.chzn-container-active {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font: inherit;
	vertical-align: baseline;
	background: transparent;
	font-size: 13px;
	font-family: montserrat, Arial, Helvetica, sans-serif;
	color: #777;
}
.chzn-container-active a,
.chzn-container-active abbr,
.chzn-container-active acronym,
.chzn-container-active address,
.chzn-container-active applet,
.chzn-container-active b,
.chzn-container-active big,
.chzn-container-active blockquote,
.chzn-container-active caption,
.chzn-container-active center,
.chzn-container-active cite,
.chzn-container-active code,
.chzn-container-active dd,
.chzn-container-active del,
.chzn-container-active dfn,
.chzn-container-active div,
.chzn-container-active dl,
.chzn-container-active dt,
.chzn-container-active em,
.chzn-container-active fieldset,
.chzn-container-active form,
.chzn-container-active h1,
.chzn-container-active h2,
.chzn-container-active h3,
.chzn-container-active h4,
.chzn-container-active h5,
.chzn-container-active h6,
.chzn-container-active i,
.chzn-container-active iframe,
.chzn-container-active img,
.chzn-container-active ins,
.chzn-container-active kbd,
.chzn-container-active label,
.chzn-container-active legend,
.chzn-container-active li,
.chzn-container-active object,
.chzn-container-active ol,
.chzn-container-active p,
.chzn-container-active pre,
.chzn-container-active q,
.chzn-container-active s,
.chzn-container-active samp,
.chzn-container-active small,
.chzn-container-active span,
.chzn-container-active strike,
.chzn-container-active strong,
.chzn-container-active sub,
.chzn-container-active sup,
.chzn-container-active table,
.chzn-container-active tbody,
.chzn-container-active td,
.chzn-container-active tfoot,
.chzn-container-active th,
.chzn-container-active thead,
.chzn-container-active tr,
.chzn-container-active tt,
.chzn-container-active u,
.chzn-container-active ul,
.chzn-container-active var {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	background: transparent;
}
/*# sourceMappingURL=okta-sign-in.css.map */
