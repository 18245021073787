.loader-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 25em;
}

.loading-message {
	font-weight: 600;
	font-size: 16px;
}

.ess-loader {
	height: 135px;
	flex: 1;
	width: 200px;
	background: url('/assets/img/loading.gif') no-repeat center;
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
}

.progress-bar {
	background-color: #0071bc !important ;
}
