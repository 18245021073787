body {
	font-family: 'Open Sans', sans-serif, Arial;
}

#okta-sign-in .auth-footer ul li {
	line-height: 1.9rem;
}

#okta-sign-in .auth-footer ul li:first-child {
	position: absolute;
	bottom: 9.25rem;
	left: 7.5rem;
	white-space: nowrap;
}

.terms-conditions-text button {
	padding: 5px 10px;
	border-radius: 4px !important;
	font-size: initial;
	margin-top: 1rem;
	cursor: pointer;
	cursor: hand;
}

#okta-sign-in .custom-checkbox label {
	background: none;
	padding-left: 30px;
}

#okta-sign-in .custom-checkbox label:before {
	background: transparent;
	border: 1px solid #bbb;
	content: '';
	width: 18px;
	height: 18px;
	position: absolute;
	left: 0;
	top: 6px;
	border-radius: 3px;
}

#okta-sign-in .custom-checkbox label:hover:before,
#okta-sign-in .custom-checkbox.focused-input label:before {
	background: transparent;
	border: 1px solid #bbb;
	content: '';
	width: 18px;
	height: 18px;
	position: absolute;
	left: 0;
	top: 6px;
	border-radius: 3px;
}

#okta-sign-in .custom-checkbox label:hover:after,
#okta-sign-in .custom-checkbox.focused-input label:after {
	content: '';
	background-color: transparent;
	position: absolute;
	left: 7px;
	top: 9px;
	width: 4px;
	border-bottom: 2px solid #bbb;
	height: 9px;
	border-right: 2px solid #bbb;
	transform: rotate(45deg);
	-o-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
}

#okta-sign-in .custom-checkbox label.checked:before {
	background: #0071bc;
	border: 1px solid #0071bc;
	content: '';
	width: 18px;
	height: 18px;
	position: absolute;
	left: 0;
	top: 6px;
	border-radius: 3px;
}

#okta-sign-in .custom-checkbox label.checked:after {
	content: '';
	background-color: transparent;
	position: absolute;
	left: 7px;
	top: 9px;
	width: 4px;
	border-bottom: 2px solid #fff;
	height: 9px;
	border-right: 2px solid #fff;
	transform: rotate(45deg);
	-o-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
}

#okta-sign-in.auth-container.main-container {
	/* -- Fonts and Text Colors -- */
	font-family: 'montserrat', Arial, Helvetica, sans-serif;
	color: #777;
}

#okta-sign-in.auth-container h2,
#okta-sign-in.auth-container h3 {
	/* -- Fonts and Text Colors -- */
	font-weight: bold;
	color: #5e5e5e;
}

#okta-sign-in.auth-container .okta-sign-in-header {
	/* -- Fonts and Text Colors -- */
	color: #5e5e5e;
}

#okta-sign-in.auth-container .okta-form-subtitle,
#okta-sign-in.auth-container .okta-form-label {
	/* -- Fonts and Text Colors -- */
	color: #777;
}

#okta-sign-in.auth-container .link {
	/* -- Fonts and Text Colors: links -- */
	color: #777;
}

#okta-sign-in.auth-container .link:link,
#okta-sign-in.auth-container .link:visited,
#okta-sign-in.auth-container .link:hover,
#okta-sign-in.auth-container .link:active {
	/* -- Fonts and Text Colors: links -- */
	color: #777;
}

#okta-sign-in.auth-container .inline-link {
	/* -- Fonts and Text Colors: links -- */
	color: #0074b3;
}

#okta-sign-in.auth-container .inline-link:link,
#okta-sign-in.auth-container .inline-link:visited,
#okta-sign-in.auth-container .inline-link:hover,
#okta-sign-in.auth-container .inline-link:active {
	/* -- Fonts and Text Colors: links -- */
	color: #0074b3;
}

#okta-sign-in.auth-container input[type='submit'],
#okta-sign-in.auth-container input[type='button'] {
	/* -- Submit Buttons' Fonts -- */
	font-family: 'montserrat', Arial, Helvetica, sans-serif;
}

#okta-sign-in.auth-container.main-container {
	/* -- Main Background and Border Colors -- */
	background-color: #fff;
	border-color: #ddd #ddd #d8d8d8;
	box-shadow: 0 2px 0 rgba(175, 175, 175, 0.12);
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
	#okta-sign-in.auth-container.main-container {
		border-width: 0;
		box-shadow: none;
	}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
	#okta-sign-in.auth-container.main-container {
		border-width: 0;
		box-shadow: none;
	}
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
	#okta-sign-in.auth-container.main-container {
		border-width: 0;
		box-shadow: none;
	}
}

@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
	#okta-sign-in.auth-container.main-container {
		border-width: 0;
		box-shadow: none;
	}
}

@media screen and (device-width: 320px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 2) {
	#okta-sign-in.auth-container.main-container {
		border-width: 0;
		box-shadow: none;
	}
}

@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) {
	#okta-sign-in.auth-container.main-container {
		border-width: 0;
		box-shadow: none;
	}
}

#okta-sign-in.auth-container.main-container .bg-helper {
	/* -- Main Background and Border Colors -- */
	/* set the helper's color value to the same color as the main-container's background */
	color: #f9f9f9;
}

#okta-sign-in.auth-container.main-container .primary-auth .password-with-toggle {
	padding-right: 65px;
}

#okta-sign-in.auth-container.main-container .primary-auth .password-toggle .eyeicon {
	right: 38px;
}

#okta-sign-in.auth-container.main-container
	.o-form
	.o-form-input
	.o-form-control
	.input-icon-divider {
	border-right: 1px solid #bbb;
	height: 38px;
	position: absolute;
	right: 34px;
	top: 0;
}

#okta-sign-in.auth-container.main-container
	.o-form
	.o-form-input
	.o-form-control.focused-input
	.input-icon-divider {
	border-right: 1px solid #0074b3;
}

#okta-sign-in.auth-container .okta-sign-in-header {
	/* -- Main Background and Border Colors -- */
	border-bottom-color: #ddd;
}

#okta-sign-in.auth-container .okta-sign-in-beacon-border {
	/* -- Main Background and Border Colors -- */
	border-color: #a7a7a7;
}

#okta-sign-in.auth-container .okta-form-divider {
	/* -- Main Background and Border Colors -- */
	border-bottom-color: #e8e8e8;
}

#okta-sign-in.auth-container .factor-icon,
#okta-sign-in.auth-container .qrcode-image {
	/* -- Main Background and Border Colors -- */
	border-color: #ccc;
}

#okta-sign-in.auth-container .mfa-okta-verify-30,
#okta-sign-in.auth-container .enroll-factor-row .mfa-okta-verify {
	/* -- Factor Icons (small): Okta Verify -- */
	background-image: url('../third-party/okta/img/icons/mfa/oktaVerify_38x38.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in.auth-container .mfa-okta-verify-30,
	#okta-sign-in.auth-container .enroll-factor-row .mfa-okta-verify {
		/* -- Factor Icons (high DPI): Okta Verify -- */
		background-image: url('../third-party/okta/img/icons/mfa/oktaVerify_ico@2x.png');
	}
}

#okta-sign-in.auth-container .mfa-okta-verify {
	/* -- Factor Icons (large): Okta Verify -- */
	background-image: url('../third-party/okta/img/icons/mfa/oktaVerify_70x70.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in.auth-container .mfa-okta-verify {
		/* -- Factor Icons (high DPI): Okta Verify -- */
		background-image: url('../third-party/okta/img/icons/mfa/oktaVerify@2x.png');
	}
}

#okta-sign-in.auth-container .mfa-google-auth-30,
#okta-sign-in.auth-container .enroll-factor-row .mfa-google-auth {
	/* -- Factor Icons (small): Google Authenticator -- */
	background-image: url('../third-party/okta/img/icons/mfa/googleAuth_38x38.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in.auth-container .mfa-google-auth-30,
	#okta-sign-in.auth-container .enroll-factor-row .mfa-google-auth {
		/* -- Factor Icons (high DPI): Google Authenticator -- */
		background-image: url('../third-party/okta/img/icons/mfa/googleAuth_ico@2x.png');
	}
}

#okta-sign-in.auth-container .mfa-google-auth {
	/* -- Factor Icons (large): Google Authenticator -- */
	background-image: url('../third-party/okta/img/icons/mfa/googleAuth_70x70.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in.auth-container .mfa-google-auth {
		/* -- Factor Icons (high DPI): Google Authenticator  -- */
		background-image: url('../third-party/okta/img/icons/mfa/googleAuth@2x.png');
	}
}

#okta-sign-in.auth-container .mfa-symantec-30,
#okta-sign-in.auth-container .enroll-factor-row .mfa-symantec {
	/* -- Factor Icons (small): Symantec VIP -- */
	background-image: url('../third-party/okta/img/icons/mfa/symantec_38x38.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in.auth-container .mfa-symantec-30,
	#okta-sign-in.auth-container .enroll-factor-row .mfa-symantec {
		/* -- Factor Icons (high DPI): Symantec VIP -- */
		background-image: url('../third-party/okta/img/icons/mfa/symantec_ico@2x.png');
	}
}

#okta-sign-in.auth-container .mfa-symantec {
	/* -- Factor Icons (large): Symantec VIP -- */
	background-image: url('../third-party/okta/img/icons/mfa/symantec_70x70.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in.auth-container .mfa-symantec {
		/* -- Factor Icons (high DPI): Symantec VIP -- */
		background-image: url('../third-party/okta/img/icons/mfa/symantec@2x.png');
	}
}

#okta-sign-in.auth-container .mfa-rsa-30,
#okta-sign-in.auth-container .enroll-factor-row .mfa-rsa {
	/* -- Factor Icons (small): RSA SecurID -- */
	background-image: url('../third-party/okta/img/icons/mfa/rsa_38x38.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in.auth-container .mfa-rsa-30,
	#okta-sign-in.auth-container .enroll-factor-row .mfa-rsa {
		/* -- Factor Icons (high DPI): RSA SecurID -- */
		background-image: url('../third-party/okta/img/icons/mfa/rsa_ico@2x.png');
	}
}

#okta-sign-in.auth-container .mfa-rsa {
	/* -- Factor Icons (large): RSA SecurID -- */
	background-image: url('../third-party/okta/img/icons/mfa/rsa_70x70.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in.auth-container .mfa-rsa {
		/* -- Factor Icons (high DPI): RSA SecurID -- */
		background-image: url('../third-party/okta/img/icons/mfa/rsa@2x.png');
	}
}

#okta-sign-in.auth-container .mfa-onprem-30,
#okta-sign-in.auth-container .enroll-factor-row .mfa-onprem {
	/* -- Factor Icons (small): OnPrem -- */
	background-image: url('../third-party/okta/img/icons/mfa/onprem_38x38.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in.auth-container .mfa-onprem-30,
	#okta-sign-in.auth-container .enroll-factor-row .mfa-onprem {
		/* -- Factor Icons (high DPI): OnPrem -- */
		background-image: url('../third-party/okta/img/icons/mfa/onprem_ico@2x.png');
	}
}

#okta-sign-in.auth-container .mfa-onprem {
	/* -- Factor Icons (large): OnPrem -- */
	background-image: url('../third-party/okta/img/icons/mfa/onprem_70x70.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in.auth-container .mfa-onprem {
		/* -- Factor Icons (high DPI): OnPrem -- */
		background-image: url('../third-party/okta/img/icons/mfa/onprem@2x.png');
	}
}

#okta-sign-in.auth-container .mfa-duo-30,
#okta-sign-in.auth-container .enroll-factor-row .mfa-duo {
	/* -- Factor Icons (small): DUO -- */
	background-image: url('../third-party/okta/img/icons/mfa/duo_38x38.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in.auth-container .mfa-duo-30,
	#okta-sign-in.auth-container .enroll-factor-row .mfa-duo {
		/* -- Factor Icons (high DPI): DUO -- */
		background-image: url('../third-party/okta/img/icons/mfa/duo_ico@2x.png');
	}
}

#okta-sign-in.auth-container .mfa-duo {
	/* -- Factor Icons (large): DUO -- */
	background-image: url('../third-party/okta/img/icons/mfa/duo_70x70.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in.auth-container .mfa-duo {
		/* -- Factor Icons (high DPI): DUO -- */
		background-image: url('../third-party/okta/img/icons/mfa/duo@2x.png');
	}
}

#okta-sign-in.auth-container .mfa-yubikey-30,
#okta-sign-in.auth-container .enroll-factor-row .mfa-yubikey {
	/* -- Factor Icons (small): Yubikey -- */
	background-image: url('../third-party/okta/img/icons/mfa/yubico_38x38.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in.auth-container .mfa-yubikey-30,
	#okta-sign-in.auth-container .enroll-factor-row .mfa-yubikey {
		/* -- Factor Icons (high DPI): Yubikey -- */
		background-image: url('../third-party/okta/img/icons/mfa/yubico_ico@2x.png');
	}
}

#okta-sign-in.auth-container .mfa-yubikey {
	/* -- Factor Icons (large): Yubikey -- */
	background-image: url('../third-party/okta/img/icons/mfa/yubico_70x70.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in.auth-container .mfa-yubikey {
		/* -- Factor Icons (high DPI): Yubikey -- */
		background-image: url('../third-party/okta/img/icons/mfa/yubico@2x.png');
	}
}

#okta-sign-in.auth-container .mfa-sms-30,
#okta-sign-in.auth-container .enroll-factor-row .mfa-okta-sms {
	/* -- Factor Icons (small): SMS -- */
	background-image: url('../third-party/okta/img/icons/mfa/sms_38x38.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in.auth-container .mfa-sms-30,
	#okta-sign-in.auth-container .enroll-factor-row .mfa-okta-sms {
		/* -- Factor Icons (high DPI): SMS -- */
		background-image: url('../third-party/okta/img/icons/mfa/sms_ico@2x.png');
	}
}

#okta-sign-in.auth-container .mfa-okta-sms {
	/* -- Factor Icons (large): SMS -- */
	background-image: url('../third-party/okta/img/icons/mfa/sms_70x70.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in.auth-container .mfa-okta-sms {
		/* -- Factor Icons (high DPI): SMS -- */
		background-image: url('../third-party/okta/img/icons/mfa/sms@2x.png');
	}
}

#okta-sign-in.auth-container .mfa-call-30,
#okta-sign-in.auth-container .enroll-factor-row .mfa-okta-call {
	/* -- Factor Icons (small): CALL -- */
	background-image: url('../third-party/okta/img/icons/mfa/voicecall_38x38.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in.auth-container .mfa-call-30,
	#okta-sign-in.auth-container .enroll-factor-row .mfa-okta-call {
		/* -- Factor Icons (high DPI): CALL -- */
		background-image: url('../third-party/okta/img/icons/mfa/voicecall_ico@2x.png');
	}
}

#okta-sign-in.auth-container .mfa-okta-call {
	/* -- Factor Icons (large): CALL -- */
	background-image: url('../third-party/okta/img/icons/mfa/voicecall_70x70.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in.auth-container .mfa-okta-call {
		/* -- Factor Icons (high DPI): CALL -- */
		background-image: url('../third-party/okta/img/icons/mfa/voicecall@2x.png');
	}
}

#okta-sign-in.auth-container .mfa-email-30,
#okta-sign-in.auth-container .enroll-factor-row .mfa-okta-email {
	/* -- Factor Icons (small): EMAIL -- */
	background-image: url('../third-party/okta/img/icons/mfa/email_38x38.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in.auth-container .mfa-email-30,
	#okta-sign-in.auth-container .enroll-factor-row .mfa-okta-email {
		/* -- Factor Icons (high DPI): EMAIL -- */
		background-image: url('../third-party/okta/img/icons/mfa/email_ico@2x.png');
	}
}

#okta-sign-in.auth-container .mfa-okta-email {
	/* -- Factor Icons (large): EMAIL -- */
	background-image: url('../third-party/okta/img/icons/mfa/email_70x70.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in.auth-container .mfa-okta-email {
		/* -- Factor Icons (high DPI): EMAIL -- */
		background-image: url('../third-party/okta/img/icons/mfa/email@2x.png');
	}
}

#okta-sign-in.auth-container .mfa-question-30,
#okta-sign-in.auth-container .enroll-factor-row .mfa-okta-security-question {
	/* -- Factor Icons (small): Security Question -- */
	background-image: url('../third-party/okta/img/icons/mfa/question_38x38.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in.auth-container .mfa-question-30,
	#okta-sign-in.auth-container .enroll-factor-row .mfa-okta-security-question {
		/* -- Factor Icons (high DPI): Security Question -- */
		background-image: url('../third-party/okta/img/icons/mfa/question_ico@2x.png');
	}
}

#okta-sign-in.auth-container .mfa-okta-security-question {
	/* -- Factor Icons (large): Security Question -- */
	background-image: url('../third-party/okta/img/icons/mfa/question_70x70.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in.auth-container .mfa-okta-security-question {
		/* -- Factor Icons (high DPI): Security Question -- */
		background-image: url('../third-party/okta/img/icons/mfa/question@2x.png');
	}
}

#okta-sign-in.auth-container .mfa-password-30 {
	/* -- Factor Icons (small): Password -- */
	background-image: url('../third-party/okta/img/icons/mfa/password_38x38.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in.auth-container .mfa-password-30 {
		/* -- Factor Icons (high DPI): Password -- */
		background-image: url('../third-party/okta/img/icons/mfa/password_ico@2x.png');
	}
}

#okta-sign-in.auth-container .mfa-windows-hello-30,
#okta-sign-in.auth-container .enroll-factor-row .mfa-windows-hello {
	/* -- Factor Icons (small): Windows Hello -- */
	background-image: url('../third-party/okta/img/icons/mfa/windowsHello_38x38.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in.auth-container .mfa-windows-hello-30,
	#okta-sign-in.auth-container .enroll-factor-row .mfa-windows-hello {
		/* -- Factor Icons (high DPI): Windows Hello -- */
		background-image: url('../third-party/okta/img/icons/mfa/windowsHello_ico@2x.png');
	}
}

#okta-sign-in.auth-container .mfa-windows-hello {
	/* -- Factor Icons (large): Windows Hello -- */
	background-image: url('../third-party/okta/img/icons/mfa/windowsHello_70x70.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in.auth-container .mfa-windows-hello {
		/* -- Factor Icons (high DPI): Windows Hello -- */
		background-image: url('../third-party/okta/img/icons/mfa/windowsHello@2x.png');
	}
}

#okta-sign-in.auth-container .mfa-u2f-30,
#okta-sign-in.auth-container .enroll-factor-row .mfa-u2f {
	/* -- Factor Icons (small): U2F -- */
	background-image: url('../third-party/okta/img/icons/mfa/u2f_38x38.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in.auth-container .mfa-u2f-30,
	#okta-sign-in.auth-container .enroll-factor-row .mfa-u2f {
		/* -- Factor Icons (high DPI): U2F -- */
		background-image: url('../third-party/okta/img/icons/mfa/u2f_ico@2x.png');
	}
}

#okta-sign-in.auth-container .mfa-u2f {
	/* -- Factor Icons (large): U2F -- */
	background-image: url('../third-party/okta/img/icons/mfa/u2f_70x70.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in.auth-container .mfa-u2f {
		/* -- Factor Icons (high DPI): U2F -- */
		background-image: url('../third-party/okta/img/icons/mfa/u2f@2x.png');
	}
}

#okta-sign-in.auth-container .mfa-okta-password {
	/* -- Factor Icons (large): Password -- */
	background-image: url('../third-party/okta/img/icons/mfa/password_70x70.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in.auth-container .mfa-okta-password {
		/* -- Factor Icons (high DPI): Password -- */
		background-image: url('../third-party/okta/img/icons/mfa/password@2x.png');
	}
}

#okta-sign-in.auth-container .mfa-custom-factor-30,
#okta-sign-in.auth-container .enroll-factor-row .mfa-custom-factor {
	/* -- Factor Icons (small): Custom Factor -- */
	background-image: url('../third-party/okta/img/icons/mfa/customFactor_38x38.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in.auth-container .mfa-custom-factor-30,
	#okta-sign-in.auth-container .enroll-factor-row .mfa-custom-factor {
		/* -- Factor Icons (high DPI): Custom Factor -- */
		background-image: url('../third-party/okta/img/icons/mfa/customFactor_ico@2x.png');
	}
}

#okta-sign-in.auth-container .mfa-custom-factor {
	/* -- Factor Icons (large): Custom Factor -- */
	background-image: url('../third-party/okta/img/icons/mfa/customFactor_70x70.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	#okta-sign-in.auth-container .mfa-custom-factor {
		/* -- Factor Icons (high DPI): Custom Factor -- */
		background-image: url('../third-party/okta/img/icons/mfa/customFactor@2x.png');
	}
}

#okta-sign-in.auth-container .button {
	/* -- Buttons' Colors -- */
	color: #5e5e5e;
	background-color: #fbfbfb;
	background: -o-linear-gradient(top, #fbfbfb 0%, #f8f8f8 100%);
	/* Opera 11.10+ */
	background: -ms-linear-gradient(top, #fbfbfb 0%, #f8f8f8 100%);
	/* IE10+ */
	background: -webkit-linear-gradient(#fbfbfb, #f8f8f8);
	background: linear-gradient(#fbfbfb, #f8f8f8);
	border-color: #c3c3c3;
	border-bottom-color: #bbbbbb;
	box-shadow: rgba(0, 0, 0, 0.05) 0 1px 0, rgba(255, 255, 255, 0.1) 0 1px 0 0 inset;
}

#okta-sign-in.auth-container .button:hover,
#okta-sign-in.auth-container .button:focus,
#okta-sign-in.auth-container .button:active,
#okta-sign-in.auth-container .button:focus {
	/* -- Buttons' Colors -- */
	background-color: white;
	background: -o-linear-gradient(top, white 0%, #fcfcfc 100%);
	/* Opera 11.10+ */
	background: -ms-linear-gradient(top, white 0%, #fcfcfc 100%);
	/* IE10+ */
	background-image: -webkit-linear-gradient(white, #fcfcfc);
	background-image: linear-gradient(white, #fcfcfc);
	border-color: #b2b2b2;
	border-bottom-color: #afaeae;
	text-decoration: none;
	cursor: pointer;
}

#okta-sign-in.auth-container .button:hover,
#okta-sign-in.auth-container .button:focus,
#okta-sign-in.auth-container .button:active,
#okta-sign-in.auth-container .button:focus {
	/* -- Buttons' Colors -- */
	box-shadow: none;
}

#okta-sign-in.auth-container .button:focus {
	border-color: #0074b3;
}

#okta-sign-in.auth-container .button-dark {
	/* -- Buttons' Colors -- */
	color: #fff;
	background-color: #929292;
	background: -o-linear-gradient(top, #929292 0%, #8a8a8a 100%);
	/* Opera 11.10+ */
	background: -ms-linear-gradient(top, #929292 0%, #8a8a8a 100%);
	/* IE10+ */
	background: -webkit-linear-gradient(#929292, #8a8a8a);
	background: linear-gradient(#929292, #8a8a8a);
	border-color: #6c6c6c;
	border-bottom-color: #676767;
	box-shadow: rgba(0, 0, 0, 0.15) 0 1px 0, rgba(255, 255, 255, 0.1) 0 1px 0 0 inset;
}

#okta-sign-in.auth-container .button-dark:hover,
#okta-sign-in.auth-container .button-dark:focus,
#okta-sign-in.auth-container .button-dark:active,
#okta-sign-in.auth-container .button-dark:focus {
	/* -- Buttons' Colors -- */
	background-color: #9a9a9a;
	background: -o-linear-gradient(top, #9a9a9a 0%, #929292 100%);
	/* Opera 11.10+ */
	background: -ms-linear-gradient(top, #9a9a9a 0%, #929292 100%);
	/* IE10+ */
	background-image: -webkit-linear-gradient(#9a9a9a, #929292);
	background-image: linear-gradient(#9a9a9a, #929292);
	border-color: #606060;
	border-bottom-color: #5e5e5e;
	text-decoration: none;
	cursor: pointer;
}

#okta-sign-in.auth-container .button-dark:hover,
#okta-sign-in.auth-container .button-dark:focus,
#okta-sign-in.auth-container .button-dark:active,
#okta-sign-in.auth-container .button-dark:focus {
	/* -- Buttons' Colors -- */
	box-shadow: none;
}

#okta-sign-in.auth-container .button-primary {
	/* -- Buttons' Colors -- */
	color: #fff;
	background-color: #007dc1;
	background: -o-linear-gradient(top, #007dc1 0%, #0073b2 100%);
	/* Opera 11.10+ */
	background: -ms-linear-gradient(top, #007dc1 0%, #0073b2 100%);
	/* IE10+ */
	background: -webkit-linear-gradient(#007dc1, #0073b2);
	background: linear-gradient(#007dc1, #0073b2);
	border-color: #004b75;
	border-bottom-color: #00456a;
	box-shadow: rgba(0, 0, 0, 0.15) 0 1px 0, rgba(255, 255, 255, 0.1) 0 1px 0 0 inset;
}

#okta-sign-in.auth-container .button-primary:hover,
#okta-sign-in.auth-container .button-primary:focus,
#okta-sign-in.auth-container .button-primary:active,
#okta-sign-in.auth-container .button-primary:focus {
	/* -- Buttons' Colors -- */
	background-color: #0087d0;
	background: -o-linear-gradient(top, #0087d0 0%, #007dc1 100%);
	/* Opera 11.10+ */
	background: -ms-linear-gradient(top, #0087d0 0%, #007dc1 100%);
	/* IE10+ */
	background-image: -webkit-linear-gradient(#0087d0, #007dc1);
	background-image: linear-gradient(#0087d0, #007dc1);
	border-color: #003d5e;
	border-bottom-color: #003a59;
	text-decoration: none;
	cursor: pointer;
}

#okta-sign-in.auth-container .button-primary:hover,
#okta-sign-in.auth-container .button-primary:focus,
#okta-sign-in.auth-container .button-primary:active,
#okta-sign-in.auth-container .button-primary:focus {
	/* -- Buttons' Colors -- */
	box-shadow: none;
}

#okta-sign-in.auth-container .button-success {
	/* -- Buttons' Colors -- */
	color: #fff;
	background-color: #4cbf9c;
	background: -o-linear-gradient(top, #4cbf9c 0%, #42b995 100%);
	/* Opera 11.10+ */
	background: -ms-linear-gradient(top, #4cbf9c 0%, #42b995 100%);
	/* IE10+ */
	background: -webkit-linear-gradient(#4cbf9c, #42b995);
	background: linear-gradient(#4cbf9c, #42b995);
	border-color: #328c71;
	border-bottom-color: #2f856b;
	box-shadow: rgba(0, 0, 0, 0.15) 0 1px 0, rgba(255, 255, 255, 0.1) 0 1px 0 0 inset;
}

#okta-sign-in.auth-container .button-success:hover,
#okta-sign-in.auth-container .button-success:focus,
#okta-sign-in.auth-container .button-success:active,
#okta-sign-in.auth-container .button-success:focus {
	/* -- Buttons' Colors -- */
	background-color: #57c3a2;
	background: -o-linear-gradient(top, #57c3a2 0%, #4cbf9c 100%);
	/* Opera 11.10+ */
	background: -ms-linear-gradient(top, #57c3a2 0%, #4cbf9c 100%);
	/* IE10+ */
	background-image: -webkit-linear-gradient(#57c3a2, #4cbf9c);
	background-image: linear-gradient(#57c3a2, #4cbf9c);
	border-color: #2c7b63;
	border-bottom-color: #2b7861;
	text-decoration: none;
	cursor: pointer;
}

#okta-sign-in.auth-container .button-success:hover,
#okta-sign-in.auth-container .button-success:focus,
#okta-sign-in.auth-container .button-success:active,
#okta-sign-in.auth-container .button-success:focus {
	/* -- Buttons' Colors -- */
	box-shadow: none;
}

#okta-sign-in.auth-container .button.link-button-disabled {
	/* -- Buttons' Colors -- */
	color: #aaa;
}

#okta-sign-in.auth-container .button.link-button-disabled:hover,
#okta-sign-in.auth-container .button.link-button-disabled:focus,
#okta-sign-in.auth-container .button.link-button-disabled:active,
#okta-sign-in.auth-container .button.link-button-disabled:focus {
	/* -- Buttons' Colors -- */
	cursor: default;
	background-image: none;
}

#okta-sign-in.auth-container .button.button-primary.link-button-disabled {
	/* -- Buttons' Colors -- */
	color: #fff;
	background-color: #63b1d7;
	border-color: #63b1d7;
	background-image: none;
	box-shadow: none;
	opacity: 0.5;
	cursor: not-allowed;
}

#okta-sign-in.auth-container .focused-input .button.button-primary {
	box-shadow: 0 0 5px #38adff;
}

/*
#okta-sign-in.auth-container .button.button-primary.link-button-disabled:hover, #okta-sign-in.auth-container .button.button-primary.link-button-disabled:focus, #okta-sign-in.auth-container .button.button-primary.link-button-disabled:active, #okta-sign-in.auth-container .button.button-primary.link-button-disabled:focus {

  cursor: default;
  background-image: none;
}
*/

#okta-sign-in.auth-container .icon-button {
	/* -- Buttons' Colors -- */
	color: #5e5e5e;
	background-color: #fff;
	background: -o-linear-gradient(top, #fff 0%, white 100%);
	/* Opera 11.10+ */
	background: -ms-linear-gradient(top, #fff 0%, white 100%);
	/* IE10+ */
	background: -webkit-linear-gradient(#fff, white);
	background: linear-gradient(#fff, white);
	border-color: #c4c4c4;
	border-bottom-color: #bdbdbd;
}

#okta-sign-in.auth-container .icon-button:hover,
#okta-sign-in.auth-container .icon-button:focus,
#okta-sign-in.auth-container .icon-button:active,
#okta-sign-in.auth-container .icon-button:focus {
	/* -- Buttons' Colors -- */
	background-color: white;
	background: -o-linear-gradient(top, white 0%, white 100%);
	/* Opera 11.10+ */
	background: -ms-linear-gradient(top, white 0%, white 100%);
	/* IE10+ */
	background-image: -webkit-linear-gradient(white, white);
	background-image: linear-gradient(white, white);
	border-color: #b3b3b3;
	border-bottom-color: #afafaf;
	text-decoration: none;
	cursor: pointer;
}

#okta-sign-in.auth-container .okta-dropdown-list {
	/* -- Dropdown Colors -- */
	/* background: #fff; */
	border-color: #888;
}

#okta-sign-in.auth-container .factors-dropdown-wrap .okta-dropdown-list {
	/* -- Dropdown Colors -- */
	border-color: #ccc;
	border-color: rgba(0, 0, 0, 0.2);
}

#okta-sign-in.auth-container .okta-dropdown-option {
	/* -- Dropdown Colors -- */
	/* background: transparent; */
	/* border-bottom-color: #fff; */
}

#okta-sign-in.auth-container .okta-dropdown-option:hover {
	/* -- Dropdown Colors -- */
	/* background: #f9f9f9; */
}

#okta-sign-in.auth-container .okta-dropdown-option.dropdown-list-title:hover {
	/* -- Dropdown Colors -- */
	background: transparent;
}

#okta-sign-in.auth-container .okta-dropdown-option a {
	/* -- Dropdown Colors -- */
	color: #5e5e5e;
}

#okta-sign-in.auth-container .okta-infobox-error {
	/* -- Error Infobox Colors -- */
	/* background-color: #fff; */
	/* border: 1px solid #ddd; */
}

#okta-sign-in.auth-container .okta-infobox-error:before {
	/* -- Error Infobox Colors -- */
	/* background-color: #e34843; */
}

#okta-sign-in.auth-container .okta-infobox-error .error-16:before {
	/* -- Error Infobox Colors: error icon -- */
	/* color: #fff; */
	/* Use the rules below to replace an error icon in error infoboxes */
	/* content: ''; */
	/* width: 16px; */
	/* height: 16px; */
	/* background-image: none; */
}

#okta-sign-in.auth-container
	.enroll-required-factor-list
	.enroll-factor-row-min
	.enroll-factor-description
	h3 {
	/* -- Enroll required factors, factors that are not the current step -- */
	color: #a7a7a7;
}

#okta-sign-in.auth-container .okta-form-input-field {
	/* -- Input Fields -- */
	background-color: #fff;
	border-color: #bbb;
}

#okta-sign-in.auth-container .okta-form-input-field:hover {
	/* -- Input Fields -- */
	border-color: #888;
}

#okta-sign-in.auth-container .okta-form-input-field.focused-input {
	border-color: #0074b3;
}

#okta-sign-in.auth-container .okta-form-input-field input {
	/* -- Input Fields -- */
	font-family: 'montserrat', Arial, Helvetica, sans-serif;
	color: #5e5e5e;
}

#okta-sign-in.auth-container .okta-form-input-field input::-webkit-input-placeholder {
	/* -- Input Fields: placeholder -- */
	font-family: 'montserrat', Arial, Helvetica, sans-serif;
	color: #aaa;
}

#okta-sign-in.auth-container .okta-form-input-field input::-moz-placeholder {
	/* -- Input Fields: placeholder -- */
	font-family: 'montserrat', Arial, Helvetica, sans-serif;
	color: #aaa;
}

#okta-sign-in.auth-container .okta-form-input-field input:-ms-input-placeholder {
	/* -- Input Fields: placeholder -- */
	font-family: 'montserrat', Arial, Helvetica, sans-serif;
	color: #aaa;
}

#okta-sign-in.auth-container .okta-form-input-field input[disabled] {
	/* -- Input Fields: disabled input field -- */
	color: #777;
	-webkit-text-fill-color: #777;
	/* background: #f4f4f4; */
}

#okta-sign-in.auth-container .okta-form-input-field input[type='password'] {
	/* -- Input Fields: password input field -- */
	font-family: Verdana, sans-serif;
}

#okta-sign-in.auth-container .okta-form-input-field input[type='password']:placeholder-shown {
	font-family: 'montserrat', Arial, Helvetica, sans-serif;
}

#okta-sign-in.auth-container .okta-form-input-field placeholder {
	/* -- Input Fields: placeholder -- */
	font-family: 'montserrat', Arial, Helvetica, sans-serif;
	color: #aaa;
}

#okta-sign-in.auth-container .input-icon:before,
#okta-sign-in.auth-container .input-tooltip:before {
	/* -- Input Fields: icons -- */
	color: #a7a7a7;
}

#okta-sign-in.auth-container .okta-form-label-inline {
	/* -- Input Fields -- */
	/* background-color: #f2f2f2; */
	/* border-color: #c8c8c8; */
	/* color: #999; */
}

#okta-sign-in.auth-container .okta-form-input-error {
	/* -- Input Fields: error highlight -- */
	/* color: #d93934; */
}

#okta-sign-in.auth-container .okta-form-input-error .error-16-small:before {
	/* -- Input Fields: error icon -- */
	/* color: #e34843; */
	/* Use the rules below to replace input fields error icon */
	/* content: ''; */
	/* width: 16px; */
	/* height: 16px; */
	/* background-image: none; */
}

#okta-sign-in.auth-container .chzn-single {
	/* -- Input Fields: Select Input -- */
	background-color: #fff;
	border-color: #bbb;
}

#okta-sign-in.auth-container .chzn-single span {
	/* -- Input Fields: Select Input -- */
	color: #777;
}

#okta-sign-in.auth-container .chzn-single:hover {
	/* -- Input Fields: Select Input -- */
	border-color: #888;
}

#okta-sign-in.auth-container .chzn-with-drop .chzn-single,
#okta-sign-in.auth-container .chzn-with-drop .chzn-drop {
	/* -- Input Fields: Select Input -- */
	background-color: #fff;
	border-color: #888;
}

#okta-sign-in.auth-container .chzn-with-drop .chzn-results li {
	/* -- Input Fields: Select Input -- */
	/* border-color: #fff; */
}

#okta-sign-in.auth-container .chzn-with-drop .chzn-results .highlighted {
	/* -- Input Fields: Select Input -- */
	/* background-color: #f9f9f9; */
}

#okta-sign-in.auth-container .primary-auth {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .password-expired {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .password-reset {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .password-reset-email-sent {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .forgot-password {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .account-unlock {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .account-unlock-email-sent {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .recovery-challenge {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .recovery-loading {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .recovery-question {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .refresh-auth-state {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .enroll-choices {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .enroll-totp {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .barcode-totp {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .activate-totp {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .enroll-manual-totp {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .barcode-push {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .activate-push {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .enroll-manual-push {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .enroll-activation-link-sent {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .enroll-symantec {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .enroll-rsa {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .enroll-onprem {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .enroll-duo {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .enroll-sms {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .enroll-call {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .enroll-question {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .mfa-verify-duo {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .mfa-verify {
	/* -- Page specific top level selectors: all challenge pages except duo -- */
}

#okta-sign-in.auth-container .mfa-verify-totp {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .mfa-verify-push {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .mfa-verify-totp-inline {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .mfa-verify-question {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .mfa-verify-passcode {
	/* -- Page specific top level selectors -- */
}

#okta-sign-in.auth-container .mfa-verify-password {
	/* -- Page specific top level selectors -- */
}

.okta-tooltip.qtip-custom {
	/* -- Tooltips -- */
	font-family: 'montserrat', Arial, Helvetica, sans-serif;
	/* background-color: #4d4d4d; */
	/* border-color: #303030; */
}

.okta-tooltip.qtip-custom .qtip-content {
	/* -- Tooltips -- */
	/* color: #f3f3f3; */
}

.okta-tooltip.qtip-custom .qtip-titlebar {
	/* -- Tooltips -- */
	/* background-color: #404040 */
}

.okta-sign-in-security-image-tooltip.qtip-custom {
	/* -- Tooltips -- */
	font-family: 'montserrat', Arial, Helvetica, sans-serif;
	/* background-color: #4d4d4d; */
	/* border-color: #303030; */
}

.okta-sign-in-security-image-tooltip.qtip-custom .qtip-content {
	/* -- Tooltips -- */
	/* color: #f3f3f3; */
}

/* custom login theme */

.login-bg-image {
	background-image: url('../../node_modules/@qnp/qnp-common/images/HCMS-login-bg.png') !important;
	background-size: 100% !important;
	background-repeat: no-repeat;
	background-position-x: center;
	background-color: #06608a !important;
}

.terms-conditions-header-text {
	display: block;
	height: 20px;
	width: 50%;
	font-weight: normal;
	font-size: 1.3rem;
	/* text-align: center; */
	color: #fff;
}

.terms-conditions-text {
	display: block;
	/* height: 20px; */
	/* width:50%; */
	font-weight: normal;
	font-size: 1rem;
	text-align: left;
	color: #fff;
}

.mft-page {
	min-height: calc(100vh - 224px);
}

.mft-page-header {
	background-image: url('../../node_modules/@qnp/qnp-common/images/HCMS-login-bg.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position-x: center;
	color: white;
	padding: 48px 0 40px 0;
}

.mft-page .mft-page-header .mft-page-title {
	background-size: cover;
	background-repeat: no-repeat;
	background-position-x: center;
	color: white;
	font-weight: bold;
}

.mft-page-content {
	padding: 40px 0;
}

.mft-help-content {
	padding-bottom: 20px;
}
.mft-help-content .harp-help-category {
	margin: 20px 0;
}
.mft-help-content .card {
	margin: 12px 0;
	box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.05);
}
.mft-help-content .card-header {
	background-color: white;
	padding: 20px 40px;
	font-size: 1.2em;
}
.mft-help-content .card-header:before {
	font-family: Ionicons;
	content: '\f3d1';
	padding-right: 8px;
}
.mft-help-content .card-header.active:before {
	font-family: Ionicons;
	content: '\f3d0';
	padding-right: 8px;
}
.mft-help-content .card-body {
	padding: 12px 40px 40px 40px;
}
.mft-help-content .card-body p:nth-last-child(1) {
	margin-bottom: 0;
}

#okta-sign-in.auth-container.main-container {
	border: 0;
	box-shadow: none;
}

#okta-sign-in.auth-container .okta-sign-in-header {
	/* background-color: #06608a; */
	border: none;
}

#okta-sign-in.auth-container .okta-sign-in-header .logo-text {
	color: #fff;
	text-align: center;
	font-size: 20px;
	margin: 20px 10px 10px 10px;
}

#okta-sign-in .auth-header {
	padding: 20px;
}

#okta-sign-in .auth-content {
	background-color: #f9f9f9;
	border-radius: 3px;
	border-top-right-radius: 0px;
	border-top-left-radius: 0px;
	padding: 20px 30px;
}

#okta-sign-in.no-beacon .auth-content {
	border-top-right-radius: 3px;
	border-top-left-radius: 3px;
}

#okta-sign-in.auth-container.main-container {
	background: none;
}

/*#okta-sign-in.auth-container .beacon-container {
  display: none;
}*/

#okta-sign-in .o-form-head {
	text-align: left;
	font-size: 18px;
}

#okta-sign-in.auth-container h2.okta-form-title {
	margin-top: 40px;
	margin-bottom: 25px;
}

#okta-sign-in.auth-container.no-beacon h2.okta-form-title {
	margin-top: 10px;
}

#okta-sign-in.auth-container .primary-auth h2.okta-form-title:after {
	content: 'Enter your user ID and password to login';
	display: block;
	height: 20px;
	width: 100%;
	font-weight: normal;
	font-size: 1rem;
}

#okta-sign-in.no-beacon .okta-sign-in-header.auth-header:before {
	display: none;
}

#okta-sign-in .okta-sign-in-header.auth-header:before {
	position: absolute;
	height: 75px;
	width: 100%;
	display: block;
	content: '';
	background: #f9f9f9;
	bottom: 0;
	padding: 20px;
	left: 0;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	border-bottom: 1px solid #ddd;
}

#okta-sign-in .okta-sign-in-header.auth-header:after {
	content: 'Content Management System';
	display: block;
	height: 20px;
	width: 100%;
	font-weight: normal;
	font-size: 1.3rem;
	text-align: center;
	color: #fff;
	margin: 20px 0 90px 0px;
}

#okta-sign-in.no-beacon .okta-sign-in-header.auth-header:after {
	margin: 20px 0 10px 0px;
}

#okta-sign-in.auth-container .okta-form-title-sub-text {
	margin-bottom: 20px;
}

#okta-sign-in.auth-container.main-container .bg-helper,
#okta-sign-in .factors-dropdown-wrap .dropdown.more-actions .bg-helper {
	color: #f9f9f9;
}

#okta-sign-in.auth-container .button.button-primary {
	color: #fff !important;
	background-color: #046b99 !important;
	background-image: none;
	border-color: #046b99 !important;
	padding: 5px;
	border-radius: 4px !important;
	box-shadow: none;
}

#okta-sign-in.auth-container .button.button-primary:hover {
	color: #fff !important;
	background-color: #035174 !important;
	background-image: none;
	border-color: #034867 !important;
	box-shadow: none;
}

#okta-sign-in.auth-container .button.button-primary:active {
	color: #fff !important;
	background-color: #034867 !important;
	background-image: none;
	border-color: #02405b !important;
	box-shadow: none;
}

/*  Remove the background image and borders on a smaller window size */
@media only screen and (max-width: 600px) {
	.login-bg-image {
		/*
    background-image: none !important;
    background-color: #fff !important;
    -webkit-filter: unset !important;
            filter: unset !important;
    */
	}
	/* Using the same selector as line 59 to override styles at small screen sizes. */
	#okta-sign-in.auth-container {
		width: auto;
		margin-right: 0;
		margin-left: 0;
	}
	#okta-sign-in.auth-container.main-container {
		border: 0;
		box-shadow: none;
	}
	#okta-sign-in.auth-container .auth-content {
		max-width: 316px;
		margin: 0 auto;
	}
}
/*# sourceMappingURL=okta-theme.css.map */
